import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private session: SessionService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // return undefined;
    //console.log(`%cClass: AuthGuard, Function: canActivate(route, state): `, 'color: black;', route, state);
    if (route.queryParamMap.get('ISSESSION')) {
      return true;
    }
    return true;
    //this.session.currentUser$.pipe(map((currentUser) => Boolean(currentUser) || this.router.parseUrl('/')));
  }
}
