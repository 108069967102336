import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { WidgetsRegistryModule } from '@ic-builder/widgets-registry';
import { IscustomselectComponent } from './iscustomselect/iscustomselect.component';
import { IscustomcheckboxComponent } from './iscustomcheckbox/iscustomcheckbox.component';
import { IscustomradioComponent } from './iscustomradio/iscustomradio.component';
import { CustomfrequentionComponent } from './customfrequention/customfrequention.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WidgetsRegistryModule.forChild({
      module: { ngModule: IsBaseControlsModule },
      components: {
        iscustomselect: IscustomselectComponent,
        iscustomcheckbox: IscustomcheckboxComponent,
      },
    }),
  ],
  exports: [
    IscustomselectComponent,
    IscustomcheckboxComponent,
    IscustomradioComponent,
    CustomfrequentionComponent,
  ],
  declarations: [
    IscustomselectComponent,
    IscustomcheckboxComponent,
    IscustomradioComponent,
    CustomfrequentionComponent
  ],
})
export class IsBaseControlsModule {}
