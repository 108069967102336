import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild  } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'ic-builder-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureComponent),
      multi: true,
    },
  ],
})
export class SignatureComponent implements AfterViewInit, ControlValueAccessor {
  _signPad:any;
  @Input() set signPad(val) {
    this._signPad = val;
  };

  get signPad():any{
    return this._signPad;
  }
  
  @ViewChild('signPadCanvas', {static: false}) signaturePadElement:any;
  @Output() signatureCreated: EventEmitter<any> = new EventEmitter<any>(); 
  signImage:any;

  constructor(private cd: ChangeDetectorRef, private el: ElementRef) { }

  private _value: any = null;

  get value(): any | null {
    return this._value;
  }

  ngAfterViewInit() {
    // this.el.nativeElement;

    // const { width, height } = this.signaturePadElement.nativeElement.getBoundingClientRect();

    // this.signaturePadElement.nativeElement.width = 200;
    // this.signaturePadElement.nativeElement.height = 200;
    // this.signPad = new SignaturePad(this.signaturePadElement.nativeElement, { penColor: 'purple' });

    this.signatureCreated.emit({
      el: this.signaturePadElement.nativeElement,
      writeValue: this.writeValue,
      // signPad: this.signPad
    });

    // this.writeValue(this.value);
  }
  /*It's work in devices*/
  startSignPadDrawing(event: Event) {
    // console.log('start', event);
  }
  /*It's work in devices*/
  movedFinger(event: Event) {

  }
  /*Undo last step from the signature*/
  undoSign() {
    const data = this.signPad.toData();
    if (data) {
      data.pop(); // remove the last step
      this.signPad.fromData(data);
    }
    this.writeValue(this.signPad.toDataURL(), true);
  }
  /*Clean whole the signature*/
  clearSignPad() {
    this.writeValue(null, true);
  }

  saveSignPad(ev: Event) {
    const base64ImageData = this.signPad.toDataURL();
    this.writeValue(base64ImageData, true);
  }

  _onChange: (_: any) => void = (_) => {};

  _onTouched = () => {};

  writeValue = (value: any, fromSelf:boolean = false) => {
      if (this.signPad) {
        if (!value) {
          this.signPad.clear();
        }

        if (!fromSelf && value) {
          if (!value.includes('data:image/png;base64,')) value = `data:image/png;base64,${value}`;;

          this.signPad.clear();

          const { width, height } = this.signaturePadElement.nativeElement.getBoundingClientRect();

          this.signPad.fromDataURL(value, { width, height }) //, {width: 200, height: 200, ratio: 1 })  // {width: 302, height: 152 });
        }
      }
      this._onChange(value);
      this._value = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
