<div class="pivContainer">
  <ng-template #pivotContainer></ng-template>
</div>
<div class="colContainer" style="flex: 1; position: relative">
  {{ label }}
  <ng-template #columnContainer></ng-template>
</div>
<div class="totContainer">
  <ng-template #totalContainer></ng-template>
</div>
