import { Component, OnInit, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';

@Component({
  selector: 'isicon',
  templateUrl: './isicon.component.html',
  styleUrls: ['./isicon.component.scss'],
})
export class IsiconComponent implements OnInit {
  
  @Input('icon') icon: string;
  
  constructor(public comp: ComponentsService,
    public store: Store,
    public outlet: Outlet2Service,    
    public el: ElementRef,
    public cd: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
