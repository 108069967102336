import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentsService, IsthemingService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'isgmaps',
  templateUrl: './isgmaps.component.html',
  styleUrls: ['./isgmaps.component.scss'],
})
export class IsgmapsComponent extends IscontrolComponent implements OnInit{
  apiLoaded: Observable<boolean>;
  zoom:number=14;
  latitude:number=51.14;
  longitude:number=4.1;
  center: google.maps.LatLngLiteral = {lat: this.latitude, lng: this.longitude};
  @ViewChild('map', { static: true }) mapEl: ElementRef<HTMLElement>;
  // @ts-ignore
  map: google.maps.Map;
  formControlLat: FormControl;
  controlidLat: string;

  formControlLng: FormControl;
  controlidLng: string;

  constructor(private httpClient: HttpClient,public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef,
    private themingService: IsthemingService) {
    super(compserv, store, outlet, NgZone, dialog, el, cd)
    this.apiLoaded = httpClient
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyC6JOC6PZdQDs9sy9uyDt-U2kdLU9QqrGE',
        'callback'
      )
      .pipe(
        map(() => {
          // @ts-ignore
           this.map = new google.maps.Map(this.mapEl.nativeElement, {
             center: {lat: this.latitude, lng: this.longitude},
             zoom: 8
           });

          return true}
          ),
        catchError(() => of(false))
      );
  }

  centermap(lat:number,lng:number){
    this.map.setCenter({lat:Number(lat),lng:Number(lng)});
  }

  ngOnInit(){
    this.controlidLat = super.initControl(this.id.toString() + '.lat', this.latitude , 'number');
    this.formControlLat = super.getFormControl(this.controlidLat); 

    this.controlidLng = super.initControl(this.id.toString() + '.lng', this.latitude , 'number');
    this.formControlLng = super.getFormControl(this.controlidLng); 

    this.formControlLng.valueChanges.pipe(debounceTime(250)).subscribe(item => {
      this.centermap(this.formControlLat.value,this.formControlLng.value);
    })

  }


}
