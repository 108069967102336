<!--<div class="row">-->
<div *ngIf="label" istag="label" class="label content" id="labeltxt">
  {{ label }}
</div>

<input
  type="color"
  istag="input"
  class="input content"
  [formControl]="formControl"
/>
<button istag="button" class="button" (click)="reset()">x</button>
<!--</div>-->
