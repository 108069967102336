import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class isContextOverlay {
  activemenu: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  setContextActive(id) {
    this.activemenu.next(id);
  }
}
