import { Action, State, StateContext, Selector, Store } from '@ngxs/store';
import * as actions from './highlight.actions';
import { Injectable } from '@angular/core';

export interface HighlightStateModel {
  path: string;
  theme: string;
}

@State<HighlightStateModel>({
  name: 'HighlightState',
  defaults: {
    path: '',
    theme: 'is-light-theme',
  },
})
@Injectable()
export class HighlightState {
  constructor(private store: Store) {}

  @Selector()
  static path(state: HighlightStateModel) {
    return state.path;
  }

  @Selector()
  public static theme(state: HighlightStateModel) {
    return state.theme;
  }

  @Action(actions.SetPath)
  setCaseTypes(
    { patchState }: StateContext<HighlightStateModel>,
    { payload }: actions.SetPath
  ) {
    this.store.dispatch(new actions.ResetPath(payload));
    patchState({
      path: '',
    });
  }

  @Action(actions.ToggleTheme)
  toggleTheme(
    { patchState }: StateContext<HighlightStateModel>,
    { payload }: actions.ToggleTheme
  ) {
    patchState({
      theme: payload,
    });
  }

  @Action(actions.ResetPath)
  ResetPath(
    { patchState }: StateContext<HighlightStateModel>,
    { payload }: actions.ResetPath
  ) {
    patchState({
      path: payload,
    });
  }
}
