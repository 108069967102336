export class SpeedTestFileModel {
  public path: string = './assets/speedtest/5mb.jpg';
  public shouldBustCache: boolean = true;
  public size: number = 4952221;

  // https://raw.githubusercontent.com/jrquick17/ng-speed-test/02c59e4afde67c35a5ba74014b91d44b33c0b3fe/demo/src/assets/500kb.jpg
  // 500kb      // 408949 kb
  // https://raw.githubusercontent.com/jrquick17/ng-speed-test/02c59e4afde67c35a5ba74014b91d44b33c0b3fe/demo/src/assets/1mb.jpg
  // 1mb        // 1197292 kb
  // https://raw.githubusercontent.com/jrquick17/ng-speed-test/02c59e4afde67c35a5ba74014b91d44b33c0b3fe/demo/src/assets/5mb.jpg
  // 5mb        // 4952221 kb
  // https://raw.githubusercontent.com/jrquick17/ng-speed-test/02c59e4afde67c35a5ba74014b91d44b33c0b3fe/demo/src/assets/13mb.jpg
  // 13mb       // 13848150 kb

  constructor() {}
}
