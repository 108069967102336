import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

export interface LibraryOptions {
  production : boolean;
}

export const OPTIONS = new InjectionToken<LibraryOptions>('options');


@NgModule({
  imports: [CommonModule],
})
export class DataAccessIscloudModule {

  static forRoot(options: LibraryOptions): ModuleWithProviders<DataAccessIscloudModule> {
    return {
     ngModule: DataAccessIscloudModule,
     providers: [
     {
       provide: OPTIONS,
       useValue: options
     }]
    }
   }
}
