<div style="width: 500px">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input
      #filter
      matInput
      type="text"
      (keyup)="updateFilter($event)"
      tabindex="1"
    />
  </mat-form-field>
  <!--div style='height: 300px; overflow: auto'-->
  <isgrid #enum_grid id="enum" data="list" style="height: 300px"> </isgrid>
  <!--/div-->

  <button mat-button (click)="oke()" tabindex="3">ok</button>
  <button mat-button (click)="cancel()" tabindex="4">cancel</button>
</div>
