<div *ngFor="let o of options[mode];index as idx" class="option" (click)="toggleValue(idx)">
    {{o}}
    <svg 
        *ngIf="value.charAt(idx) === '1'"
        class="cross"
        width="50" height="50" 
        viewBox="0 0 50 50"
        overflow="visible"
        stroke="black"
        stroke-width="10"
        stroke-linecap="round"
    >
        <line x2="50" y2="50" />
        <line x1="50" y2="50" />
     </svg>
</div>