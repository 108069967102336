import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private session: SessionService, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const sessionId = this.session.getSessionId();
    const headersConfig: Record<string, string> = {};

    if (sessionId) {
      headersConfig.ISSESSION = sessionId;
    }

    // console.log(
    //   `%cClass: AuthInterceptor, Function: intercept(headersConfig): `,
    //   'color: black;',
    //   request,
    //   ' ',
    //   headersConfig
    // );

    const req = request.clone({ setHeaders: headersConfig });
    return next.handle(req).pipe(
      tap({
        error: (err) => {
          if (
            err instanceof HttpErrorResponse &&
            err.status === HttpStatusCode.Unauthorized
          ) {
            this.router.navigateByUrl('/');
          }
        },
      })
    );
  }
}
