<isgrid
  #grid
  [id]="id"
  [columns]="columns"
  [adata]="enumerationlist$ | async"
  [editmode]="false"
  [datamode]="3"
  class="isgrid"
></isgrid>

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
