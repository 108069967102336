import { IsGridService, IsGridRegistry } from './../../isgrid.service';
import { map } from 'rxjs/operators';
import { Component, OnInit, ElementRef, AfterViewInit, ViewChild, ViewContainerRef, Input } from '@angular/core';

@Component({
  selector: 'isheadercolumn',
  templateUrl: './isheadercolumn.component.html',
  styleUrls: ['./isheadercolumn.component.scss'],
})
export class Isheadercolumn1Component implements AfterViewInit {
  @Input('visible') visible = true;
  @Input('subheadervisible') subheadervisible = false;
  label: string;
  colid: string;
  colindex: number;
  gridid: number;
  sort: string;
  sorticon: string;

  translation: any | null = null;

  @ViewChild('customfilter', { static: true, read: ViewContainerRef }) public customfilter: ViewContainerRef;
  @ViewChild('subheader', { read: ViewContainerRef }) public subheader: ViewContainerRef;

  constructor(private el: ElementRef, private grid: IsGridRegistry) {}

  translate(lang){
    if (this.translation){
      this.label = this.translation[lang];
    }
  }

  ngAfterViewInit(): void {
    if (this['pivots']) this.el.nativeElement.setAttribute('istag', 'headercolumn');
    if (!this.visible) {
      this.el.nativeElement.setAttribute('hidden', '');
    }

    // this.el.nativeElement.style['flex-grow'] = '1';

    if (this.label !== 'select') {
      this.el.nativeElement.setAttribute('draggable', true);

      this.el.nativeElement.addEventListener('dragstart', (ev) => {
        if (ev.offsetX < 10 || ev.currentTarget.getBoundingClientRect().width - ev.offsetX < 10) {
          event.preventDefault();
        }

        ev.dataTransfer.setData('text/plain', this.colindex.toString());
      });

      this.el.nativeElement.addEventListener('dragover', (ev) => {
        ev.preventDefault();
      });

      this.el.nativeElement.addEventListener('drop', (ev) => {
        this.grid.views.get(this.gridid).moveColumn(Number(ev.dataTransfer.getData('text')), this.colindex);
      });

      this.el.nativeElement.addEventListener('click', (ev) => {
        this.grid.views.get(this.gridid).resetallsortedcolumns(this.colid);
        if (!this.sort) {
          this.sortup();
        } else {
          if (this.sort === 'down') {
            this.sortup();
          } else {
            this.sortdown();
          }
        }
        this.grid.views.get(this.gridid).addsortcolumn(this.colid, this.sort);
      });
    }
  }

  sortup() {
    this.sort = 'up';
    this.sorticon = 'arrow_upward';
  }

  sortdown() {
    this.sort = 'down';
    this.sorticon = 'arrow_downward';
  }

  resetsort() {
    this.sort = null;
    this.sorticon = null;
  }

  // openFilter(event) {
  //   event.preventDefault();
  //   event.stopPropagation();

  //   this.moveDropdown();
  // }

  // moveDropdown() {
  //   let dropdown = this.el.nativeElement.parentElement.parentElement.querySelector('isgriddropdown')
  //   let box = this.el.nativeElement.getBoundingClientRect();
  //   dropdown.style['left'] = box.left + 'px';
  //   dropdown.style['top'] = box.bottom + 'px';
  //   dropdown.style['display'] = 'block';
  // }
}
