<div [style.position]="'relative'">
  <div class="menu-item" [style.z-index]="level">
    <div>{{ description }}</div>
    <div style="text-align: right; flex: 1">
      <mat-icon *ngIf="sizes.length > 0 && submenuRef">
        {{
          submenuRef.instance.expanded
            ? 'keyboard_arrow_right'
            : 'keyboard_arrow_down'
        }}
      </mat-icon>
    </div>
  </div>
  <div><ng-template #submenu *ngIf="sizes.length > 0"></ng-template></div>
</div>
