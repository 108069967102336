import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import embed from 'vega-embed';

@Component({
  selector: 'ic-builder-vegaemb',
  templateUrl: './vegaemb.component.html',
  styleUrls: ['./vegaemb.component.scss'],
})
export class VegaembComponent implements OnInit  {

vegaEmbed = embed;

constructor(
  private cd: ChangeDetectorRef,
  private el: ElementRef,
  private componentservice: ComponentsService,
  private store:Store
) {}

spec = {
  "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
  "title": "Distribution of Body Mass of Penguins",
  "width": 400,
  "height": 80,
  "data": {
    "url": "data/penguins.json"
  },
  "mark": "area",
  "transform": [
    {
      "density": "Body Mass (g)",
      "groupby": ["Species"],
      "extent": [2500, 6500]
    }
  ],
  "encoding": {
    "x": {"field": "value", "type": "quantitative", "title": "Body Mass (g)"},
    "y": {"field": "density", "type": "quantitative", "stack": "zero"},
    "color": {"field": "Species", "type": "nominal"}
  }
};

  ngOnInit() {
    


    //embed("#vis", null, { actions: false });
 }
}


