import { Component, Input, OnInit } from '@angular/core';
import {
  IIsComponent,
  Outlet2Service,
  SetComponent,
} from '@ic-builder/is-base';
import { Store } from '@ngxs/store';

@Component({
  selector: 'isenumpanel',
  templateUrl: './isenumpanel.component.html',
  styleUrls: ['./isenumpanel.component.scss'],
})
export class IsenumpanelComponent implements OnInit {
  @Input() multiselect: boolean = true;
  @Input() set namevalue(value: string) {
    this.enums = this.createNamevalueEnums(value);
    this.store.dispatch(
      new SetComponent({ componentlist: this.enums, outlet: this.id })
    );
  }
  get() {
    return this.enums;
  }

  id: number;
  enums: IIsComponent[] = [];

  nrCols: number = 1;
  colGap: string = '10px';

  constructor(private outletservice: Outlet2Service, private store: Store) {}

  ngOnInit(): void {
    this.setComponent();
  }

  namevalueValueChanges(value) {
    this.namevalue = value.replaceAll(`\\n`, `\n`);
    this.setComponent();
  }

  multiselectValueChanges(value) {
    this.multiselect = value != 'false';
    this.setComponent();
  }

  setComponent() {
    this.enums = this.createNamevalueEnums(this.namevalue);
    if (this.enums) {
      this.store.dispatch(
        new SetComponent({ componentlist: this.enums, outlet: this.id })
      );
    }
  }

  createEnum(namevalue: string, parentid: number) {
    const id = this.outletservice.uniqueId;
    return {
      parentid: parentid,
      id: id,
      name: namevalue + id,
      type: 'isenum2',
      flexsize: 1,
      flexorder: 4,
      config: {
        label: namevalue.split('=')[1],
        formInput: true,
        enum: namevalue.split('=')[0],
        multiselect: this.multiselect,
      },
      attribs: { style: { static: { '': { display: 'flex' } } } },
    };
  }

  createPanel() {
    const id = this.outletservice.uniqueId;
    return {
      parentid: this.id,
      id: id,
      name: 'Parameters',
      type: 'ispanel',
      flexsize: 1,
      flexorder: 1,
      config: {},
      attribs: {
        style: {
          static: {
            '': {
              width: '100%',
              height: '100%',
              position: 'relative',
              display: 'grid',
              'grid-template-columns': `repeat(${this.nrCols}, 1fr)`,
              'grid-column-gap': this.colGap,
            },
          },
        },
      },
    };
  }

  createNamevalueEnums(value: string) {
    if (!value) return;
    const split = value.split(`\n`);
    const panel = this.createPanel();
    const enums = split.map((nv) => this.createEnum(nv, panel.id));
    return [panel, ...enums];
  }
}
