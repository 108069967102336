import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';
import { GetMaxComponentNumber } from '@ic-builder/iscomp';

@Component({
  selector: 'isbar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss'],
})
export class BarComponent {
  constructor(
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private componentservice: ComponentsService
  ) {}

  series: Array<GroupedBar> = [];

  public defaultColors = [
    'rgb(160, 209, 255)',
    'rgb(247, 168, 10)',
    'rgb(243, 235, 143)',
  ];

  @ViewChild('dialogContainer', { read: ViewContainerRef })
  dialogContainer: ViewContainerRef | null = null;
  @ViewChild('Dialog', { read: TemplateRef }) dialog: TemplateRef<any> | null =
    null;

  private _minY = 0;
  private _maxY = 0;
  private _maxX = 0;
  private _margin = 0.2;
  private _data: any = [];

  // set determineColor(val:string) {
  //   this.determineColorFunction = new Function(val);
  // }

  determineColor : Function | undefined;
  colorInfo: Array<any> | undefined;

  // get determineColor() : Function | undefined {
  //   return this._determineColor;
  // }

  keys: string[] = [];

  get data() {
    return this._data;
  }

  yAxisValues = [250, 500, 750, 1000, 2000];
  yIntervalCnt = 6;

  @Input() xaxistextdegree = 0;

  calcyAxis(minY, maxY) {
    //this.yAxisValus =
  }

  displayInfo(ev: any, i: number, j: number) {
    const result: EmbeddedViewRef<any> =
      this.dialogContainer!.createEmbeddedView(this.dialog!, {
        data: this.componentservice.roundToTwo(this.series[i].bars[j].value),
      });

    const parentinfo = this.el.nativeElement.getBoundingClientRect();
    const info = ev.currentTarget!.getBoundingClientRect();

    const elementRef = result.rootNodes[0];

    elementRef.style.left =
      (info.left - parentinfo.left + 20).toString() + 'px';
    elementRef.style.top = (info.top - parentinfo.top - 20).toString() + 'px';
  }

  closeInfo() {
    this.dialogContainer?.clear();
  }

  @Input() set data(d: any) {
    if (d) {
      this.keys = Object.keys(d);
      const values: any[] = Object.values(d);

      this.series = [];

      const totalStacks: number[] = [];

      values.map((v: any) => {
        (Object.values(v) as number[]).map((c: number) => {
          totalStacks.push(c);
        });
      });

      // smallest yValue
      if (totalStacks.length) {
        this._minY = totalStacks.reduce((prev: number, cur: number) => {
          return prev < cur ? prev : cur;
        },0);

        this._minY = this._minY < 0 ? this._minY : 0;

        // largest yValue
        this._maxY = totalStacks.reduce((prev: number, cur: number) => {
          return prev > cur ? prev : cur;
        },0);
      }

      const base = this.baseX() * 100;

      for (let i = 0; i <= this.series.length; i++) {
        if (this.keys.includes(this.keys[i])) {
          this.series.push(
            new GroupedBar(
              Object.values(d[this.keys[i]]),
              i,
              base,
              this._minY,
              this._maxY
            )
          );
        }
      }

      this.cd.detectChanges();

      this._data = d;

      if (this.determineColor) {
        const bars = this.el.nativeElement.querySelectorAll('rect')
        
        for(let i=0; i < bars.length; i += 1) {
          bars[i].style.setProperty('fill', this.determineColor(i, this.colorInfo ? this.colorInfo[i] : null));
        }
      }
    }
  }

  getRelativeY(y: number) {
    if (y >= 0 && this._maxY != 0) {
      const base = 100 * this.baseX();

      return (base * ((this._maxY - y) / this._maxY)).toString() + '%';
    }
    if (this._maxY - this._minY != 0){
      return (100 * (y / (this._maxY - this._minY))).toString() + '%';
    }
    return '100%';
  }

  baseX() {
    if (this._maxY - this._minY != 0) {
      return this._maxY / (this._maxY - this._minY);
    }
    return 1;
  }

  get BaseXAxis(): string {
    if (this._minY == 0) {
      return '100%';
    }
    return (100 * this.baseX()).toString() + '%';
  }

  getWidth() {
    if (this.series.length + this._margin * (this.series.length + 1) != 0) {
      return (
        (
          100 /
          (this.series.length + this._margin * (this.series.length + 1))
        ).toString() + '%'
      );
    }
    return '100%';
  }

  getGroupLeft(position: number) {
    return (
      (
        (100 * (position + (position + 1) * this._margin)) /
        (this.series.length + this._margin * (this.series.length + 1))        
      ).toString() + '%'
    );
  }

  getGroupLefttext(position: number) {
    if (this.xaxistextdegree==-90){
      return (
        (100 * (position + (position + 1) * this._margin)) /
        (this.series.length + this._margin * (this.series.length + 1))
        +
        ((100 /
          (this.series.length + this._margin * (this.series.length + 1)))/2)
      ).toString() + '%'
    } else {  
    return (
      (
        (100 * (position + (position + 1) * this._margin)) /
        (this.series.length + this._margin * (this.series.length + 1))
      ).toString() + '%'
    );
    }
  }  

  gettextxaxisstyle(position: number) {
    return this.xaxistextdegree==-90?"transform:rotate(90deg)":"transform:rotate(0deg)"
}    

  gettextxaxisleft(position: number) {
      return this.xaxistextdegree==-90?"5":"50%"
  }    

  gettextxaxistop(position: number) {
    return this.xaxistextdegree==-90?"-5":"5"
}    
}

export class GroupedBar {
  bars: Bar[] = [];

  constructor(
    private vals: number[],
    private position: number,
    private basex: number,
    private miny: number,
    private maxy: number
  ) {
    let used = 0;

    this.bars = Object.values(vals).map((v: number) => {
      used += v;
      return new Bar(v, used, this.basex, this.miny, this.maxy);
    });
  }

  getBarLeft(position: number) {
    if (this.bars.length > 0) {
      let s: any = 100 * (position / this.bars.length);
       if (s == 'NaN') {
        s = 0;
      }
      return s.toString() + '%';
    }
    return '0%';
  }

  getBarWidth() {
    if (this.bars.length > 0) {
      return (100 / this.bars.length).toString() + '%';
    }
    return '100%';
  }
}

class Bar {
  constructor(
    public value: number,
    private used: number,
    private basex: number,
    private miny: number,
    private maxy: number
  ) {}

  getTop() {
    if ((this.value <= 0) || (this.maxy==0)){
      return this.basex.toString() + '%';
    }
    return (
      ((this.basex * (this.maxy - this.value)) / this.maxy).toString() + '%'
    );
  }

  getHeight() {
    if ((this.value < 0) && (this.miny!=0)){
      const res = ((100 - this.basex) * this.value) / this.miny;

      return res.toString() + '%';
    }
    if (this.maxy!=0){
      return ((this.basex * this.value) / this.maxy).toString() + '%';
    } 
    return '0%';
  }

  getFormatValue() {
    return Math.round(100 * this.value) / 100
  }
}
