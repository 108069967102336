import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'isfilterpanel',
  templateUrl: './isfilterpanel.component.html',
  styleUrls: ['./isfilterpanel.component.scss'],
})
export class IsfilterpanelComponent implements OnInit {
  @Input() expression: string;
  expressionCleared: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  clearExpression() {
    this.expressionCleared.next(true);
    this.cd.detectChanges();
  }
}
