import { defer, Observable } from 'rxjs';
import { BroadcastChannel } from 'broadcast-channel';
import { shareReplay } from 'rxjs/operators';

export const createBroadcastChannelObservable = <T>(name: string) =>
  defer(
    () =>
      new Observable<BroadcastChannel<T>>((observer) => {
        let activeChannel: BroadcastChannel<T> | undefined;
        const createChannel = () => {
          const channel = new BroadcastChannel<T>(name, {
            idb: {
              onclose: () => {
                // the onclose event is just the IndexedDB closing.
                // you should also close the channel before creating
                // a new one.
                channel.close();
                activeChannel = createChannel();
                observer.next(activeChannel);
              },
            },
          });
          return channel;
        };
        activeChannel = createChannel();
        observer.next(activeChannel);

        return {
          unsubscribe() {
            activeChannel?.close();
          },
        };
      })
  ).pipe(shareReplay(1));
