import { FormControl, Validators } from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'iscontrol',
  templateUrl: './iscontrol.component.html',
  styleUrls: ['./iscontrol.component.scss'],
})
export class IscontrolComponent implements OnInit {
  _id: number;
  @Input('id') set id(val: number) {
    if (this._id != val) {
      this.comp.updateid(this._id, this);
      this._id = val;
    }
  }
  get id(): number {
    return this._id;
  }

  @Input() controlname = '';

  @Input() formInput = true;
  validators: Array<any> = [];
  valid = true;
  warning = false;
  warningMessages = [];
  dialogRef: MatDialogRef<any>;
  @Input('masterdetail') masterdetail: any = null;

  @ViewChild('warningMes', { read: TemplateRef }) warningMes: TemplateRef<any>;

  constructor(
    public comp: ComponentsService,
    public store: Store,
    public outlet: Outlet2Service,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  initControl(controlid, initialValue, controlname?, usevalidator?, disabled?) {
    if (!controlid) {
      controlid = this.outlet.uniqueId;
      this._id = controlid;
      //this.id = this.id?this.id:controlid
      //this.store.dispatch(new UpdateProperty({compid:this.id,path:'config.controlid',value:controlid}));
    }
    if (!controlname) {
      this.controlname = 'controlname' + controlid.toString();
      controlid = this._id + '.' + this.controlname;
    } else {
      this.controlname = controlname;
    }

    const formControl = new FormControl({ value: initialValue, disabled });
    this.comp.formcontrols.set(controlid.toString(), {
      name: controlname ? controlname : this.controlname,
      formcontrol: formControl,
    });

    if (!(usevalidator === false)) {
      const validators = [];

      this.validators.map((val) => {
        if (
          ['minLength', 'maxLength', 'min', 'max', 'patttern'].includes(
            val.validatortype
          )
        ) {
          validators.push(
            Validators[val.validatortype](val[val.validatortype])
          );
        }
      });
      formControl.setValidators(validators);
    }
    return controlid;
  }

  getFormControl(controlid) {
    return this.comp.formcontrols.get(controlid.toString()).formcontrol;
  }

  addValidator(val) {
    this.validators.push(val);
  }

  makeValid() {
    this.valid = true;
    this.el.nativeElement.classList.remove('invalid');
    this.cd.detectChanges();
  }

  openwarning(ev) {
    let infoform,infocomponent;

    let form = this.comp?.findisparent(this,null,'isform');
    if (!form){ form = this.comp?.findisparent(this,null,'iswizard') };
    if (form){
      infoform = form.el.nativeElement.getBoundingClientRect();
    }
    infocomponent = this.el.nativeElement.getBoundingClientRect();

    const info = ev.currentTarget.getBoundingClientRect();
    
    if ((infoform.right - info.right) < 100) {
      this.NgZone.run(() => {
        this.dialogRef = this.dialog.open(this.warningMes, {
          hasBackdrop: false,
          position: {
            left: (infocomponent.left+10).toString() + 'px',
            top: infocomponent.bottom.toString() + 'px',
          },
        });
      });
    } else
    {
    this.NgZone.run(() => {
      this.dialogRef = this.dialog.open(this.warningMes, {
        hasBackdrop: false,
        position: {
          left: infoform.right.toString() + 'px',
          top: infoform.bottom.toString() + 'px',
        },
      });
    });
    }
  }

  closewarning() {    
    this.NgZone.run(() => {
      this.dialogRef?.close();
      this.warning = false;
    });
  }
}
