<svg>
  <circle
    id="default"
    cx="50%"
    cy="55%"
    r="30%"
    fill="none"
    stroke="none"
    stroke-width="20%"
  />

  <circle
    class="circle"
    cx="50%"
    cy="55%"
    r="30%"
    stroke-width="20%"
    *ngFor="let s of data; let i = index"
    fill="none"
    [attr.stroke]="s.color"
    (click)="setFocus(i)"
  ></circle>

  <text
    x="50%"
    y="55%"
    class="value"
    text-anchor="middle"
    font-size="20"
    *ngIf="selectedIndex != -1"
  >
    {{ data[selectedIndex].value }}
  </text>
  <text
    x="50%"
    y="55%"
    dy="20px"
    text-anchor="middle"
    *ngIf="selectedIndex != -1"
  >
    {{ data[selectedIndex].label }}
  </text>

  <!--Title-->
  <text
    x="50%"
    y="25px"
    font-size="20"
    text-anchor="middle"
    class="value"
    *ngIf="title"
  >
    {{ title }}
  </text>
</svg>

<div class="legend" *ngIf="showLegend">
  <span
    *ngFor="let d of data; let i = index"
    [style.background-color]="d.color"
    style="grid-column: 1"
    (click)="setFocus(i)"
    id="legend-color"
  >
  </span>
  <span *ngFor="let d of data" style="grid-column: 2">{{ d.label }}</span>
</div>
