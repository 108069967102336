<!-- <input type="checkbox" istag="checkbox" class="checkbox" [formControl]="formControl" /> -->
<iscustomcheckbox
  [formControl]="formControl"
  istag="checkbox"
  class="checkbox"
></iscustomcheckbox>

<div istag="label" class="label" id="labeltxt">
  {{ label }}
</div>
