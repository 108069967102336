<div class="row">
  <div class="content" id="labeltxt">
    {{ label }}
  </div>

  <div
    [style.color]="icon.value === formControl.value ? 'black' : '#cccccc'"
    *ngFor="let icon of icons"
    (click)="
      icon.value === formControl.value
        ? formControl.setValue('')
        : formControl.setValue(icon.value)
    "
  >
    <mat-icon class="option">{{ icon.icon }}</mat-icon>
  </div>
</div>
