import { Injectable } from '@angular/core';


let uniqueIdInstanceNr = 0;

@Injectable({
  providedIn: 'root'
})
export class UniquenbrService {

  private _uniqueIdInstanceNr: number;

  get uniqueIdInstanceNr() {
    return this._uniqueIdInstanceNr;
  }

  private _uniqueId:number = -3000;

  get uniqueId() {
    //console.log('getuniqueid : ',this._uniqueIdInstanceNr,' ',this._uniqueId--);
    return this._uniqueId--;

  }

  constructor() { 
    this._uniqueIdInstanceNr = uniqueIdInstanceNr++;
    // console.trace(
    //   `%cClass: UniquenbrService, Function: constructor(this._uniqueIdInstanceNr): `,
    //   'color: black;',
    //   this._uniqueIdInstanceNr
    // ); 

  }
}
