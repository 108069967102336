import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  SimpleChange,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'issizeinput2',
  templateUrl: './issizeinput2.component.html',
  styleUrls: ['./issizeinput2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Issizeinput2Component),
      multi: true,
    },
  ],
})
export class Issizeinput2Component implements ControlValueAccessor {
  constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

  private _value: string;

  formControlDirective: FormControlDirective = new FormControlDirective(
    null,
    null,
    [this],
    null
  );
  formControl: FormControl;

  @Input('options') options: string[] = ['vh', 'px', '%'];

  get value() {
    return this._value;
  }

  inputValueChanges(ev: any) {
    const val = ev.currentTarget.value;
    const select = this.el.nativeElement.querySelector('select').value;

    this.writeValue(val + select);
  }

  selectValueChanges(ev: any) {
    //this.cd.detectChanges();

    const val = this.el.nativeElement.querySelector('input').value;
    const select = ev.currentTarget.value;

    this.writeValue(val + select);
  }

  ngOnInit(): void {
    this.formControlDirective.form = this.formControl;
    const changes = {};
    changes['form'] = new SimpleChange(null, null, true);
    this.formControlDirective.ngOnChanges(changes);
  }

  // ControlValueAccessor implementation
  _onChange: (_: any) => void = (_) => {};

  _onTouched = () => {};

  writeValue(value: string): void {
    this.cd.detectChanges();

    // trace type
    const type = this.options.find((o) => value.includes(o));

    // set select
    const select = this.el.nativeElement.querySelector('select');
    select.value = type;

    // set number
    const input = this.el.nativeElement.querySelector('input');
    input.value = value.substring(0, value.length - type.length);

    this._onChange(value);
    this._value = value;
  }

  // setDisabledState(isDisabled: boolean): void {

  // }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
