import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IsfilterComponent } from '../../isfilter/isfilter.component';

@Component({
  selector: 'isgriddropdown',
  templateUrl: './isgriddropdown.component.html',
  styleUrls: ['./isgriddropdown.component.scss'],
})
export class IsgriddropdownComponent implements OnInit {
  @Input() col: string;
  showfilter = false;
  @ViewChild(IsfilterComponent, { static: true }) filter: IsfilterComponent;
  openlist: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  openFilter(ev) {
    ev.stopPropagation();

    this.showfilter = !this.showfilter;
    const box = this.el.nativeElement.parentElement.getBoundingClientRect();
    const dropdown = this.el.nativeElement.querySelector('#dropdown');
    dropdown.style['top'] = box.bottom + 'px';
    dropdown.style['left'] = box.left + 'px';
    dropdown.style['display'] = this.showfilter ? 'block' : 'none';

    this.cd.detectChanges();
  }
}
