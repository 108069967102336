import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Expression } from '../expression.model';
import { LeafExpressionFormData } from '../isleafexpression/isleafexpression.component';

export interface ExpressionFormData {
  operator: string;
  leftExpression: LeafExpressionFormData | ExpressionFormData;
  rightExpression: LeafExpressionFormData | ExpressionFormData;
}

@Component({
  selector: 'isexpression',
  templateUrl: './isexpression.component.html',
  styleUrls: ['./isexpression.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IsexpressionComponent),
      multi: true,
    },
  ],
})
export class IsexpressionComponent implements ControlValueAccessor, OnInit {
  form: FormGroup;
  @Input() fieldOptions;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() splitevent: EventEmitter<void> = new EventEmitter<void>();
  open = false;

  private _onChange: (value: ExpressionFormData | null | undefined) => void;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.createFormGroup();
    this.setupObservables();
  }

  // start interface ControlValueAccessor
  writeValue(value: ExpressionFormData | null | undefined): void {
    if (!value) {
      return;
    }

    this.form.patchValue(value);
  }

  registerOnChange(fn: (value: ExpressionFormData | null | undefined) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // TODO: implement this method
    // throw new Error('registerOnTouched not implemented');
  }

  setDisabledState(isDisabled: boolean): void {
    // TODO: implement this method
    // throw new Error('setDisabledState not implemented');
  }
  // end interface

  private createFormGroup() {
    this.form = new FormGroup({
      operator: new FormControl(null),
      leftExpression: new FormControl(null),
      rightExpression: new FormControl(null),
    });
  }

  private setupObservables() {
    this.form.valueChanges.subscribe((value) => {
      if (this._onChange) {
        this._onChange(value);
      }
    });
  }

  add(side) {
    this.form.get(side).setValue({
      leftExpression: null,
      operator: null,
      rightExpression: null,
    });
    this.cd.detectChanges();
  }

  addLeaf(side) {
    this.form.get(side).setValue({
      field: this.fieldOptions.length == 1 ? this.fieldOptions[0] : null,
      operator: null,
      value: null,
    });

    this.cd.detectChanges();
  }

  isLeaf(expression: LeafExpressionFormData | ExpressionFormData): expression is LeafExpressionFormData {
    return (expression as LeafExpressionFormData).field !== undefined;
  }

  toggleOpen() {
    this.open = !this.open;
    this.cd.detectChanges();
  }

  expressionFromJson(json) {
    if (json) {
      if (this.isLeaf(json)) {
        const left = json['field'] ? json['field'] : ' ';
        const operator = json['operator'] ? json['operator'] : ' ';
        const right = json['value'] ? json['value'] : ' ';
        return left + ' ' + operator + ' ' + right;
      } else {
        let left = this.expressionFromJson(json['leftExpression']);
        left = left ? left : ' ';
        const operator = json['operator'] ? json['operator'] : ' ';
        let right = this.expressionFromJson(json['rightExpression']);
        right = right ? right : ' ';
        return '(' + left + ' ' + operator + ' ' + right + ')';
      }
    }
  }

  printResult(value) {
    return this.expressionFromJson(value).slice(1, -1);
  }

  removeExp(side) {
    this.form.removeControl(side);
    this.form.addControl(side, new FormControl(null));
    this.cd.detectChanges();
  }

  splitExp(side) {
    const values = this.form.get(side).value;
    this.removeExp(side);
    this.form.get(side).setValue({
      leftExpression: values,
      operator: null,
      rightExpression: null,
    });
    this.cd.detectChanges();
  }
}
