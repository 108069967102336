import {
  Inject,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { SessionService } from './session.service';
import { AuthInterceptor } from './auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth.guard';
import { LoggingInterceptor } from './httpmonitor.service';

const AUTH_FORROOT_GUARD = new InjectionToken<void>('AUTH_FORROOT_GUARD');

@NgModule()
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        SessionService,
        AuthGuard,
        {
          provide: AUTH_FORROOT_GUARD,
          useFactory: provideForRootGuard,
          deps: [[SessionService, new Optional(), new SkipSelf()]],
        },
        LoggingInterceptor,
        [
          {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
          },
          {
            provide: HTTP_INTERCEPTORS,
            useClass: LoggingInterceptor,
            multi: true,
          },
        ],
      ],
    };
  }
  // Note: We are injecting the SessionService so it gets created eagerly...
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(
    @Optional() @Inject(AUTH_FORROOT_GUARD) guard: string,
    @Optional() sessionService: SessionService
  ) {
    // do nothing
  }
}

export function provideForRootGuard(sessionService: SessionService): string {
  if (sessionService) {
    throw new Error(
      `AuthModule.forRoot() called twice. Lazy loaded modules should use AuthModule.forChild() instead.`
    );
  }
  return 'guarded';
}
