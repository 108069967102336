<form [formGroup]="form">
  <div style="display: grid; grid-template-columns: 24px 4fr 24px 24px; background-color: var(--300)">
    <mat-icon (click)="toggleOpen()" [style.transform]="open ? 'rotate(90deg)' : ''" class="icon"
      >keyboard_arrow_right</mat-icon
    >
    <p style="margin: 0">
      {{
        (form.get('field').value ? form.get('field').value : '') +
          ' ' +
          (form.get('operator').value ? form.get('operator').value : '') +
          ' ' +
          (form.get('value').value ? form.get('value').value : '')
      }}
    </p>
    <mat-icon (click)="splitevent.emit()" style="transform: rotate(90deg)" class="icon">call_split</mat-icon>
    <mat-icon (click)="remove.emit()" class="icon">remove</mat-icon>
  </div>
  <div [style.display]="open ? 'grid' : 'none'">
    <is-style-select
      *ngIf="this.fieldOptions.length !== 1"
      [frmGrp]="form"
      name="field"
      label="field"
      [options]="fieldOptions"
      [valueOnly]="true"
      [objectList]="false"
    ></is-style-select>
    
    <is-style-select
      [frmGrp]="form"
      name="operator"
      label="operator"
      [options]="operatorOptions"
      [valueOnly]="true"
      [objectList]="false"
    ></is-style-select>
    <is-style-input [frmGrp]="form" name="value" label="value"></is-style-input>
  </div>
</form>
