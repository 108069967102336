<mat-icon
  (click)="openFilter($event)"
  [style.transform]="showfilter ? 'rotate(180deg)' : ''"
  style="cursor: pointer; transition: all 0.2s ease; text-indent: 0"
  >keyboard_arrow_down</mat-icon
>
<div id="dropdown" (click)="$event.stopPropagation(); $event.preventDefault()">
  <isfilter class="filter"></isfilter>
  <mat-icon style="cursor: pointer" (click)="openlist.next(true)" class="filter"
    >filter_list</mat-icon
  >
</div>
