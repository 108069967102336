import { IsGridRegistry } from './../../isgrid.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fieldselector',
  templateUrl: './fieldselector.component.html',
  styleUrls: ['./fieldselector.component.scss'],
})
export class FieldselectorComponent implements OnInit {
  columns: any[];
  data: any[];
  overlayRef;
  gridid: number;

  constructor(private grid: IsGridRegistry) {}

  ngOnInit(): void {}

  close() {
    const fieldselector = this.grid.get(-1009);
    const grid = this.grid.get(this.gridid).changeVisibility(
      fieldselector.data.map((f) => {
        return f.select;
      })
    );

    this.overlayRef.detach();
    //this.dialogRef.close()
  }
}
