<div *ngIf="label" istag="label" class="content label" id="labeltxt">
  {{ label }}
</div>

<!-- <select
  istag="selectoption"
  id="selectoption"
  class="select"
  [formControl]="formControl"  
>
  
<option istag="option" *ngFor="let enum of (enumerationlist$ | async)" [value]="enum.id" class="option" >
    {{ enum.description }}
  </option>
</select> -->

<iscustomselect
  istag="selectoption"
  id="selectoption"
  class="select"
  [formControl]="formControl"
  valuefield="id"
  descriptionfield="description"
  [options]="(enumerationlist$ | async)"
>
</iscustomselect>

<mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
<mat-icon
  istag="warning"
  *ngIf="warning"
  style="color: orange"
  (mouseenter)="openwarning($event)"
  (mouseleave)="closewarning()"
  >warning</mat-icon
>

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
