import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'isday2',
  templateUrl: './isday2.component.html',
  styleUrls: ['../isyearplanner2.component.scss', './isday2.component.scss'],
})
export class Isday2Component implements AfterViewInit {
  constructor(private el: ElementRef) {}

  @HostListener('focus')
  focus() {
    console.log('focused day');
  }

  @HostListener('keydown.a', ['$event'])
  keydown(ev) {
    console.log('keypressed Ctrl + a', ev);
    if (ev.ctrlKey) {
    }
  }

  @Input('day') day: number;

  ngAfterViewInit(): void {
    this.el.nativeElement.setAttribute('day', this.day);
    this.el.nativeElement.setAttribute('tabindex', 1);
  }
}
