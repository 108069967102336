import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import {
  ComponentsService,
  IIsMenuItem,
  IsMenuItem,
  Outlet2Service,
} from '@ic-builder/is-base';
import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { LoadMenu } from '../isapplication/isapplication.action';

@Component({
  selector: 'ismenu',
  templateUrl: './ismenu.component.html',
  styleUrls: ['./ismenu.component.scss'],
})
export class IsmenuComponent implements OnInit {
  menu: Observable<IsMenuItem[]>;
  config: any;
  //treeControl2: NestedTreeControl<RightClickItem> (node => node.children);
  //dataSource = new ArrayDataSource(TREE_DATA);

  ds: ArrayDataSource<IsMenuItem>;
  treeControl = new NestedTreeControl<IIsMenuItem>((node) => node.children);
  module: number;
  overlay: number;
  name: string;
  menuname: string;
  menucmp: number;
  menuservice: Observable<IsMenuItem>;
  subscription: Subscription;
  menux: IsMenuItem;

  hasChild = (_: number, node: IsMenuItem) =>
    !!node.children && node.children.length > 0;

  constructor(private store: Store, private compService: ComponentsService) {}

  ngOnInit() {
    //this.menu = this.store.select(state => state['IsApplicationState'].modules[this.module].menus[this.name])

    if (this.menuname) {
      this.loadmenu(this.module, this.menuname);
    } else if (this.menucmp) {
      this.menux = this.store.selectSnapshot(Outlet2Service.menu(this.menucmp));
      if (this.menux) {
        this.ds = new ArrayDataSource(this.menux.children);
        const item: IsMenuItem = this.menux;
        if (item.default) {
          const node: IsMenuItem = item.children.find((f) => {
            return f && f.id === item.default;
          });
          if (node) {
            this.store.dispatch({
              type: node.payload.action,
              payload: {
                id: node.payload.id,
                name: node.name,
                outlet: node.payload.outlet,
              },
            });
          }
        }
      }
      /*
        this.subscription = this.menuservice.subscribe(menu =>
          {
            console.log('subscribe outlet2menu',menu);
            if(menu){
              this.ds = new ArrayDataSource(menu.children);
              let item:IsMenuItem=menu;
              if (item.default){
                let node:IsMenuItem = item.children.find(f => {return f && f.id === item.default});
                if (node){
                  this.store.dispatch({"type":node.payload.action,"payload":{id:node.payload.id,name:node.name,outlet:node.payload.outlet}})
                }
              }
              this.store.dispatch(new LoadComponent({id:this.overlay,name:'overlay'}));
            }
          }
        );
        this.store.dispatch(new LoadComponent({id:this.menucmp,name:'pageformmenu',kind:"Menu"}));
        */
    }
    if (this.config) {
      if (this.config.menuname) {
        this.loadmenu(this.config.module, this.config.menuname);
      }
    }
  }

  loadmenu(module: number, menuname: string) {
    this.store.dispatch(
      new LoadMenu({ module: module.toString(), menu: menuname })
    );
    const x = module + '.' + menuname;
    this.store
      .select((state) => state['isapplicationstate'].menus.get(x))
      .subscribe((data) => {
        if (data) {
          this.ds = new ArrayDataSource(data.children);
        }
      });
  }

  click(node) {
    if (node.menutype === 'function') {
      const contextid = this.store.selectSnapshot(Outlet2Service.contextid);
      const comp = this.compService.widgets.get(contextid);
      const a = comp.componentType.prototype;
      const params = [comp.instance];
      //let b = a.fieldselector.arguments
      a[node.function](...params);
    } else {
      this.store.dispatch({
        type: node.action,
        payload: {
          id: node.payload.id,
          name: node.payload.name,
          outlet: node.payload.outlet,
        },
      });
    }
  }
}
