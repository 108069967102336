import { IsGridRegistry } from './../../isgrid.service';
import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'isselectheader',
  templateUrl: './isselectheader.component.html',
  styleUrls: ['./isselectheader.component.scss'],
})
export class IsselectheaderComponent implements AfterViewInit {
  gridid: number;
  ischecked = false;

  colid: string;
  colindex: number;
  sort: string;
  sorticon: string;
  formControl = new FormControl(true);
  headervisible: boolean;

  constructor(
    private grid: IsGridRegistry,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    if (!this.headervisible) {
      this.renderer.setStyle(
        this.el.nativeElement.querySelector('iscustomcheckbox'),
        'display',
        'none'
      );
    }

    this.el.nativeElement.addEventListener('click', (ev) => {
      this.grid.views.get(this.gridid).resetallsortedcolumns(this.colid);
      if (!this.sort) {
        this.sortup();
      } else {
        if (this.sort === 'down') {
          this.sortup();
        } else {
          this.sortdown();
        }
      }
      this.grid.views.get(this.gridid).addsortcolumn(this.colid, this.sort);
    });
  }

  selectAll() {
    for (const data of this.grid.get(this.gridid).data) {
      data.select = true;
    }

    this.grid.get(this.gridid).celCompRef.map((row) => {
      (row.select.instance as any).checkedVal = true;

      Object.values(row).map((cel: ComponentRef<any>) => {
        cel.location.nativeElement.classList.add('row-selectedtest');
      });
    });

    this.grid
      .get(this.gridid)
      .datasets.set('selected', this.grid.get(this.gridid).data);

    this.ischecked = true;
    this.formControl.setValue(true);
  }

  deselectAll() {
    for (const data of this.grid.get(this.gridid).data) {
      data.select = false;
    }

    this.grid.get(this.gridid).celCompRef.map((row) => {
      (row.select.instance as any).checkedVal = false;

      Object.values(row).map((cel: ComponentRef<any>) => {
        cel.location.nativeElement.classList.remove('row-selectedtest');
      });
    });

    this.el.nativeElement
      .querySelector('iscustomcheckbox')
      .classList.remove('notallcheck');

    this.grid.get(this.gridid).datasets.set('selected', []);
    this.ischecked = false;
    this.formControl.setValue(false);
  }

  check() {
    // this.ischecked = !this.ischecked;
    this.formControl.value;

    if (this.formControl.value) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  sortup() {
    this.sort = 'up';
    this.sorticon = 'arrow_upward';
  }

  sortdown() {
    this.sort = 'down';
    this.sorticon = 'arrow_downward';
  }

  resetsort() {
    this.sort = null;
    this.sorticon = null;
  }
}
