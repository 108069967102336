import { MenuhandlercontainerComponent } from './../menuhandlercontainer/menuhandlercontainer.component';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentsService, IIsComponent } from '@ic-builder/is-base';

@Component({
  selector: 'menuhandler',
  templateUrl: './menuhandler.component.html',
  styleUrls: ['./menuhandler.component.scss'],
})
export class MenuhandlerComponent implements OnInit {
  constructor(private resolve: ComponentFactoryResolver, private comp: ComponentsService) {}

  children: IIsComponent[];
  id: number;
  parentid: number;
  menuitems = [];
  menucontainerRefs = [];
  data: any;

  renderHorizontal = false;
  phonemenu = false;

  @ViewChild('menucontainer', { read: ViewContainerRef }) menucontainers: ViewContainerRef;

  ngOnInit(): void {}

  allMenuItems(children, level) {
    for (const child of children) {
      if (this.menuitems.length == level) {
        this.menuitems.push([]);
      }

      const widget = this.comp.widgets.get(child.id);
      widget.instance.level = level;

      widget.location.nativeElement.setAttribute('level', level.toString());

      this.menuitems[level].push(widget);

      if (child.children) {
        this.allMenuItems(child.children, level + 1);
      }
    }
  }

  openContainer(level, id) {
    if (this.menucontainerRefs[level].instance.menucontaineritems.length > 0) {
      this.closeContainer(level);
    }

    const toInsert = this.menuitems[level + 1].filter((m) => {
      return m.instance.parentid == id;
    });
    const oldItem = this.menuitems[level].find((m) => {
      return m.instance.id == id;
    });
    const info = oldItem.location.nativeElement.getBoundingClientRect();

    if (level == 0 && this.renderHorizontal) {
      //debugger;
      //this.menucontainerRefs[level+1].location.nativeElement.style.left = (info.width*oldItem.instance.flexorder-2).toString()+'px';
      this.menucontainerRefs[level + 1].location.nativeElement.style.left =
        (info.width * (oldItem.instance.flexorder - 1)).toString() + 'px';
      this.menucontainerRefs[level + 1].location.nativeElement.style.top = info.height.toString() + 'px';
    } else {
      this.menucontainerRefs[level + 1].location.nativeElement.style.left = info.width.toString() + 'px';
      this.menucontainerRefs[level + 1].location.nativeElement.style.top =
        (5 + info.height * (oldItem.instance.flexorder - 1)).toString() + 'px';
    }
    for (const insert of toInsert) {
      this.menucontainerRefs[level + 1].instance.menucontaineritems.insert(insert.hostView);

      if (insert.instance.children) {
        const mouseEnterListener = {
          handleEvent: (ev) => {
            this.openContainer(level + 1, insert.instance.id);
          },
        };

        insert.location.nativeElement.addEventListener('mouseenter', mouseEnterListener);
      }
      const mouseLeaveListener = {
        handleEvent: (ev) => {
          if (!ev.toElement) {
            this.closeAll();
            return;
          }

          let newlevel = ev.toElement.getAttribute('level');
          if (!newlevel) {
            newlevel = ev.toElement.parentElement.getAttribute('level');
          }
          if (parseInt(newlevel) <= level + 1 && insert.instance.children) {
            if (ev.currentTarget == ev.target) {
              for (let i = level; i < this.menucontainerRefs.length - 1; i++) {
                this.closeContainer(i + 1);
              }
            }
          }
          if (!newlevel) {
            for (let i = 0; i < this.menucontainerRefs.length - 1; i++) {
              this.closeContainer(i);
            }
          }
        },
      };
      insert.location.nativeElement.addEventListener('mouseleave', mouseLeaveListener);
    }

    if (this.menucontainerRefs[level + 1]) {
      this.menucontainerRefs[level + 1].changeDetectorRef.detectChanges();
    }
    //this.menucontainerRefs[level+1]
  }

  closeContainer(level) {
    if (!this.menucontainerRefs[level + 1]) {
      return;
    }

    for (let i = this.menucontainerRefs[level + 1].instance.menucontaineritems.length - 1; i >= 0; i--) {
      this.menucontainerRefs[level + 1].instance.menucontaineritems.detach(i);
    }
    if (this.menucontainerRefs[level + 1]) {
      this.menucontainerRefs[level + 1].changeDetectorRef.detectChanges();
    }
  }

  onLoaded() {
    for (const container of this.menucontainerRefs) {
      while (container.length > 0) {
        container.detach(0);
      }
    }

    while (this.menucontainers.length > 0) {
      this.menucontainers.detach(0);
    }

    this.menuitems = [];

    this.menucontainerRefs = [];

    this.allMenuItems(this.children, 0);

    const factory = this.resolve.resolveComponentFactory(MenuhandlercontainerComponent);

    let containerRef = this.menucontainers.createComponent(factory);
    console.log('handlerphone: ', this.phonemenu);
    containerRef.instance.phonemenu = this.phonemenu;
    containerRef.changeDetectorRef.detectChanges();
    this.menucontainerRefs.push(containerRef);

    if (this.renderHorizontal) {
      const menucontainer = containerRef.location.nativeElement.querySelector('.menucontainer');
      menucontainer.style['flex-direction'] = 'row';
    }

    for (let i = 0; i < this.menuitems.length - 1; i++) {
      containerRef = this.menucontainerRefs[i].instance.menucontainer.createComponent(factory);
      this.menucontainerRefs.push(containerRef);

      containerRef.changeDetectorRef.detectChanges();
    }

    let teller = 0;
    const classname = this.phonemenu ? 'phonemenuitem' : 'menuitem';
    if (this.menuitems.length) {
      for (const item of this.menuitems[0]) {
        if (this.renderHorizontal) {
          item.instance.direction = 'horizontal';
        }
        item.location.nativeElement.classList.add(classname);
        item.location.nativeElement.setAttribute('istag', classname);
        if (this.phonemenu) {
          item.instance.phonemenu = true;
          item.instance['col'] = (teller % 4) + 2;
          item.instance['row'] = Math.floor(teller / 4) + 2;
          item.location.nativeElement.style['grid-column-start'] = item.instance['col'];
          item.location.nativeElement.style['grid-column-end'] = item.instance['col'] + 1;
          item.location.nativeElement.style['grid-row-start'] = item.instance['row'];
          item.location.nativeElement.style['grid-row-end'] = item.instance['row'] + 1;
        }
        teller++;

        this.menucontainerRefs[0].instance.menucontaineritems.insert(item.hostView);

        const mouseEnterListener = {
          handleEvent: (ev) => {
            if (item.instance.children ? item.instance.children.length > 0 : false) {
              this.openContainer(0, item.instance.id);
            }
          },
        };

        const mouseLeaveListener = {
          handleEvent: (ev) => {
            if (!ev.toElement) {
              this.closeAll();
              return;
            }

            let level = ev.toElement.getAttribute('level');
            if (!level) {
              level = ev.toElement.parentElement.getAttribute('level');
            }
            if (parseInt(level) <= 0 || !level) {
              if (ev.currentTarget == ev.target) {
                for (let i = 0; i < this.menucontainerRefs.length - 1; i++) {
                  this.closeContainer(i);
                }
              }
            }
          },
        };

        item.location.nativeElement.addEventListener('mouseenter', mouseEnterListener);

        item.location.nativeElement.addEventListener('mouseleave', mouseLeaveListener);
      }
    }

    //let style = this.comp.defaultStyles.get(this.id)

    if (this.comp.defaultStylesSub.get(this.id).get(classname)) {
      for (const menu of this.menuitems) {
        for (const item of menu) {
          item.location.nativeElement.setAttribute(
            'class',
            this.comp.theme + '-' + this.id.toString() + '-' + classname
          );
          //item.location.nativeElement.setAttribute('menuitem','');
          /*for(let [key,value] of Object.entries(this.comp.defaultStylesSub.get(this.id).get('menuitem'))){
            item.location.nativeElement.style[key] = value;
            item.changeDetectorRef.detectChanges();
          }*/
        }
      }
    }
    this.menucontainerRefs.map((item) => item.changeDetectorRef.detectChanges());
    //this.comp.setAttribs({id:this.id},this.comp.widgets.get(this.id))
    //this.comp.addAtribs({id:this.id},this.comp.widgets.get(this.id))
  }

  closeAll() {
    for (let i = 0; i < this.menucontainerRefs.length - 1; i++) {
      this.closeContainer(i);
    }
  }

  setOrigin(origin) {
    for (const menulevel of this.menuitems) {
      for (const item of menulevel) {
        item.instance.origin = origin;
      }
    }
  }

  setInfo(info) {
    for (const menulevel of this.menuitems) {
      for (const item of menulevel) {
        item.instance.info = info;
      }
    }
  }

  addItem() {}
}
