import { ComponentsService, IslanguageService } from '@ic-builder/is-base';
import { AndOrComponent } from './and-or/and-or.component';
import { IsexpcontainerComponent } from './isexpcontainer/isexpcontainer.component';
import { FormGroup, FormControl } from '@angular/forms';
import {
  Component,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
} from '@angular/core';

@Component({
  selector: 'isexpfilter',
  templateUrl: './isexpfilter.component.html',
  styleUrls: ['./isexpfilter.component.scss'],
})
export class IsexpfilterComponent implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    private compfactory: ComponentFactoryResolver,
    private compservice: ComponentsService,
    private languages: IslanguageService
  ) {}

  containers: Array<number> = [];

  @ViewChild('container', { read: ViewContainerRef }) container;
  @Input() index: number;
  @Input() usefielddefs: boolean;
  initdone = false;

  toggled = false;

  formGroup: FormGroup = new FormGroup({});
  formControl: FormControl = new FormControl('');

  lastCmpRef: ComponentRef<IsexpcontainerComponent>;
  cmpRefs: Array<ComponentRef<IsexpcontainerComponent | IsexpfilterComponent>> = [];

  mode = 'local';

  columnOptions = [];
  initialValue: any[] = [];
  cname = 'filter';

  #gridid: number = null;

  counter = 0;

  topLevel = true;

  lblExecute:string;

  activeFunction = (row: any) => {
    if (this.cmpRefs.length == 0) return true;
    
    let results = [];
    this.cmpRefs.map((c: ComponentRef<any>) => {
      if (c.instance.cname == 'container' || c.instance.cname == 'filter') {
        results.push(c.instance.activeFunction(row));
      }
    });

    let result = results[results.length - 1];

    let counter = results.length - 2;

    for (let i = this.cmpRefs.length - 1; i >= 1; i--) {
      if (i % 2 == 1) {
        if (this.cmpRefs[i].instance.formControl.value == 'OR') {
          result = results[counter] || result;
        } else {
          result = results[counter] && result;
        }
        counter = counter - 1;
      }
    }

    return result;
  };

  set gridid(val: number) {
    this.#gridid = val;
    this.initfilter();
  }

  get gridid() {
    return this.#gridid;
  }

  removeNode(cmpRef: ComponentRef<any>) {
    const idx = this.container.indexOf(cmpRef.hostView);
    const freeIndex = cmpRef.instance.index;
    this.cmpRefs.splice(idx, 1);
    this.container.remove(idx);
    const removalName = cmpRef.instance.cname == 'filter' ? 'container' : 'item';
    this.formGroup.removeControl(removalName + freeIndex.toString());
    if (this.cmpRefs.length == 0) {
      this.applyFilter();
    }
  }

  triggerRemoveNode() {
    const event = new CustomEvent('remove', {
      bubbles: true,
      detail: {
        self: this,
      },
    });

    this.el.nativeElement.dispatchEvent(event);
  }

  addLevel(currentvalue: any, initialValue: any[], idx?: number, freeIdx?: number, cmpRef?: ComponentRef<any>) {
    if (freeIdx == undefined) {
      freeIdx = this.counter++;
    }

    const cmp = this.compfactory.resolveComponentFactory(IsexpfilterComponent);
    const cmpContainer = this.container.createComponent(cmp, idx);

    if (idx != undefined) {
      this.cmpRefs.splice(idx, 0, cmpContainer);
    } else {
      this.cmpRefs.push(cmpContainer);
    }
    //cmpContainer.instance.columnOptions = this.columnOptions;

    this.formGroup.addControl('container' + freeIdx.toString(), cmpContainer.instance.formControl);

    cmpContainer.changeDetectorRef.detectChanges();

    cmpContainer.instance.topLevel = false;
    cmpContainer.instance.gridid = this.gridid;
    cmpContainer.instance.index = freeIdx;

    cmpContainer.instance.initialValue = initialValue;

    cmpContainer.instance.afterformReady();

    if (cmpRef) {
      cmpContainer.instance.lastCmpRef.instance.datatype = cmpRef.instance.datatype;
      cmpContainer.instance.lastCmpRef.changeDetectorRef.detectChanges();
      cmpContainer.instance.lastCmpRef.instance.formGroup.setValue(currentvalue);
    }

    cmpContainer.location.nativeElement.addEventListener('remove', (ev) => {
      ev.stopPropagation();
      const idx = this.cmpRefs.findIndex((c) => cmpContainer == c);
      this.removeNode(cmpContainer);

      if (idx > 1 || (idx == 0 && this.cmpRefs.length > 1)) {
        const AndOrComp = idx > 1 ? this.cmpRefs[idx - 1] : this.cmpRefs[idx];

        const removeIdx = this.container.indexOf(AndOrComp.hostView);
        this.formGroup.removeControl('operator' + AndOrComp.instance.index.toString());

        const cmpIdx = this.cmpRefs.findIndex((c) => AndOrComp == c);
        this.cmpRefs.splice(cmpIdx, 1);

        this.container.remove(removeIdx);
        this.cd.detectChanges();
      }
    });
  }

  addNode(initialValue?, operaterValue?: string) {
    if (!this.initdone) this.initfilter();
    if (this.container.length > 0) {
      const cmp = this.compfactory.resolveComponentFactory(AndOrComponent);
      const cmpRef = this.container.createComponent(cmp);
      cmpRef.changeDetectorRef.detectChanges();
      this.counter++;
      this.formGroup.addControl('operator' + this.counter.toString(), cmpRef.instance.formControl);

      this.cmpRefs.push(cmpRef);

      if (operaterValue) {
        cmpRef.instance.formControl.setValue(operaterValue);
      }

      cmpRef.instance.index = this.counter;
    }

    const cmp = this.compfactory.resolveComponentFactory(IsexpcontainerComponent);
    const cmpRef = this.container.createComponent(cmp);

    cmpRef.instance.columnOptions = this.columnOptions;

    this.counter++;
    cmpRef.instance.index = this.counter;

    this.lastCmpRef = cmpRef;
    this.cmpRefs.push(cmpRef);

    cmpRef.changeDetectorRef.detectChanges();

    if (initialValue) {
      //this.lastCmpRef.instance.formGroup.get('kolom').setValue(initialValue.kolom);
      //this.lastCmpRef.instance.formGroup.get('expressie').setValue(initialValue.expressie);
      this.lastCmpRef.instance.formGroup.setValue(initialValue);
    }

    this.formGroup.addControl('item' + this.counter.toString(), cmpRef.instance.formControl);

    cmpRef.location.nativeElement.addEventListener('expand', () => {
      const freeIdx = cmpRef.instance.index;
      const currentvalue = cmpRef.instance.formGroup.value;
      const idx = this.cmpRefs.findIndex((c) => cmpRef == c);
      this.removeNode(cmpRef);
      this.addLevel(currentvalue, [{ kolom: null, expressie: null, value: null }], idx, freeIdx, cmpRef);
    });

    cmpRef.location.nativeElement.addEventListener('remove', (ev) => {
      ev.stopPropagation();
      const idx = this.cmpRefs.findIndex((c) => cmpRef == c);
      this.removeNode(cmpRef);

      if (idx > 1 || (idx == 0 && this.cmpRefs.length > 1)) {
        const AndOrComp = idx > 1 ? this.cmpRefs[idx - 1] : this.cmpRefs[idx];

        const removeIdx = this.container.indexOf(AndOrComp.hostView);
        this.formGroup.removeControl('operator' + AndOrComp.instance.index.toString());

        const cmpIdx = this.cmpRefs.findIndex((c) => AndOrComp == c);
        this.cmpRefs.splice(cmpIdx, 1);

        this.container.remove(removeIdx);
        this.cd.detectChanges();
      }
    });
  }

  getWhere(cmp: ComponentRef<any>) {
    if (cmp.instance.cname == 'filter') {
      let where = '';
      debugger
      cmp.instance.cmpRefs.map((c) => {
        where += this.getWhere(c);
      });
      return '(' + where + ')';
    } else {
      if (cmp.instance.cname == 'container') {
        debugger
        const column = cmp.instance.formGroup.get('kolom').value;
        const val = this.columnOptions.find((c) => c.label == column).value;

        return cmp.instance.generateString(
          'q.' + val,
          cmp.instance.formGroup.get('expressie').value,
          cmp.instance.formGroup.get('value').value
        );
      }
      return ' ' + cmp.instance.formControl.value + ' ';
    }
  }

  applyFilter() {
    if (this.mode == 'server') {
      let sql = this.compservice.widgets.get(this.gridid).instance.originalSql;

      let where = '';

      this.cmpRefs.map((c) => {
        where += this.getWhere(c);
      });

      //console.log('wherestatement', where);
      if (where != '') {
        sql = 'SELECT * FROM (' + sql + ') as q';
        sql = sql + ' where ' + where;
      }
      this.compservice.widgets.get(this.gridid).instance.sql = sql;
    }

    if (this.mode == 'local') {
      const dataloaded = this.compservice.widgets.get(this.gridid).instance.view.dataLoaded;
      const grid = this.compservice.widgets.get(this.gridid);

      if (dataloaded.value) {
        grid.instance.view.execExprFilter(this.activeFunction);
        grid.changeDetectorRef.detectChanges();
      } else {
        const sub = dataloaded.subscribe((v) => {
          if (v) {
            grid.instance.view.execExprFilter(this.activeFunction);
            grid.changeDetectorRef.detectChanges();
            sub.unsubscribe();
          }
        });
      }
    }
  }

  toggleView() {
    this.toggled = !this.toggled;
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    this.lblExecute = this.languages.translate(this.compservice.language,'execute');
  
    this.formGroup.valueChanges.subscribe((v) => {
      let statement = '';

      const sorted = Object.keys(this.formGroup.value).sort(
        (x, y) => Number(x.substr(x.length - 1)) - Number(y.substr(y.length - 1))
      );

      sorted.map((k) => {
        if (k.includes('container')) {
          statement += '(';
        }
        statement += this.formGroup.get(k).value;

        if (k.includes('container')) {
          statement += ')';
        }
        if (k != sorted[sorted.length - 1]) {
          statement += ' ';
        }
      });
      this.formControl.setValue(statement);

      console.log('formGroep', this.formGroup);

      this.cd.detectChanges();
    });
  }

  afterformReady() {
    this.initfilter();
  }
  
  initfilter(){
    if (!this.compservice.widgets.get(this.gridid)) { console.log(`Exp filter gridid ${this.gridid} is not valid`); return};
    this.columnOptions = this.gridid
      ? this.compservice.widgets.get(this.gridid)?.instance.fielddefs?.map((f) => {
          
          const col = this.usefielddefs?null:this.compservice.widgets.get(this.gridid).instance.columns.find((c) => c.name == f.name);
          

          let datatype = f.datatype;

          if (!['float', 'datetime', 'integer'].includes(f.datatype)) {
            datatype = 'string';
          }

          if (col) {
            const addfilter = col.filter ? col.filter : true;
            if (addfilter) {
              this.initdone = true;
              return {
                datatype: col.editcomp == 'isenum2' ? 'enum_' + datatype : f.datatype,
                value: f.name,
                label: f.label ? f.label : f.name,
                enum: col.enum ? col.enum : null,
              };
            }
          } else {
            const addfilter = f.filter ? f.filter : false;
            if (addfilter) {
              this.initdone = true;
              return {
                datatype: datatype,
                value: f.name,
                label: f.label ? f.label : f.name,
              };
            }
          }
          
        })
      : null;

    if (!this.columnOptions) {
      this.columnOptions = this.gridid
        ? this.compservice.widgets.get(this.gridid).instance.columns?.map((col) => {
            const addfilter = col.filter ? col.filter : true;
            if (addfilter) {
              let datatype = 'string';
              if (!['float', 'datetime', 'integer'].includes(col.datatype)) {
                datatype = 'string';
              }
              if (['isnumber'].includes(col.editcomp)) {
                datatype = 'integer';
              }
              this.initdone = true;
              return {
                datatype: col.editcomp == 'isenum2' ? 'enum_' + datatype : datatype,
                value: col.name,
                label: col.label ? col.label : col.name,
                enum: col.enum ? col.enum : null,
              };
            }
            
          })
        : [];
    }

    this.columnOptions = this.columnOptions?.filter((item) => {
       return item != undefined;
    });
    let operatorValue = '';

    this.initialValue.map((i) => {
      if (Array.isArray(i)) {
        this.addLevel(null, i);
      } else {
        if (typeof i == 'object') {
          this.addNode(i, operatorValue);
        } else {
          operatorValue = i;
        }
      }
    });
    if ((this.topLevel) &&  (this.mode == 'server')){
      this.applyFilter();      
      this.compservice.widgets.get(this.gridid).instance.active = true;
    }
  }
}

export class Container {
  containers = [];
}
