<!--plot area-->
<!-- <svg width="100%" height="100%" class="plot-area" istag="mainarea"> -->
<div style="width:100%;height:100%">
  <!-- <svg height="100%" width="100%">
    <text x="50%" y="50%">aaa</text>
  </svg>   -->
<svg class="x-as" istag="x-axis">
  <!-- <text text-anchor="start">test</text> -->
  <svg
    *ngFor="let k of keys; let i = index"
    [attr.height]="'100%'"
    [attr.x]="getGroupLefttext(i)"
    [attr.width]="getWidth()"
    y="0%"
  >
    <!-- <text height="100%" width="100%"></text> -->
    <!-- <text style="height:100%" istag="xitem" x="50%" y="50%" text-anchor="start" class="x-label">{{ k }}</text> -->
    <!-- <text x="50%" y="5" text-anchor="start" style="writing-mode: tb;glyph-orientation-vertical: 0;">{{k}}</text> -->
    <text istag='texthorz' [attr.x]="gettextxaxisleft(i)" [attr.y]="gettextxaxistop(i)" [attr.style]="gettextxaxisstyle(i)" text-anchor="start">{{k}}</text>
  </svg>
</svg>

<svg class="y-as" istag="y-axis">
  <text 
    *ngFor="let y of yAxisValues"
    istag="textvert"
    [attr.y]="getRelativeY(y)"
    text-anchor="end"
    x="100%"
    dx="-2px"
  >
    {{ y }}
  </text>
</svg>

<svg  class="plot-area" istag="platarea">
  <line
    x1="0%"
    *ngFor="let y of yAxisValues"
    [attr.y1]="getRelativeY(y)"
    [attr.y2]="getRelativeY(y)"
    x2="100%"
    class="grid-line"
    stroke-dasharray="4 4"
  ></line>

  <svg istag="seriesitem"
    *ngFor="let serie of series; let i = index"
    [attr.x]="getGroupLeft(i)"
    [attr.width]="getWidth()"
    height="100%"
  >
    <rect
      *ngFor="let bar of serie.bars; let j = index"
      (mouseover)="displayInfo($event, i, j)"
      (mouseleave)="closeInfo()"
      [attr.x]="serie.getBarLeft(j)"
      [style.fill]="defaultColors[j]"
      [attr.y]="bar.getTop()"
      [attr.height]="bar.getHeight()"
      [attr.width]="serie.getBarWidth()"
    >
  </rect>
  <text 
    *ngFor="let bar of serie.bars"
    x="50%"
    [attr.y]="bar.getTop()"
    dy="20"
    text-anchor="middle"
    fill="white"
  >
    {{bar.getFormatValue()}}
  </text>
  </svg>

  <line x1="0%" y1="0%" y2="100%" x2="0%" class="line"></line>
  <line
    x1="0%"
    [attr.y1]="BaseXAxis"
    [attr.y2]="BaseXAxis"
    x2="100%"
    class="line"
  ></line>
</svg>
</div>
<!-- </svg> -->

<ng-template #dialogContainer></ng-template>

<ng-template #Dialog let-data="data">
  <div class="dialog">
    {{ data }}
  </div>
</ng-template>
