import { IsGridService, IsGridRegistry } from './../isgrid.service';
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  Input,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'isbody',
  templateUrl: './isbody.component.html',
  styleUrls: ['./isbody.component.scss'],
})
export class IsbodyComponent implements AfterViewInit {
  @ViewChild('bodyContainer', { read: ViewContainerRef })
  public bodyContainer: ViewContainerRef;
  @ViewChild('stickyleftContainer', { read: ViewContainerRef })
  public stickyleftContainer: ViewContainerRef;
  @ViewChild('stickyrightContainer', { read: ViewContainerRef })
  public stickyrightContainer: ViewContainerRef;
  @ViewChild('fakeScroll', { read: ElementRef }) public fakeScroll: ElementRef;
  @ViewChild('fakeScrollHorz', { read: ElementRef })
  public fakeScrollHorz: ElementRef;

  @Input('id') id: number;
  @Input('vertscrollerbar')
  get vertscrollbar() {
    let virtual = this.isgrid.get(this.id)?.virtualscroll;

    return virtual;
  }
  set vertscrollbar(val) {}

  @Input('horzscrollerbar')
  get horzscrollbar() {
    return this.isgrid.get(this.id)?.virtualscroll;
  }

  constructor(private isgrid: IsGridRegistry, private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.setBodyinView();
  }

  setClass(rowclass) {
    this.el.nativeElement.classList.add(rowclass);
  }

  setBodyinView() {
    const view = this.isgrid.get(this.id);
    view.bodyContainer = this.bodyContainer;
    if (!view.pivot) {
      this.isgrid.get(this.id).fakeScroll = this.fakeScroll;
      this.isgrid.get(this.id).fakeScrollHorz = this.fakeScrollHorz;
    }

    if (!this.isgrid.get(this.id).virtualscroll) {
      this.el.nativeElement.style['overflow-y'] = 'scroll';
    }
  }
}
