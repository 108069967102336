import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';
import { values } from 'lodash';

@Component({
  selector: 'islabeldatadelimiter',
  templateUrl: './islabeldatadelimiter.component.html',
  styleUrls: ['./islabeldatadelimiter.component.scss'],
})
export class IslabeldatadelimiterComponent implements AfterViewInit {
  constructor(private comp: ComponentsService) {}

  private data: any = {};

  labelKey: string = '';
  valKey: string = '';

  get delimitedLabelList() {
    if (!this.data[this.labelKey]) {
      return [];
    }

    const val = this.data[this.labelKey]
      ? this.data[this.labelKey].split(';')
      : [];
    return val;
  }

  get delimitedValueList() {
    if (!this.data[this.valKey]) {
      return [];
    }

    const val = this.data[this.valKey]
      ? this.data[this.valKey].toString().split(',')
      : [];
    return val;
  }

  // set data(val) {
  //   this._data = val;
  // }

  // get data() {
  //   return this._data;
  // }

  ngAfterViewInit(): void {}
}
