import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentsService, Isform2Component, LoadComponent, Outlet2Service } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { AppError, LoadDataset, RunQuery } from '../isapplication/isapplication.action';
import { IsApplicationState } from '../isapplication/isapplication.state';
import { IscompiteratorService } from './iscompiterator.service';
import { CvcolorPipe } from '../color/color-helpers.service';

@Component({
  selector: 'iscompiterator',
  templateUrl: './iscompiterator.component.html',
  styleUrls: ['./iscompiterator.component.scss'],
})
export class IsCompiteratorComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private store: Store,
    private comp: ComponentsService,
    private outlet: Outlet2Service,
    private el: ElementRef,
    private appservice: IsApplicationState,
    private compiterator: IscompiteratorService,
    private cd: ChangeDetectorRef,
    private renderer:Renderer2,
    public colorpipe: CvcolorPipe
  ) {}

  @ViewChild('iscontainer', { read: ViewContainerRef }) public contentContainer: ViewContainerRef;

  name: string;
  #dsname = '';
  account: string;
  aliastemplate: string;
  @Input() datamode = 1;
  @Input() menuId: number;
  @Input() xxx: number;

  dataSetBeforeAfterView = false;
  formIsReady = false;
  isLoading = false;
  useLoading = true;
  transformtodict : string | null = null;

  activeComponents: Array<ComponentRef<any>> = [];
  ondataLoaded: any;
  onCmpRendered: any;
  sortedcolumns: string[] = [];
  appendMode = 'replace';
  storeoffline: boolean;
  private _selectedComponent: any | null = null;
  selectedColor:string = 'silver';

  @Input() bubbleCmpRendered:boolean = false;

  sortFn: Function | null = null;
  displaymaxitems: number | null;
  mapFn: (value, index) => Array<any> | null = null;
  

  set dsname(val) {
    // build an action to remove old dsname key in store if exists
    this.#dsname = val;

    this.setSubscriptions();
  }

  get dsname() {
    return this.#dsname;
  }

  #aliasname;

  set aliasname(val) {
    // build an action to remove old dsname key in store if exists
    this.#aliasname = val;

    this.setSubscriptions();
  }

  get aliasname() {
    return this.#aliasname;
  }

  #sql = '';

  set sql(val) {
    this.#sql = val;

    if (val) {
      try {
        this.loadData();
      } catch (error) {
        alert(error.message);
      }
    }
  }

  get sql() {
    return this.#sql;
  }

  set formGroupItems(val: Array<any>) {
    this.#formGroupItems = val;

    if (this.formIsReady) {
      this.formSubscriptions.forEach((f) => f.unsubscribe());
      this.formSubscriptions = [];

      this.setMasterFormGroup();
    }
  }

  get formGroupItems() {
    return this.#formGroupItems;
  }

  #formGroupItems: Array<any> = [];

  private _masterFormGroup = new FormGroup({});

  get masterFormGroup() {
    return this._masterFormGroup;
  }

  params: any = {};

  @Input() set activeData(val: any[]) {
    if (this._activeData?.length > 0) {
      this.clearComponents();
    }

    this._activeData = val;


    if (this._activeData?.length > 0) {
      this.renderComponents();
    } else {
      this.cd.detectChanges();
    }
  }

  get activeData(): any[] {
    return this._activeData;
  }

  @Input() set formid(val: number) {
    if (this._formid === val) return;

    this._formid = val;
    this.loadComponent();
  }

  @Input() set selectedComponent(component){
    if (this._selectedComponent){  
      this.renderer.setStyle(this._selectedComponent.el.nativeElement,'background-color','white');
    }
    this._selectedComponent = component;
    if (this._selectedComponent){
       this.renderer.setStyle(this._selectedComponent.el.nativeElement,'background-color',this.selectedColor);
    }
  }

  translate(lang){
    this.activeComponents.forEach((c) => {
      if (c.instance.translate) c.instance.translate(lang);
    })
  }

  get selectedComponent(){
    return this._selectedComponent;
  }

  get formid() {
    return this._formid;
  }

  private _formid: number;

  paramSubscription: Subscription | null;
  currentSubscription: Subscription | null;
  formSubscriptions: Array<Subscription> = [];

  private _activeData = [];

  id: number;

  fieldSorter = (fields) => (a, b) =>
    fields
      .map((o) => {
        let dir = 1;
        if (o[0] === '-') {
          dir = -1;
          o = o.substring(1);
        }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
      })
      .reduce((p, n) => (p ? p : n), 0);

  sortData(data) {
    return data.sort(this.fieldSorter(this.sortedcolumns));
  }

  selectComponent(component){
    if (this.selectedComponent){  
      this.renderer.setStyle(this.selectedComponent.el.nativeElement,'background-color','white');
    }
    this.selectedComponent = component;
    this.renderer.setStyle(this.el.nativeElement,'background-color',this.selectedColor);
  }

  moveComponentOnSortorder(compdata, key) {
    let oldidx;
    //try {
    oldidx = this._activeData.findIndex((item) => {
      return compdata[key] == item[key];
    });
    this._activeData[oldidx] = compdata;
    //} catch (error) {
    //  this.store.dispatch({ type: '[LoggingonServer]', payload: '' });
    //}
    //try {
    this._activeData = this.sortData(this._activeData);
    //} catch (error) {
    //  this.store.dispatch({ type: '[LoggingonServer]', payload: 'sortdata' });
    //}
    //try {
    const newidx = this._activeData.findIndex((item) => {
      return compdata[key] == item[key];
    });

    if (newidx != -1 && newidx != oldidx) {
      const compref = this.contentContainer.detach(oldidx);
      this.contentContainer.insert(compref, newidx);
    }
    //} catch (error) {
    //  this.store.dispatch({ type: '[LoggingonServer]', payload: '' });
    //}
  }

  setSubscriptions() {
    let info = this.store.select(IsApplicationState.dsset(this.aliasname ? this.aliasname : this.dsname));

    if (this.mapFn) {
      info = info?.pipe(map(this.mapFn));
    }

    this.currentSubscription?.unsubscribe();

    this.currentSubscription = info.subscribe((data) => {
      if (data) {
        this.clearComponents();
        if (this.ondataLoaded) {
          try {
            data = this.ondataLoaded(data);
          } catch (error) {
            this.store?.dispatch({
              type: '[AppError]',
              payload: {
                code: 1000,
                source: 'compiterator.ondataloaded : ' + this.name + this.id.toString(),
                message: error,
                level: 'error',
              },
            });
          }
        }
        this.isLoading = false;
        switch (this.appendMode) {
          case 'first':
            {
              const maxindex = data.length - this._activeData.length;
              for (let i = 0; i < maxindex; i++) {
                this.insertComponent(i, data[i]);
              }
            }
            break;
          case 'last':
            {
              const startindex = this._activeData.length - 1;
              for (let i = startindex; i < data.length; i++) {
                this.addComponent(data[i]);
              }
            }
            break;
          default: {
            this._activeData = this.sortFn ? data.sort(this.sortFn) : data;
            this.renderComponents();
          }
        }
      }
    });
  }

  setMasterFormGroup() {
    this.formGroupItems.map((f) => {
      const frmCtrl = this.comp.formcontrols.get(f.controlname);

      if (!frmCtrl) throw Error(`${f.controlname} is Onbekend!`);

      const dummyControl = new FormControl(frmCtrl.formcontrol.value);

      const s = frmCtrl.formcontrol.valueChanges.subscribe((v) => {
        dummyControl.setValue(v);
      });

      dummyControl.setValidators(Validators.required);

      this.formSubscriptions.push(s);

      this._masterFormGroup.addControl(f.key, dummyControl);
    });
  }

  loadData(ondataLoaded?:any) {
    if (this.datamode == 2) {
      const allParamsHavevalue = Object.values(this.params).every((x) => x !== null && x !== '');
      if (allParamsHavevalue) {
        if (this.aliastemplate) {
          this.aliasname = this.outlet.parsetemplate(this.aliastemplate, this.dsname, this.params);
        }
        this.store.dispatch(
          new LoadDataset({
            dsname: this.dsname,
            alias: this.aliasname,
            account: this.account,
            params: this.params,
            definition: 0,
            // fixparams: this.fixparams ? this.fixparams : this.defaultfixparams,
            fixparams: false,
            storeoffline: this.storeoffline,
            ondataLoaded:ondataLoaded,
            transformtodict: this.transformtodict??null
          })
        );
      }
    } else if (this.sql) {
      this.isLoading = true;
      // this.clearComponents();

      if (!this.dsname) {
        this.store.dispatch(
          new AppError({
            source: 'icompiterator ' + this.name + '-' + this.id.toString(),
            code: 1,
            level: 'warning',
            message: 'Can not perform loaddata when dsname is null',
          })
        );
      }

      if (!this.appservice.checkParams(this.params, this.#sql, this.name, this.id)) {
        this.clearComponents();
        return;
      }

      // const regExp = new RegExp(`[:?]([a-zA-Z])+`, 'gmi');
      // const sqlparams = this.#sql.match(regExp).filter((v, i, a) => a.indexOf(v) === i);
      // if (Object.entries(this.params).length < sqlparams.length) {
      //   this.store.dispatch(
      //     new AppError({
      //       source: 'iscompiterator ' + this.name + '-' + this.id.toString(),
      //       code: 1,
      //       level: 'warning',
      //       message: `no parameters expected ${sqlparams.length} parameters found ${this.#sql}`,
      //     })
      //   );
      //   return;
      // }

      // sqlparams.forEach((param) => {
      //   if (!this.params[param.slice(1)]) {
      //     this.store.dispatch(
      //       new AppError({
      //         source: 'iscompiterator ' + this.name + '-' + this.id.toString(),
      //         code: 1,
      //         level: 'warning',
      //         message: 'parameter missing ' + param.substring(1),
      //       })
      //     );
      //     return;
      //   }
      // });

      this.store.dispatch(
        new RunQuery({
          dsname: this.dsname,
          sql: this.sql,
          account: this.account,
          params: this.params,
          definition: 0,
          // fixparams: this.fixparams ? this.fixparams : this.defaultfixparams,
          fixparams: false,
          reducer: this['initreducer'] ?? null,
          transformtodict : this.transformtodict??null
        })
      );
    }
  }

  reached = null;

  loadComponent() {
    if (!this.formid) {
      return;
    }

    this.menuId;
    // if (this.comp.widgets.get(this.formid)) {
    //   this.renderComponents();
    //   return;
    // }

    if (!this.compiterator.componentsLoading.includes(this.formid)) {
      this.store.dispatch(new LoadComponent({ id: this.formid, name: '' }));
      this.compiterator.componentsLoading.push(this.formid);
    }

    const cmpLoad = this.outlet.getComponentById$(this.formid).pipe(
      filter((component) => Boolean(component)),
      take(1)
    );

    let sub;
    let subTriggered = false;

    sub = cmpLoad.subscribe((s) => {
      this.renderComponents();

      if (sub) {
        sub?.unsubscribe();
      } else {
        subTriggered = true;
      }
    });

    if (subTriggered) {
      sub.unsubscribe();
    }
    
  }

  clearComponents() {
    this.activeComponents = [];
    this.contentContainer?.clear();
    this.cd.detectChanges();
  }

  renderComponentsFromData: boolean = false;

  async renderComponents() {
    if (!this.contentContainer) {
      this.dataSetBeforeAfterView = true;
      return;
    }
    // this.clearComponents();

    // check availlability
    if (this.renderComponentsFromData) {
      const unique_compids = [...new Set(this._activeData.map((c) => c.compid))].filter(
        (id) => !this.comp.widgets.get(id + 1)
      );

      let resolved = 0;

      unique_compids.forEach((id) => {
        if (!this.compiterator.componentsLoading.includes(id)) {
          this.store.dispatch(new LoadComponent({ id, name: '' }));
          this.compiterator.componentsLoading.push(id);
        }

        const cmpLoad = this.outlet.getComponentById$(id).pipe(
          filter((component) => Boolean(component)),
          take(1)
        );

        let sub;
        let subTriggered = false;

        sub = cmpLoad.subscribe((s) => {
          resolved++;
          if (resolved == unique_compids.length) this.renderComponents();

          if (sub) {
            sub?.unsubscribe();
          } else {
            subTriggered = true;
          }
        });

        if (subTriggered) {
          sub.unsubscribe();
        }
      });

      if (unique_compids.length > 0) return;
    }

    if (this._activeData?.length) {
      for (const d of this._activeData) {
        let cmpRef;

        if (this.renderComponentsFromData) {
          cmpRef = await (await this.outlet.copyComponent(d.compid, null, 0, null, d)).renderedtree;
        } else {
          cmpRef = await (await this.outlet.copyComponent(this.formid, null, 0, null, d)).renderedtree;
        }
        if (cmpRef) {
          cmpRef.instance.isparent = this;
          cmpRef.location.nativeElement.setAttribute('index', this.contentContainer.length);
          this.contentContainer.insert(cmpRef.hostView);
          this.activeComponents.push(cmpRef);
        }

        // if (d === this._activeData[0]) {
        //    const width = cmpRef.location.nativeElement.getBoundingClientRect().width;
        //   this.el.nativeElement.style.setProperty('--columnwidth', `${width}px`);
        // }

        this.comp.resetEvents(this.id);
      }

      this.comp.resetEvents(this.id);
      if (this.onCmpRendered && this.activeComponents.length > 0) {
        this.onCmpRendered(this.activeComponents);
      }

      if (this.activeComponents.length > 0) {
        const event = new CustomEvent('onCmpRendered', { detail: this.activeComponents, bubbles: this.bubbleCmpRendered });
        this.el.nativeElement.dispatchEvent(event);
      }

      // this.activeComponents.forEach((c) => console.log(c));
      this.cd.detectChanges();

    }
  }

  async addComponent(d, ref?) {
    console.log('add');

    this._activeData = [...this._activeData, d];

    let cmpRef;

    if (this.renderComponentsFromData) {
      cmpRef = await (await this.outlet.copyComponent(d.compid, null, 0, null, d)).renderedtree;
    } else {
      cmpRef = await (await this.outlet.copyComponent(this.formid, null, 0, null, d)).renderedtree;
    }
    cmpRef.instance.isparent = this;
    this.contentContainer.insert(cmpRef.hostView);

    this.activeComponents.push(cmpRef);

    // if (d === this._activeData[0]) {
    //   const width = cmpRef.location.nativeElement.getBoundingClientRect().width;
    //   this.el.nativeElement.style.setProperty('--columnwidth', `${width}px`);
    // }

    this.comp.resetEvents(this.id);
  }

  async insertComponent(index, d, ref?) {
    console.log('add');

    this._activeData = [...this._activeData, d];

    let cmpRef;

    if (this.renderComponentsFromData) {
      cmpRef = await (await this.outlet.copyComponent(d.compid, null, 0, null, d)).renderedtree;
    } else {
      cmpRef = await (await this.outlet.copyComponent(this.formid, null, 0, null, d)).renderedtree;
    }
    cmpRef.instance.isparent = this;
    this.contentContainer.insert(cmpRef.hostView, index);

    this.activeComponents.splice(index, 0, cmpRef);

    // if (d === this._activeData[0]) {
    //   const width = cmpRef.location.nativeElement.getBoundingClientRect().width;
    //   this.el.nativeElement.style.setProperty('--columnwidth', `${width}px`);
    // }

    this.comp.resetEvents(this.id);
  }

  removeComponent(index) {
    this._activeData.splice(index, 1);
    this.contentContainer.detach(index);

    return this.activeComponents.splice(index, 1);
  }

  afterformReady() {
    this.formIsReady = true;

    // trigger
    try {
      this.setMasterFormGroup();
    } catch (error) {
      this.store?.dispatch({
        type: '[AppError]',
        payload: {
          code: 1000,
          source: 'compiterator.setMasterFormGroup : ' + this.name + this.id.toString(),
          message: error,
          level: 'error',
        },
      });
    }
  }

  ngOnInit(): void {
    this.paramSubscription = this._masterFormGroup.valueChanges.pipe(debounceTime(50)).subscribe((v) => {
      this.params = v;

      if (this._masterFormGroup.valid) {
        this.loadData();
      }
    });
    if (this.#formGroupItems.length == 0) {
      //this.loadData();
    }
  }

  ngAfterViewInit(): void {
    if (this.dataSetBeforeAfterView) {
      if (!this.formid) {
        return;
      }
      this.renderComponents();
    }
  }

  ngOnDestroy() {
    this.paramSubscription?.unsubscribe();
    this.currentSubscription?.unsubscribe();
    this.formSubscriptions.forEach((f) => f.unsubscribe());
  }
}
