import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { InitApplication, InitPortal } from '@ic-builder/iscomp';
import { SessionService } from '@ic-builder/auth';
import { SubSink } from 'subsink';

@Component({
  selector: 'ic-builder-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, OnDestroy {
  #subs = new SubSink();

  constructor(private store: Store, private sessionService: SessionService) {}

  ngOnInit(): void {
    this.#subs.sink = this.sessionService.currentUser$.subscribe(
      (currentUser) => {
        console.log(
          `%cClass: FormComponent, Function: this.sessionService.currentUser$(currentUser): `,
          'color: green;',
          currentUser
        );
        // if (currentUser) {
        //   this.store.dispatch(
        //     new InitApplication({
        //       id: currentUser.applicationid,
        //       name: 'login',
        //       account: currentUser.account.toString(),
        //       outlet: 0,
        //     })
        //   );
        // } else {
        this.store.dispatch(
          new InitPortal({ applicationname: 'IntersoftOne', applicationtype:1 })
        );
        //}
      }
    );
  }

  ngOnDestroy() {
    this.#subs.unsubscribe();
  }
}
