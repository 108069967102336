import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'isradio',
  templateUrl: './isradio.component.html',
  styleUrls: ['./isradio.component.scss'],
})
export class IsradioComponent extends IscontrolComponent implements OnInit {
  @Input() label: string;
  @Input() initialValue = false;
  @Input() value: string;
  @Input() groupname: string;
  @Input() groupvalue: boolean;

  controlid: number;
  formControl: FormControl;

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput) {
      this.controlid = super.initControl(
        this.id.toString() + '.radio',
        this.initialValue,
        'radio'
      );
      this.formControl = super.getFormControl(this.controlid);
    } else {
      this.formControl = new FormControl(this.initialValue);
    }
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControl.value);
        });
      }
    }
  }

  controlnameValueChanges(newVal) {
    this.compserv.formcontrols.get(this.controlid.toString()).name = newVal;
  }

  ngOnDestroy() {
    // super.destroyControls([this.controlname])
  }
}
