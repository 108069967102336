
import { Outlet2Service } from '../outlet2.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { LoadComponent } from '../base.actions';

@Component({
  selector: 'outlet',
  templateUrl: './outlet2.component.html',
  styleUrls: ['./outlet2.component.scss'],
})
export class Outlet2Component implements OnInit, AfterViewInit, OnDestroy {
  firstLoad = -1;
  currentlyLoaded: ComponentRef<any> | null = null;
  outletservice: Observable<ComponentRef<any>> | null = null;
  subscription: Subscription | null = null;
  loadedassub: Subscription | null = null;

  @Input() id: number;
  @Input() formid: number;
  @Input() class: string;
  @Input() height: string;

  @Input() data: any;
  @ViewChild('outlet', { read: ViewContainerRef }) outlet: ViewContainerRef;
  @Input() loadas: number;
  @Input() kind: string;
  outletloadedcomponent: Observable<any>;
  @Output('outletloadedform') outletloadedform: EventEmitter<any> = new EventEmitter();

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private el: ElementRef
  ) {}

  ngOnInit() {
    console.log('outlet afterformReady : ',this);

    if (typeof this.id === 'string') {
      this.id = parseInt(this.id);
    }
    if (this.id == null) {
      this.id = 0;
    }
    if (this.height == null) {
      this.height = '100vh';
    } else if (this.height === 'noheight') {
      this.el.nativeElement.removeAttribute('style');
    }

    //console.log('init outlet2component',this.id,'height : ',this.height);
    this.outletservice = Outlet2Service.outletx(this.id);
    this.subscription = this.outletservice.subscribe((widget) => {
      if (widget != this.currentlyLoaded) {
        if (widget != null && this.outlet != null) {
          if (this.outlet.length > 0) {
            this.outlet.detach(0);
            this.currentlyLoaded?.instance.afterDetachDom ? this.currentlyLoaded.instance.afterDetachDom() : null;
          }
          this.outlet.insert(widget.hostView);
          widget.instance.isparent = this;
          
          this.currentlyLoaded = widget;
          const event = new CustomEvent('beforereloadinoutlet', {
            bubbles: true,
            cancelable: true,
            detail: { outlet: this.id, component: widget.instance },
          });
          this.el.nativeElement.dispatchEvent(event);
          this.cd.detectChanges();

          // Notify to all Components they have been inserted:
          const insertDom = (widget: ComponentRef<any>) => {
            widget.instance.afterInsertInDom ? widget.instance.afterInsertInDom() : null;
            widget['ischildren']?.forEach((item: any) => {
              insertDom(item);
            });
          };

          insertDom(widget);
        }
        // if (!widget) {
        //   if (this.outlet.length > 0) {
        //     this.outlet.remove(0);
        //   }
        // }
      }
    });

    if (this.firstLoad) {
      this.store.dispatch(new LoadComponent({ 
        id: this.firstLoad,
        name: '',
        outlet: this.id,
        data: this.data ? this.data : {},
        // loadas: this.loadas,
      }));
    }
  }

  ngAfterViewInit(): void {
    // remove this when application is converted
    if (this.formid != null) {
      const load = new LoadComponent({
        id: this.formid,
        name: 'outlet2component',
        outlet: this.id,
        data: this.data,
        loadas: this.loadas 
      });
      //console.log('outlet afterviewinit',{id:this.formid,name:'outlet2component',outlet:this.id});
      if (this.kind) {
        load.payload['kind'] = this.kind;
      }
      if (this.loadas) {
        load.payload['loadas'] = this.loadas;
        load.payload['renderloadasimmediate'] = true;
        this.outletloadedcomponent = this.store.dispatch(load);
        this.loadedassub = this.outletloadedcomponent.subscribe((a) => {
          if (this.outletloadedform) {
            this.outletloadedform.emit(a);
          }
        });
      } else {
        this.store.dispatch(load);
      }
    }

    this.route.paramMap.pipe(
      tap((params: ParamMap) => {
        const form = parseInt(params.get('form'));
        const outlet = parseInt(params.get('outlet'));
        this.id = outlet;
        //console.log('routing params : ',form,' ',outlet);
        this.store.dispatch(
          new LoadComponent({
            id: form,
            name: 'outlet2component',
            outlet: this.id,
          })
        );
      })
    );
  }

  clearOutlet() {
    this.outlet.detach(0);
  }

  ngOnDestroy() {
    if (this.loadedassub) {
      this.loadedassub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  firstLoadValueChanges(newVal) {
    this.store.dispatch(new LoadComponent({ id: newVal, name: '', outlet: this.id }));
  }
}
