import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-builder-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss'],
})
export class LineComponent implements OnInit {
  constructor(private cd: ChangeDetectorRef) {}

  private _minY = 0;
  private _maxY = 0;

  private _maxX = 0;

  baseX(): number {
    return this._maxY / (this._maxY - this._minY);
  }

  getLeft(i: number) {
    return (100 * (i / (this._maxX - 1))).toString() + '%';
  }

  getTop(val: number) {
    // if (val < 0) {
    //   return (100*this.baseX()).toString() + '%';
    // }
    return (
      ((100 * this.baseX() * (this._maxY - val)) / this._maxY).toString() + '%'
    );
  }

  get BaseXAxis(): string {
    if (this._minY == 0) {
      return '100%';
    }
    return (100 * this.baseX()).toString() + '%';
  }

  set data(d: any) {
    let temp: any = {};

    this._maxY = 0;
    this._minY = 0;

    Object.values(d).map((d: any) =>
      Object.entries(d).forEach(([key, value]) => {
        if (!Object.keys(temp).includes(key)) {
          temp[key] = [];
        }
        temp[key].push(value);

        if ((value as number) > this._maxY) {
          this._maxY = value as number;
        }

        if ((value as number) < this._minY) {
          this._minY = value as number;
        }
      })
    );

    this._data = Object.entries(temp).map(
      ([key, val]) => new Series(key, val as number[])
    );

    this.cd.detectChanges();

    // for(let i=0;i<this._data.length-1;i++){
    //   this._lines.push(
    //     {
    //       y1:this._data[i],
    //       y2:this._data[i+1]
    //     }
    //   )
    // }
  }

  private _data: any[] = [];
  public _lines: any[] = [];

  get data(): any[] {
    return this._data;
  }

  ngOnInit(): void {}
}

class Series {
  lines: Line[] = [];

  constructor(private key: string, private values: number[]) {
    for (let i = 0; i < this.values.length - 1; i++) {
      this.lines.push({
        y1: this.values[i],
        y2: this.values[i + 1],
      });
    }
  }

  getLeft(i: number) {
    return (100 * (i / (this.values.length - 1))).toString() + '%';
  }
}

class Line {
  y1: number = 0;
  y2: number = 0;
}
