import { AddComp, SetEvent } from './isevent.action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ComponentRef, Injectable } from '@angular/core';

export interface IsEventStateModel {
  actions: any;
  test: Array<ComponentRef<any>>;
}

@State<IsEventStateModel>({
  name: 'iseventstate',
  defaults: {
    actions: {},
    test: [],
  },
})
@Injectable()
export class IsEventState {
  constructor() {}

  @Selector()
  public static getAction(state) {
    return (type) => {
      //state.actions[type]='something else'
      return state.actions[type];
    };
  }

  @Selector()
  public static setAction(state) {
    return (type) => {
      state.actions[type] = 'something else';
      return state.actions[type];
      //return state.actions[type];
    };
  }

  public static adjust(state) {
    debugger;
    state.test[0].instance.style.color = 'blue';
  }

  @Action(SetEvent)
  setEvent(
    { patchState, getState }: StateContext<IsEventStateModel>,
    { payload }: SetEvent
  ) {
    const state = getState();

    const actions = state.actions;

    const { active, info } = payload;

    patchState({
      actions: {
        ...actions,
        [payload.name]: {
          active,
          info,
        },
      },
    });
  }

  @Action(AddComp)
  addComp(
    { patchState, getState }: StateContext<IsEventStateModel>,
    { payload }: AddComp
  ) {
    const state = getState();

    const temp = state.test;
    temp.push(payload);
    temp[0].changeDetectorRef.detectChanges();
    temp[0].location.nativeElement.style['background-color'] = 'blue';
    temp[0].instance.title = 'Changed by state';

    console.log(temp);

    patchState({
      test: temp,
    });
  }
}
