import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { HighlightState } from './highlight.state';
import { HighlightOnFocusDirective } from './highlight-on-focus.directive';
import { AddClassOnHighlightDirective } from './add-class-on-highlight.directive';
import { CvcolorPipe } from '../color/color-helpers.service';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([HighlightState])],
  declarations: [
    HighlightOnFocusDirective,
    AddClassOnHighlightDirective,
    CvcolorPipe,
  ],
  exports: [
    HighlightOnFocusDirective,
    AddClassOnHighlightDirective,
    CvcolorPipe,
  ],
  providers: [CvcolorPipe],
})
export class HighlightModule {}
