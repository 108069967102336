export function getTreeChildPath<
  K extends keyof T,
  T extends Record<K, T[K]>,
  C extends keyof T = keyof T,
  I extends keyof T = keyof T
>(
  haystackTree: T,
  config: { idProperty: I; childrenProperty: C },
  needleId: T[I]
): Array<C | number> | false {
  const workArray = [haystackTree];
  const pathArray: Array<Array<C | number>> = [[]];
  let item;
  while ((item = workArray.shift()) != null) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentPath = pathArray.shift()!;
    if (item[config.idProperty] === needleId) {
      return currentPath;
    }
    if (
      Object.prototype.hasOwnProperty.call(item, config.childrenProperty) &&
      Array.isArray(item[config.childrenProperty])
    ) {
      const childrenArray: Array<T> = item[config.childrenProperty];
      childrenArray.forEach((child, idx) => {
        workArray.push(child);
        pathArray.push([...currentPath, config.childrenProperty, idx]);
      });
    }
  }
  return false;
}

const c = getTreeChildPath(
  {
    id: 1,
    children: [
      { id: 2, children: [{ id: 5 }] },
      { id: 3, children: [{ id: 4 }] },
    ],
  },
  { idProperty: 'id', childrenProperty: 'children' },
  5
);

c; //?
