import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { FormControl } from '@angular/forms';
import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'isdatefull',
  templateUrl: './datefull.component.html',
  styleUrls: ['./datefull.component.scss'],
})
export class DatefullComponent
  extends IscontrolComponent
  implements OnInit, AfterViewInit
{
  @Input() label: string;
  @Input() fromlabel = '';
  @Input() untillabel = '';
  @Input() datefrom: number;
  @Input() dateuntil: number;

  @Input() startVanaf: any;
  @Input() endVanaf: any;
  @Input() startTot: any;
  @Input() endTot: any;

  controlidFrom: number;
  controlidUntil: number;
  formControlfrom: FormControl;
  formControluntil: FormControl;

  controlidFromfmt: number;
  controlidUntilfmt: number;
  formControlfromfmt: FormControl;
  formControluntilfmt: FormControl;
  today = false;

  constructor(
    public comp: ComponentsService,
    public outlet: Outlet2Service,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    let ep = null;
    let fr = null;
    let un = null;
    if (this.datefrom) {
      ep = this.EPochToDateObj(this.datefrom);
      fr =
        ep.year.toString() +
        '-0' +
        ep.month.toString() +
        '-0' +
        ep.day.toString();
    }
    if (this.dateuntil) {
      ep = this.EPochToDateObj(this.dateuntil);
      un =
        ep.year.toString() +
        '-' +
        ep.month.toString() +
        '-' +
        ep.day.toString();
    }
    this.controlidFrom = super.initControl(
      this.id.toString() + '.from',
      '2020-01-01',
      'from'
    );
    this.controlidUntil = super.initControl(
      this.id.toString() + '.until',
      '2022-12-31',
      'until'
    );

    this.formControlfrom = super.getFormControl(this.controlidFrom);
    this.formControluntil = super.getFormControl(this.controlidUntil);

    this.controlidFromfmt = super.initControl(
      this.id.toString() + '.fromfmt',
      '2022-01-01',
      'fromfmt'
    );
    this.formControlfromfmt = super.getFormControl(this.controlidFromfmt);
    this.controlidUntilfmt = super.initControl(
      this.id.toString() + '.untilfmt',
      '2022-12-31',
      'untilfmt'
    );
    this.formControluntilfmt = super.getFormControl(this.controlidUntilfmt);
  }

  DateToString(date: Date): string {
    if (typeof date === 'string') {
      return;
    }
    if (date) {
      const dtval =
        (date.getMonth() + 1).toString() +
        '/' +
        date.getDate().toString() +
        '/' +
        date.getFullYear();
      return dtval;
    }
  }

  ngAfterViewInit() {
    this.formControlfrom.valueChanges
      .pipe(map((d) => this.DateToString(d)))
      .subscribe((c) => {
        this.formControlfromfmt.setValue(c);
      });
    this.formControluntil.valueChanges
      .pipe(map((d) => this.DateToString(d)))
      .subscribe((c) => {
        this.formControluntilfmt.setValue(c);
      });
  }

  asEPoch(date) {
    return (
      Math.floor(
        (new Date(date).getTime() - new Date('12-30-1899').getTime()) /
          (1000 * 60 * 60 * 24)
      ) + 1
    );
  }

  EPochToDate(epochdate) {
    const ret = new Date(0);
    return ret.setDate(ret.getDate() + (epochdate - 25569));
  }

  EPochToDateObj(epochdate) {
    const d = new Date(0);
    d.setDate(d.getDate() + (epochdate - 25569));
    return { day: d.getDay(), month: d.getMonth() + 1, year: d.getFullYear() };
  }

  afterformReady() {
    if (this.formControlfrom) {
      if (this['valueChanges']) {
        this.formControlfrom.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControlfrom.value);
        });
      }

      if (this.formControlfromfmt.value != null) {
        this.formControlfrom.setValue(this.formControlfromfmt.value);
      } else if (this.today) {
        const dt = new Date();
        const dtval =
          (dt.getMonth() + 1).toString() +
          '/' +
          dt.getDate().toString() +
          '/' +
          dt.getFullYear();
        this.formControlfrom.setValue(dtval);
      }
    }

    if (this.formControluntil) {
      if (this['valueChanges']) {
        this.formControluntil.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControluntil.value);
        });
      }

      if (this.formControluntilfmt.value != null) {
        this.formControluntil.setValue(this.formControluntilfmt.value);
      } else if (this.today) {
        const dt = new Date();
        const dtval =
          (dt.getMonth() + 1).toString() +
          '/' +
          dt.getDate().toString() +
          '/' +
          dt.getFullYear();
        this.formControluntil.setValue(dtval);
      }
    }
  }

  controlnameValueChanges(newVal) {
    this.comp.formcontrols.get(this.controlidFrom.toString()).name =
      newVal + '.from';
    this.comp.formcontrols.get(this.controlidUntil.toString()).name =
      newVal + '.until';
  }
}
