<!-- <form [formGroup]="frmGrp" class="row" [style.visibility]="(visFunc(wizGrp))? 'visible':'hidden'">    -->
<div *ngIf="label" istag="label" class="content label" id="labeltxt">
  {{ label }}
</div>

<input
  istag="input"
  type="date"
  [min]="start"
  [max]="end"
  class="content input"
  [formControl]="formControl"
  useValueAsDate
  [format]="format"  
/>
<button istag="button" class="button" (click)="reset()">x</button>
<mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
<mat-icon
  istag="warning"
  *ngIf="warning"
  style="color: orange"
  (mouseenter)="openwarning($event)"
  (mouseleave)="closewarning()"
  >warning</mat-icon
>
<!-- </form> -->

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
