import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ic-builder-istranslabel',
  templateUrl: './istranslabel.component.html',
  styleUrls: ['./istranslabel.component.css'],
})
export class IstranslabelComponent {

  constructor(private comp: ComponentsService,
    private store: Store,
    private cd: ChangeDetectorRef, private el: ElementRef) {}

  fn?: (...d) => void;

  valSub?: Subscription;

  _params = null;

  get params() {
    return this._params;
  }

  set params(val: any[]) {
    if (this.fn) {
      this.el.nativeElement.innerHTML = this.fn(...val);
    }

    this._params = val;
  }

  set identifier(val: string) {

    this.valSub?.unsubscribe()
    this._identifier = val;

    if (!val) return;

    this.valSub = this.store.select((state) => state.isapplicationstate.dssets.currentLanguage[val])
      .subscribe((v) => {
        if (v) {
          this.el.nativeElement.classList.remove('loading');

          let r;

          if (typeof v === 'function') {
            this.fn = v;
            r = this.params ? v(...this.params): '';
          } else {
            r = v;
          } 

          this.el.nativeElement.innerHTML = r;
          this.cd.detectChanges();
        } else {
          this.el.nativeElement.classList.add('loading');
        }
      });

    
  }

  get identifier() {
    return this._identifier;
  }

  private _identifier = null;
}
