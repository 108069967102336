import { Store } from '@ngxs/store';
import { SetEvent } from './isevent.action';

export class EventTrig {
  action: string;
  elements: any;

  constructor(
    private name: string,
    private el: NodeList,
    private store: Store
  ) {
    this.elements = this.convertNodeList(this.el);
    this.action = this.name;
  }

  convertNodeList(nodeList) {
    const elementList: Array<HTMLElement> = [];

    if (nodeList) {
      for (let i = 0; i < nodeList.length; i++) {
        const node: Node = nodeList[i];

        // Make sure it's really an Element
        if (node.nodeType == Node.ELEMENT_NODE) {
          elementList.push(node as HTMLElement);
        }
      }
    }
    return elementList;
  }

  add(el) {
    const temp = this.convertNodeList(el);

    const difference: any = temp.filter((x) => !this.elements.includes(x));

    for (const dif of difference) {
      dif.addEventListener(
        'dragstart',
        {
          store: this.store,
          name: this.name,
          elem: dif,
          //state:this.state,
          handleEvent: function () {
            const info = JSON.parse(this.elem.dataset.info);
            this.store.dispatch(
              new SetEvent({ name: this.name, active: true, info: info })
            );
          },
        },
        false
      );

      dif.addEventListener('dragend', {
        store: this.store,
        name: this.name,
        elem: dif,
        handleEvent: function () {
          const info = JSON.parse(this.elem.dataset.info);
          this.store.dispatch(
            new SetEvent({ name: this.name, active: false, info: info })
          );
        },
      });

      this.elements.push(dif);
    }
  }
}
