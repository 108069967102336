import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'customfrequention',
  templateUrl: './customfrequention.component.html',
  styleUrls: ['./customfrequention.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomfrequentionComponent),
      multi: true,
    },
  ],
})

export class CustomfrequentionComponent implements OnInit, ControlValueAccessor {


  get value(): string {
    return this._value;
  }

  private _value: string = "";

  @Input('mode') mode: "days" | "weeks" = "days";

  options: any = {
    days: ["Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"],
    weeks: Array.from({ length: 52}).map((_, v) => v + 1),
    months: ["Jan", "Feb", "Mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Okt", "Sep", "Nov", "Dec"]
  }

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  toggleValue(idx: number) {
    this.writeValue(
      this.value.substring(0, idx) +
      (this.value.charAt(idx) === '1' ? '0' : '1') +
      this.value.substring(idx + 1)
    );
  }

  _onChange: (_: any) => void = (_) => {};

  writeValue(value: string): void {
    this._onChange(value);
    this._value = value;

    this.cd.detectChanges();
  }

  registerOnChange(fn: (_: any) => void): void {
      this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
      //
  }
}
