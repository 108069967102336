<div class="row">
  <div class="list" (dragover)="$event.preventDefault()">
    <div class="content" id="labeltxt">
      <p style="margin: 0">custom filter</p>
      <mat-icon class="icon" (click)="addLeaf()">add</mat-icon>
      <!-- <mat-icon class="icon" (click)="add()">add_box</mat-icon> -->
    </div>
  </div>
  <div style="width: 100%" *ngIf="form.get('expression')">
    <div *ngIf="form.get('expression').value">
      <form [formGroup]="form">
        <div *ngIf="isLeaf(form.get('expression').value)">
          <isleafexpression
            (splitevent)="splitExp()"
            (remove)="remove()"
            [fieldOptions]="fieldOptions"
            formControlName="expression"
          ></isleafexpression>
        </div>

        <div *ngIf="!isLeaf(form.get('expression').value)">
          <isexpression
            (splitevent)="splitExp()"
            (remove)="remove()"
            [fieldOptions]="fieldOptions"
            formControlName="expression"
          ></isexpression>
        </div>
      </form>
    </div>
  </div>
</div>
