import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'isslider',
  templateUrl: './isslider.component.html',
  styleUrls: ['./isslider.component.scss'],
})
export class IssliderComponent
  extends IscontrolComponent
  implements OnInit, OnDestroy
{
  @Input() label: string;
  @Input() initialValue: string;
  @Input() min: number;
  @Input() max: number;

  controlid: number;
  formControl: FormControl;

  private subscriptions: Array<Subscription> = [];

  step: number;
  value;

  constructor(
    comp: ComponentsService,
    public store: Store,
    public outlet: Outlet2Service,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    this.controlid = super.initControl(this.controlid, this.initialValue);
    this.formControl = super.getFormControl(this.controlid);

    if (this.formControl) {
      this.subscriptions.push(
        this.formControl.valueChanges.subscribe((a) => {
          if (a) {
            this.value = a;
          } else {
            this.value = this.max;
          }
          this.cd.detectChanges();
        })
      );
    }
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControl.value);
        });
      }
    }
  }

  maxValueChanges(val) {
    this.max = val;
    this.step = (this.max + this.min) / 100;
    this.cd.detectChanges();
  }

  minValueChanges(val) {
    this.min = val;
    this.step = (this.max + this.min) / 100;
    this.cd.detectChanges();
  }

  controlnameValueChanges(val) {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions.push(
      this.formControl.valueChanges.subscribe((a) => {
        if (a) {
          this.value = a;
        } else {
          this.value = this.max;
        }
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    // super.destroyControls([this.controlname])
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
