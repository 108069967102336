import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';

@Component({
  selector: 'istoolbar',
  templateUrl: './istoolbar.component.html',
  styleUrls: ['./istoolbar.component.scss'],
})
export class IstoolbarComponent implements OnInit, AfterViewInit {
  //@ViewChild('children', { read: ViewContainerRef }) public children:ViewContainerRef;
  @ViewChildren('children', { read: ViewContainerRef })
  public children: QueryList<ViewContainerRef>;

  config: any;
  sizes: Array<any> = [];
  id: number;

  constructor(
    private compservice: ComponentsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    //let cmpRef = this.compservice.widgets.get(this.id);
  }

  ngAfterViewInit() {
    //this.formDef.createForm(this.componentFactoryResolver,this.toolbarHost)
    /*
    if (this.style.static.display==='flex'){
      this.compservice.setStyle(this.id,'display','flex');
      this.compservice.setStyle(this.id,'flex-direction','column');
      this.sizes.map(el => {this.compservice.setStyle(el.id,'flex','0 0 calc('+el.size+'% - 0px)');})
    }*/
    //this.cd.detectChanges()
  }
}
