import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';

@Component({
  selector: 'istimebar',
  templateUrl: './istimebar.component.html',
  styleUrls: ['./istimebar.component.scss'],
})
export class IstimebarComponent implements AfterViewInit {
  url = 'http://www.w3.org/2000/svg';
  @Input() startHour: number = 0;
  @Input() endHour: number = 24;
  @Input() interval = 15;
  @Input() periodinhour = 4;
  @Input() scale = 1;
  @Input() timecomponent = null;
  @Input() height = 50;
  @Input() width = 20;

  #xdir = 'x';
  #ydir = 'y';
  textyoffset = '2';
  textxoffset = 0;
  orientation_ = 'vertical';
  @Input() set orientation(value: string) {
    if (this.orientation_ != value) {
      this.orientation_ = value;
      this.setOrientation();
    }
  }
  get orientation() {
    return this.orientation_;
  }

  private _startLocation = null;
  private _startRelativePosition = null;

  set startLocation(val: number) {
    this._startLocation = val;
    
    this.cd.detectChanges();

    const startEl = this.el.nativeElement.querySelector('#startTime');

    if (this.orientation === 'vertical') {
      const { top, height } = this.el.nativeElement.getBoundingClientRect();
      this._startRelativePosition = (val - top) / height;
      startEl.style.setProperty('top', `${100*this._startRelativePosition}%`);

    } else {
      const { left, width } = this.el.nativeElement.getBoundingClientRect();
      this._startRelativePosition = (val - left) / width;
      startEl.style.setProperty('left', `${100*this._startRelativePosition}%`);

    }
    // (val - top) / height
  }

  get startLocation(): number {
    return this._startLocation;
  }

  hours;
  mousemoveSubscription;
  mousewheelSubscription;

  constructor(private comp: ComponentsService, private el: ElementRef, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.calculateCurrentTime();
    setInterval(() => console.log('time'), 60000);

    this.updateHours();
  }

  calculateCurrentTime(orientation=this.orientation) {
    const date = new Date();

    const relativeTime = date.getHours() / 24 + date.getMinutes() / ( 60 * 24 )

    const currentTimeEl = this.el.nativeElement.querySelector('#currentTime');

    if (orientation === 'vertical') {
      currentTimeEl.style.setProperty('top', `${100 * relativeTime}%`)
    } else {
      currentTimeEl.style.setProperty('left', `${100 * relativeTime}%`)
    }
  }

  setOrientation(orient?) {
      const startEl = this.el.nativeElement.querySelector('#startTime');

      if (startEl) {
        const { width, height } = startEl.getBoundingClientRect();

        startEl.style.setProperty('height', orient === 'vertical' ? width : '100%');
        startEl.style.setProperty('width', orient === 'horizontal' ? height : '100%');
      }

    switch (orient ? orient : this.orientation_) {
      case 'vertical': {
        this.#xdir = 'x';
        this.#ydir = 'y';
        this.textyoffset = '2';
        this.textxoffset = 0;

        // if (this._startLocation) {
        //   const startEl = this.el.nativeElement.querySelector('#startTime');

        //   const { width, height } = startEl.getBoundingClientRect();

        //   startEl.style.setProperty('height', width);
        //   startEl.style.setProperty('width', height);
        //   startEl.style.removeProperty('left');
        //   startEl.style.setProperty('top', `${100 * this._startRelativePosition}%`);



        // }

        if (startEl) {
          startEl.style.removeProperty('left');
          startEl.style.setProperty('top', `${100 * this._startRelativePosition}%`);
        }

        break;
      }
      case 'horizontal': {
        this.#xdir = 'y';
        this.#ydir = 'x';
        this.textyoffset = '20';
        this.textxoffset = 0.5;

        if (startEl) {
          startEl.style.removeProperty('top');
          startEl.style.setProperty('left', `${100 * this._startRelativePosition}%`);
        }
        break;
      }
    }
    const currentEl = this.el.nativeElement.querySelector('#currentTime');

    if (currentEl) {
      const { width, height } = currentEl.getBoundingClientRect();

      currentEl.style.setProperty('height', width);
      currentEl.style.setProperty('width', height);
      this.calculateCurrentTime(orient ? orient : this.orientation_);
    }

    this.updateHours();
    this.orientation_ = orient ? orient : this.orientation_;
  }

  startHourValueChanges(val) {
    this.startHour = Math.max(Number(val), 0);
    this.updateHours();
    return true;
  }

  endHourValueChanges(val) {
    this.endHour = Math.min(Number(val), 24);
    this.updateHours();
    return true;
  }

  caleValueChanges(val) {
    this.scale = val;
    this.updateHours();
    return true;
  }

  updateHours() {
    this.drawRulers(Number(this.startHour), Number(this.endHour));
    this.cd.detectChanges();
  }

  onLoaded(timecomponent?: Component) {
    if (this.timecomponent) {
      this.mousemoveSubscription?.unsubscribe();
      this.mousewheelSubscription?.unsubscribe();
      this.timecomponent = timecomponent;
    }
    const cmpRef = this.comp.widgets.get(this.timecomponent);
    //this.reportoutlet.insert(cmpRef.hostView);
    if (cmpRef) {
      this.mousemoveSubscription = cmpRef.instance.mouseMove.subscribe((event) => {
        this.drawMousePos(event, cmpRef);
      });

      this.mousewheelSubscription = cmpRef.instance.mouseWheel.subscribe((event) => {
        this.wheelPosToHours(event);
      });
      this.cd.detectChanges();
    }
  }

  wheelPosToHours(event) {
    console.log('wheel event : ', event);
    if (event.deltaY > 0) {
      if (this.startHour > 0) {
        this.updateRulersHrs(this.startHour - 0.25, this.endHour - 0.25);
      }
    } else {
      if (this.endHour < 24) {
        this.updateRulersHrs(this.startHour + 0.25, this.endHour + 0.25);
      }
    }
  }

  drawMousePos(event, cmpRef) {
    const box = cmpRef.location.nativeElement.getBoundingClientRect();
    const svgSide = this.el.nativeElement.querySelector('#side-ruler-svg');
    let sideline;
    if (svgSide.querySelector('#side-ruler-pos')) {
      sideline = svgSide.querySelector('#side-ruler-pos');
    } else {
      sideline = document.createElementNS(this.url, 'line');
      sideline.setAttribute('id', 'side-ruler-pos');
    }
    sideline.setAttribute(this.#ydir + '1', ((event.y - box.y - (96 * this.scale) / 2.54) / this.scale).toString());
    sideline.setAttribute(this.#ydir + '2', ((event.y - box.y - (96 * this.scale) / 2.54) / this.scale).toString());
    sideline.setAttribute(this.#xdir + '1', '0');
    sideline.setAttribute(this.#xdir + '2', '20');
    sideline.setAttribute('stroke-width', '2');
    sideline.setAttribute('stroke', 'red');
    svgSide.appendChild(sideline);
  }

  updateRulers(from: string, until: string) {
    const start = from.split(':');
    const startHour = Number(start[0]) + Number(start[1]) / 60 / 100;
    const end = until.split(':');
    const endHour = Number(end[0]) + Number(end[1]) / 60 / 100;
    this.drawRulers(startHour, endHour);
    this.startHour = startHour;
    this.endHour = endHour;
    this.updateTimecomponent();
  }

  updateTimecomponent() {
    if (this.timecomponent) {
      const timegrid = this.comp.gc(this.timecomponent);
      timegrid.startHour = this.startHour;
      timegrid.endHour = this.endHour;
      timegrid.view.refreshCards();
    }
  }

  updateRulersHrs(startHour, endHour) {
    this.drawRulers(startHour, endHour);
    this.startHour = startHour;
    this.endHour = endHour;
    this.updateTimecomponent();
  }

  drawRulers(startHour, endHour) {
    const svgSide = this.el.nativeElement.querySelector('#side-ruler-svg');
    // mm lines

    //svgSide.parentNode.removeChild(svgSide);
    svgSide.querySelectorAll('*').forEach((n) => n.remove());
    for (let i = 0; i < (endHour - startHour + 1) * this.periodinhour; i++) {
      const line = document.createElementNS(this.url, 'line');
      line.setAttribute(this.#ydir + '1', ((i / ((endHour - startHour) * this.periodinhour)) * 100).toString() + '%');
      line.setAttribute(this.#xdir + '1', '15');
      line.setAttribute(this.#ydir + '2', ((i / ((endHour - startHour) * this.periodinhour)) * 100).toString() + '%');
      line.setAttribute(this.#xdir + '2', '20');
      line.setAttribute('stroke-width', '1');
      line.setAttribute('stroke', '#555');
      svgSide.appendChild(line);
    }
    // cm lines
    for (let i = 0; i < endHour - startHour + 1; i++) {
      const line = document.createElementNS(this.url, 'line');
      line.setAttribute(this.#ydir + '1', ((i / (endHour - startHour)) * 100).toString() + '%');
      line.setAttribute(this.#xdir + '1', '0');
      line.setAttribute(this.#ydir + '2', ((i / (endHour - startHour)) * 100).toString() + '%');
      line.setAttribute(this.#xdir + '2', '20');
      line.setAttribute('stroke-width', '1');
      line.setAttribute('stroke', '#555');
      svgSide.appendChild(line);
    }
    // text
    for (let i = 0; i < endHour - startHour + 1; i++) {
      const text = document.createElementNS(this.url, 'text');
      text.setAttribute(
        this.#ydir,
        ((i / (endHour - startHour)) * 100 - 0.5 - this.textxoffset).toString().toString() + '%'
      );
      text.setAttribute(this.#xdir, this.textyoffset);
      const hour = i + startHour;
      text.appendChild(document.createTextNode(hour.toString()));
      svgSide.appendChild(text);
    }
  }
}
