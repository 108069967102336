<span>{{lblField}}</span>
<span>{{lblExpression}}</span>
<span>{{lblValue}}</span>

<select [formControl]="formGroup.get('kolom')">
  <option *ngFor="let kolom of columnOptions" [value]="kolom.label">{{ kolom.label }}</option>
</select>
<select [formControl]="formGroup.get('expressie')">
  <option *ngFor="let exp of expressieValuesFiltered" [value]="exp.value">{{ exp.label }}</option>
</select>

<ng-template #valueContainer></ng-template>

<ng-template #stringInput>
  <input [formControl]="formGroup.get('value')" />
</ng-template>

<ng-template #numberInput>
  <input type="number" [formControl]="formGroup.get('value')" />
</ng-template>

<ng-template #dateInput>
  <input type="date" [formControl]="formGroup.get('value')" />
</ng-template>

<ng-template #isEnum>
  <isenum
    [formControlID]="formGroup.get('value')"
    class="is-enum"
    [enum]="activeColumn?.enum"
    [formInput]="false"
    [initialValue]="formGroup.get('value').value"
    multiselect="true"
  ></isenum>
</ng-template>

<span class="material-icons collapse" (click)="expandNode()">account_tree</span>
<span class="material-icons minus" (click)="removeNode()">remove</span>
