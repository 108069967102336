import { ComponentRef } from '@angular/core';

//FIXME: check if can be removed
export class SetEvent {
  static readonly type = '[SetEvent]';

  constructor(public payload: { name: string; active: boolean; info: any }) {}
}

export class AddComp {
  static readonly type = '[AddComp]';

  constructor(public payload: ComponentRef<any>) {}
}
