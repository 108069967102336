<input istag="input" type="text" class="trigger input" [formControl]="control" />
<div
  istag="options"
  class="options"
  [style.visibility]="open ? 'visible' : 'hidden'"
>
  <ng-container *ngFor="let option of options">
    <span istag="option" (click)="select(option[valuefield] ?option[valuefield]: option)" class="option">{{
      option[displayfield] ?option[displayfield]: option
    }}</span>
  </ng-container>
</div>
