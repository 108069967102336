import { ComponentsService } from '@ic-builder/is-base';
import { IsGridRegistry } from './../../isgrid.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'stylefunc',
  templateUrl: './stylefunc.component.html',
  styleUrls: ['./stylefunc.component.scss'],
})
export class StylefuncComponent implements OnInit {
  @ViewChild('stylefunceditor', { read: TemplateRef })
  addinput: TemplateRef<any>;
  activeDialog: MatDialogRef<any>;

  constructor(
    private dialog: MatDialog,
    private grid: IsGridRegistry,
    private comp: ComponentsService,
    private cd: ChangeDetectorRef
  ) {}

  options = { theme: 'vs-dark', language: 'json' };

  data = null;

  editor: any;
  @Input() initialValue: string;
  //activegrid;
  //gridid:number;
  //colname:string;
  //rownumber:number;
  formControl: FormControl;

  ngOnInit(): void {
    this.formControl = new FormControl(this.initialValue);
  }

  opendialog() {
    this.activeDialog = this.dialog.open(this.addinput);
    this.cd.detectChanges();
    //console.log(this.grid.get(this.gridid))
  }

  monaco(ev) {
    this.editor = ev;
    this.editor.setValue(this.formControl.value);
  }

  close() {
    this.activeDialog.close();
  }

  save() {
    this.formControl.setValue(this.editor.getValue());
    this.close();
  }

  testjson() {
    if (JSON.parse(this.formControl.value)) {
      alert('Ok');
    } else {
      alert('Not ok');
    }
  }
}
