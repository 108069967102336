import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'is-style-input',
  templateUrl: './is-style-input.component.html',
  styleUrls: ['./is-style-input.component.scss'],
})
export class IsStyleInputComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() initialValue: string;
  @Input() name: string;
  @Input() frmGrp: FormGroup;
  @Input() wizGrp: FormGroup;
  @Input() destroy = true;

  id: number;
  // name:string;

  controlsready = false;
  validators: Array<any> = [];
  parentid: number;

  notActive = false;
  controlid: number;

  constructor(private comp: ComponentsService) {}

  ngOnInit(): void {
    this.addControls([this.name], this.initialValue ? this.initialValue : null);
  }

  ngOnDestroy() {
    this.destroyControls([this.name]);
  }

  visFunc = (control) => {
    return true;
  };

  addControls(controlnames: string[], controlvalue: any, disabled?: boolean) {
    if (!this.frmGrp) {
      if (!this.comp.formgroup.get(this.parentid)) {
        this.comp.addformgroup(this.parentid);
      }
      this.frmGrp = this.comp.formgroup.get(this.parentid);
    }
    if (!this.frmGrp.get(controlnames[0])) {
      this.frmGrp.addControl(controlnames[0], new FormControl({ value: controlvalue, disabled: disabled }));
    }
    // controlnames.forEach(name => {
    //   if (!this.frmGrp.get(name)) {
    //     this.frmGrp.addControl(name, new FormControl({value: controlvalue, disabled: disabled}));
    //   }
    // })
    this.controlsready = true;
  }

  destroyControls(controlnames: string[]) {
    if (this.destroy) {
      controlnames.forEach((name) => {
        this.frmGrp.removeControl(name);
      });
    }
  }
}
