import { IsAction } from './isstate.model';

export class DataLoadedAction {
  static readonly type = '[DataLoadAction] DataLoadAction';
  constructor(public payload: string) {}
}

export class LoadAction {
  static readonly type = '[LoadAction] LoadAction';
  constructor(public payload: IsAction) {}
}

export class ExecuteAction {
  static readonly type = '[ExecuteAction] ExecuteAction';
  constructor(public payload: IsAction) {}
}

export class KillAction {
  static readonly type = '[KillAction] KillAction';
  constructor(public payload: IsAction) {}
}

export class ChangeStateAction {
  static readonly type = '[ChangeStateAction] ChangeStateAction';
  constructor(public payload: IsAction) {}
}
