import { ComponentsService } from '@ic-builder/is-base';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'issplitter',
  templateUrl: './splitter.component.html',
  styleUrls: ['./splitter.component.scss'],
})
export class SplitterComponent implements OnInit, AfterViewInit {
  constructor(
    private el: ElementRef,
    private compservice: ComponentsService,
    private cd: ChangeDetectorRef
  ) {}

  followMouse = false;
  startOffset = 0;

  totalWidth = 0;
  id: number;
  parentid: number;
  containertypes: string[] = ['ispanel', 'isgrid'];

  direction = 'horizontal';

  ngOnInit(): void {
    window.addEventListener('mousemove', (ev) => {
      if (this.followMouse) {
        const leftElem = this.el.nativeElement.previousSibling;
        const rightElem = this.el.nativeElement.nextSibling;

        const leftid = Number(leftElem.getAttribute('_isid'));
        const rightid = Number(rightElem.getAttribute('_isid'));

        const direction = this.testElements(
          leftElem,
          rightElem,
          leftid,
          rightid
        );

        if (direction && this.direction == 'horizontal') {
          const leftInfo = leftElem.getBoundingClientRect();
          const rightInfo = rightElem.getBoundingClientRect();

          const leftrightPad = leftElem.style.paddingRight
            ? Number(
                leftElem.style.paddingRight.substring(
                  0,
                  leftElem.style.paddingRight.length - 2
                )
              )
            : 0;
          const leftleftPad = leftElem.style.paddingLeft
            ? Number(
                leftElem.style.paddingLeft.substring(
                  0,
                  leftElem.style.paddingLeft.length - 2
                )
              )
            : 0;

          const rightrightPad = rightElem.style.paddingRight
            ? Number(
                rightElem.style.paddingRight.substring(
                  0,
                  rightElem.style.paddingRight.length - 2
                )
              )
            : 0;
          const rightleftPad = rightElem.style.paddingLeft
            ? Number(
                rightElem.style.paddingLeft.substring(
                  0,
                  rightElem.style.paddingLeft.length - 2
                )
              )
            : 0;

          const leftWidth = leftInfo.width; //+ leftrightPad + leftleftPad;

          const splitter = this.el.nativeElement.getBoundingClientRect();

          const change =
            direction * (ev.clientX - splitter.left - this.startOffset);

          if (
            leftWidth + change <=
              this.totalWidth - rightleftPad + rightrightPad &&
            rightInfo.width - change <=
              this.totalWidth - leftleftPad - leftrightPad
          ) {
            this.compservice.setStyle(
              leftid,
              'width',
              (leftInfo.width + change).toString() + 'px'
            );
            this.compservice.setStyle(
              rightid,
              'width',
              (rightInfo.width - change).toString() + 'px'
            );

            (<Record<string, string | number>>(
              this.compservice.defaultStylesSub.get(leftid).get('')
            )).width = (leftInfo.width + change).toString() + 'px';
            (<Record<string, string | number>>(
              this.compservice.defaultStylesSub.get(rightid).get('')
            )).width = (rightInfo.width - change).toString() + 'px';
          }
        }

        if (direction && this.direction == 'vertical') {
          const leftInfo = leftElem.getBoundingClientRect();
          const rightInfo = rightElem.getBoundingClientRect();

          const leftrightPad = 0; //leftElem.style.paddingTop? Number(leftElem.style.paddingRight.substring(0, leftElem.style.paddingRight.length-2)):0
          const leftleftPad = 0; //leftElem.style.paddingBottom?Number(leftElem.style.paddingLeft.substring(0, leftElem.style.paddingLeft.length-2)):0

          const rightrightPad = 0; //rightElem.style.paddingRight? Number(rightElem.style.paddingRight.substring(0, rightElem.style.paddingRight.length-2)):0
          const rightleftPad = 0; //rightElem.style.paddingLeft? Number(rightElem.style.paddingLeft.substring(0, rightElem.style.paddingLeft.length-2)): 0

          const topHeight = leftInfo.height; //+ leftrightPad + leftleftPad;

          const splitter = this.el.nativeElement.getBoundingClientRect();

          const change =
            direction * (ev.clientY - splitter.top - this.startOffset);

          if (
            topHeight + change <=
              this.totalWidth - rightleftPad + rightrightPad &&
            rightInfo.height - change <=
              this.totalWidth - leftleftPad - leftrightPad
          ) {
            this.compservice.setStyle(
              leftid,
              'height',
              (leftInfo.height + change).toString() + 'px'
            );
            this.compservice.setStyle(
              rightid,
              'height',
              (rightInfo.height - change).toString() + 'px'
            );

            (<Record<string, string | number>>(
              this.compservice.defaultStylesSub.get(leftid).get('')
            )).height = (leftInfo.height + change).toString() + 'px';
            (<Record<string, string | number>>(
              this.compservice.defaultStylesSub.get(rightid).get('')
            )).height = (rightInfo.height - change).toString() + 'px';
          }
        }
      }
    });
  }

  ngAfterViewInit() {
    let neighbour;
    /*if(this.compservice.widgets.get(this.parentid)){
      let children = this.compservice.widgets.get(this.parentid).location.nativeElement.children
      prev = children[children.length-1]
    } else {
      prev = [...this.compservice.widgets.values()]
      prev = prev[prev.length-1]
    }*/

    neighbour = [...this.compservice.widgets.values()];
    neighbour = neighbour[neighbour.length - 1];

    if (neighbour.instance.parentid == this.parentid) {
      if (this.containertypes.includes(neighbour.instance.type)) {
        if (
          neighbour.instance.alignment == 'top' ||
          neighbour.instance.alignment == 'bottom'
        ) {
          this.direction = 'vertical';

          const height = (<Record<string, string | number>>(
            this.compservice.defaultStylesSub.get(this.id).get('')
          )).height;
          const width = (<Record<string, string | number>>(
            this.compservice.defaultStylesSub.get(this.id).get('')
          )).width;

          (<Record<string, string | number>>(
            this.compservice.defaultStylesSub.get(this.id).get('')
          )).width = width ? width : '100%';
          (<Record<string, string | number>>(
            this.compservice.defaultStylesSub.get(this.id).get('')
          )).height = height ? height : '20px';

          this.el.nativeElement.style.width = width ? width : '100%';
          this.el.nativeElement.style.height = height ? height : '20px';
          this.el.nativeElement.style.cursor = 'row-resize';

          //this.compservice.widgets.get(this.parentid).changeDetectorRef.detectChanges();
        } else {
          const height = (<Record<string, string | number>>(
            this.compservice.defaultStylesSub.get(this.id).get('')
          )).height;
          const width = (<Record<string, string | number>>(
            this.compservice.defaultStylesSub.get(this.id).get('')
          )).width;

          this.direction = 'horizontal';
          (<Record<string, string | number>>(
            this.compservice.defaultStylesSub.get(this.id).get('')
          )).height = height ? height : '100%';
          (<Record<string, string | number>>(
            this.compservice.defaultStylesSub.get(this.id).get('')
          )).width = width ? width : '20px';

          this.el.nativeElement.style.width = width ? width : '20px';
          this.el.nativeElement.style.height = height ? height : '100%';
          this.el.nativeElement.style.cursor = 'col-resize';
        }
      }
    }

    window.addEventListener('mouseup', () => {
      this.followMouse = false;
    });
    this.el.nativeElement.addEventListener('mousedown', (ev) => {
      this.followMouse = true;

      if (this.direction == 'horizontal') {
        this.startOffset =
          ev.clientX - this.el.nativeElement.getBoundingClientRect().left;
        console.log(this.startOffset);
        const leftWidth =
          this.el.nativeElement.previousSibling.getBoundingClientRect().width;
        const rightWidth =
          this.el.nativeElement.nextSibling.getBoundingClientRect().width;

        this.totalWidth = leftWidth + rightWidth;
      } else {
        this.startOffset =
          ev.clientY - this.el.nativeElement.getBoundingClientRect().top;
        const topHeight =
          this.el.nativeElement.previousSibling.getBoundingClientRect().height;
        const bottomHeight =
          this.el.nativeElement.nextSibling.getBoundingClientRect().height;

        this.totalWidth = topHeight + bottomHeight;
      }
    });

    //{"display":"block","position":"relative","left":0,"top":0,"width":"100px","height":"25px"}
  }

  testElements(left, right, leftid, rightid) {
    if (leftid && rightid) {
      const leftalign = this.compservice.widgets.get(leftid).instance.alignment;
      const rightalign =
        this.compservice.widgets.get(rightid).instance.alignment;

      return leftalign == rightalign
        ? leftalign == 'left' || leftalign == 'top'
          ? 1
          : -1
        : false;
    }

    /*if(left.style && right.style){
      if(left.style.float==right.style.float)
      return left.style.float=='left'? 1:-1;
    }*/

    return false;
  }
}
