import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ComponentsService, IslanguageService, Outlet2Service } from '@ic-builder/is-base';

@Component({
  selector: 'menuhandleritem3',
  templateUrl: './menuhandleritem3.component.html',
  styleUrls: ['./menuhandleritem3.component.scss'],
})
export class Menuhandleritem3Component implements OnInit {
  constructor(private el: ElementRef, public comp: ComponentsService, private outlet:Outlet2Service, private cd: ChangeDetectorRef, private languages:IslanguageService) {}

  @ViewChild('children', { read: ViewContainerRef }) public children: ViewContainerRef;

  id: number;

  label: string = '';
  icon: string = '';

  renderDirection: string = 'vertical';
  indent: string = '2px';
  info: any;
  translation: any | null = null;  

  @HostListener('mouseenter')
  MouseEnter() {
    const left = this.el.nativeElement.getBoundingClientRect().width;
    const top = this.el.nativeElement.getBoundingClientRect().height;

    if (this.renderDirection == 'vertical') {
      this.el.nativeElement.style.setProperty('--top', this.indent);
      this.el.nativeElement.style.setProperty('--left', left.toString() + 'px');
    } else {
      this.el.nativeElement.style.setProperty('--top', top.toString() + 'px');
      this.el.nativeElement.style.setProperty('--left', this.indent);
    }

    const children = this.comp.widgets.get(this.id)['ischildren'] ? this.comp.widgets.get(this.id)['ischildren'] : [];

    let hasChildren = false;
    let hasIcon = false;

    children.map((c) => {
      this.children.insert(c.hostView);

      c.instance.renderDirection = this.renderDirection;

      if (c.instance.icon) {
        hasIcon = true;
      }
      const temp = this.comp.widgets.get(c.instance.id)['ischildren'];
      if (temp) {
        hasChildren = true;
      }
    });

    let template = 'max-content';

    if (hasIcon) {
      template = '25px ' + template;
    } else {
      template = '0px ' + template;
    }

    if (hasChildren) {
      template = template + ' 25px';
    } else {
      template = template + ' 0px';
    }

    children.map((c) => {
      c.location.nativeElement.style['grid-template-columns'] = template;
    });

    this.cd.detectChanges();
  }

  setInfo(p1, p2) {
    this.info = p1;
  }

  @HostListener('mouseleave')
  MouseLeave() {
    while (this.children.length > 0) {
      this.children.detach(0);
    }

    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.el.nativeElement.setAttribute('istag', 'menuitem');
    this.cd.detectChanges();
  }

  translate(lang){
    if (this.translation){
      if (typeof this.translation !== 'string'){
        this.label = this.translation[lang];
      } else
      {  
        this.label = this.languages.translate(lang,this.translation);
      }
    }
  }    
}
