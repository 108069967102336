{{ day }}

<svg>
  <circle
    cx="50%"
    cy="50%"
    r="35%"
    fill="none"
    stroke="black"
    stroke-width="0.5"
  ></circle>
</svg>
