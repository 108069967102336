import {
  Injectable,
  Compiler,
  Injector,
  Component,
  NgModule,
  ViewContainerRef,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IscompilerService {
  constructor(private compiler: Compiler, private _injector: Injector) {}

  compile(
    name: string,
    selector,
    template,
    style: string,
    vc: ViewContainerRef
  ) {
    const tmpCmp = Component({ selector: selector, template: template })(
      class {}
    );
    const tmpModule = NgModule({ declarations: [tmpCmp] })(class {});

    this.compiler
      .compileModuleAndAllComponentsAsync(tmpModule)
      .then((factories) => {
        const f = factories.componentFactories[0];
        if (vc) {
          const cmpRef = vc.createComponent(f);
          cmpRef.instance.name = name;
        }
      });
  }
}
