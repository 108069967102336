import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { ComponentRef, Injectable } from '@angular/core';
import {
  AddRecord,
  ClearDSChildCache,
  DeleteRecord,
  EnumToDataset,
  ExecQry,
  Execute,
  ExecuteAndRead,
  //ExecuteAction,
  FetchData,
  GetEventListners,
  GetMaxComponentNumber,
  InitAccount,
  InitApplication,
  InitApplicationByName,
  InitPortal,
  LoadData,
  LoadDataset,
  LoadDataTree,
  //LoadMenu,
  LoadModule,
  LocalLoginAction,
  LoginAction,
  LogoutAction,
  LogState,
  RegisterOutlet,
  RemoveDataset,
  RunQuery,
  SelectAccount,
  SelectTreeNode,
  SetAction,
  SetConfig,
  SetDataConnectionId,
  SetDataset,
  AppendDataSet,
  UnshiftDataSet,
  //SetFormGroupOutlet,
  UpdateLocalCache,
  SetOutlet,
  UpdateRecord,
  UpdateRecordByKey,
  ExecCommand,
  LoginFailed,
  LoadDatasets,
  ClearLoadedSet,
  LoggingonServer,
  IsMessage,
  IsMessageHandled,
  PreviewReport,
  OnlineStatusAction,
  SendofflineCache,
  AppError,
  IIsAppError,
  StoreInOfflineCache,
  MutateStateByFunction,
  RunScript,
  ErrorAsMessage,
  LoginFailedMessage,
  LoadTreeDataset,
  InitEditor,
  SetChangeTranslation,
} from './isapplication.action';
import { IsCloudService, LoginResponse } from '@ic-builder/data-access-iscloud';
import {
  ComponentsService,
  IIsComponent,
  IsMenuItem,
  LoadComponent,
  IIsAppConfig,
  Outlet2Service,
  IsthemingService,
  IslanguageService,
} from '@ic-builder/is-base';
import { catchError, tap } from 'rxjs/operators';
import { IsData } from './isapplication.model';
import { DataLoadedAction } from '../isstate/isstate.action';
//import { classToClass, plainToClass } from 'class-transformer';
import { FormGroup } from '@angular/forms';
import { SessionService } from '@ic-builder/auth';
import { EMPTY, from, of } from 'rxjs';
import { db } from '@ic-builder/is-base';
import { keyBy } from 'lodash';
import { state } from '@angular/animations';
import { SpeedTestService } from 'libs/data-access-iscloud/src/lib/speed-test.service';

export enum ApplicationTypes {
  UserApp = 'UserApp',  
  BuilderApp = 'BuilderApp',
  AdminApp = 'AdminApp'
 }

export interface IsApplicationStateModel {
  appconfig: IIsAppConfig;
  online: boolean;
  offlinecache: Array<any>;
  apptype: ApplicationTypes | null;
  appname: string;
  initstates: Array<any>;
  mainform: any;
  account: number;
  accounts: any;
  components: Map<number, IIsComponent>;
  forms: Map<number, IIsComponent>;
  columns: Map<number, IIsComponent>;
  outlets: any;
  menus: Map<string, IsMenuItem>;
  data: IsData[];
  datatree: Map<string, any>;
  config: Map<string, any>;
  function: Map<string, Function>;
  newcompid: number;
  eventstest: any;
  version: number;
  unimonitor: boolean;
  //datasets: Map<string, any>;
  dssets: any;
  datasetloaded: string;
  datadefs: Map<string, any>;
  sessionmessages: Map<string, any>;
  loginfailed: LoginFailedMessage;
  messages: any;
  error: IIsAppError;
  logerrorasmessage: boolean;
  senderrorstoserver: boolean;
}

@State<IsApplicationStateModel>({
  name: 'isapplicationstate',
  defaults: {
    mainform: null,
    online: true,
    offlinecache: [],
    appconfig: {
        username:null,
        name:'',      
        title:'',
        language:null,
        password:null,
        id:null,
        outlet:null,
      },
    initstates: null,
    apptype: null,
    appname: null,
    account: null,
    accounts: null,
    components: new Map<number, IIsComponent>(),
    forms: new Map<number, IIsComponent>(),
    columns: new Map<number, IIsComponent>(),
    outlets: [],
    menus: new Map<string, IsMenuItem>(),
    data: null,
    datatree: null,
    config: null,
    function: new Map<string, Function>(),
    newcompid: 20000,
    eventstest: null,
    version: 1,
    unimonitor: false,
    //datasets: new Map<string, any>(),
    dssets: {},
    datasetloaded: null,
    datadefs: new Map<string, any>(),
    sessionmessages: new Map<string, any>(),
    loginfailed: null,
    messages: null,
    logerrorasmessage: false,
    error: null,
    senderrorstoserver: false,
  },
})

@Injectable({ providedIn: 'root' })
export class IsApplicationState {
  teller: number;
  editform = 'isform';
  version = 1;
  accounts: any[];
  counter = 1;

  constructor(
    private ic: IsCloudService,
    private store: Store,
    private sessionService: SessionService,
    private comp: ComponentsService,
    private outlet: Outlet2Service,
    private speedtest: SpeedTestService,
    private themeService: IsthemingService,
    private languageService: IslanguageService
  ) {
    const funclist = new Map<string, Function>();
    funclist.set('IsApplicationState.dsname', this.appsetdsname);
    this.comp.functions.set(-1, funclist);

    window.addEventListener('online', () => {
      this.store.dispatch(new OnlineStatusAction(true));
    });

    window.addEventListener('offline', () => {
      this.store.dispatch(new OnlineStatusAction(false));
    });


  }

  @Selector()
  public static mainform(state: IsApplicationStateModel) {
    return state.mainform;
  }

  //@Selector()
  //  public static selectedmodule(state: IsApplicationStateModel) {
  //    return state.selectedmodule;
  // }

  @Selector()
  public static datatree(state: IsApplicationStateModel) {
    return (name: string) => {
      return state.datatree.get(name);
    };
  }

  @Selector()
  public static config(state: IsApplicationStateModel) {
    return (name: string) => {
      return state.config.get(name);
    };
  }

  @Selector()
  public static appconfig(state: IsApplicationStateModel) {
    return state.appconfig;
  }

  @Selector()
  public static loginfailed(state: IsApplicationStateModel) {
    return state.loginfailed;
  }

  @Selector()
  public static datasetdef(state: IsApplicationStateModel) {
    return (name: string) => {
      return state.datadefs.get(name);
    };
  }

  @Selector()
  public static function(state: IsApplicationStateModel) {
    return (name: string) => {
      return state.function.get(name);
    };
  }

  @Selector()
  public static getAction(state) {
    return (type) => {
      return state.actionname[type];
    };
  }

  @Selector()
  public static outlet(state) {
    return (id: number) => {
      return state.outlets[id].selectedview;
    };
  }

  @Selector()
  public static datasetx(state) {
    return state.datasetloaded;
  }

  @Selector()
  public static accounts(state) {
    return state.accounts;
  }

  @Selector()
  public static datasetval(state) {
    return (dsname: string) => {
      return state.dssets[dsname];
    };
  }

  static outletformgroup(outlet: number, formid: number) {
    return createSelector([IsApplicationState], (state: IsApplicationStateModel) => {
      return state.outlets[outlet].formgroups[formid];
    });
  }

  static outletx(id: number) {
    return createSelector([IsApplicationState], (state: IsApplicationStateModel) => {
      return state.forms.get(state.outlets[id].selectedview);
    });
  }

  static outleteditable(id: number) {
    return createSelector([IsApplicationState], (state: IsApplicationStateModel) => {
      return state.outlets[id].editable;
    });
  }

  static dataset(dsname: string) {
    return createSelector([IsApplicationState], (state: IsApplicationStateModel) => {
      return state.dssets[dsname];
    });
  }

  static dsset(dsname: string) {
    return createSelector([IsApplicationState], (state: IsApplicationStateModel) => {
      return state.dssets[dsname];
    });
  }

  static datadef(dsname: string) {
    return createSelector([IsApplicationState], (state: IsApplicationStateModel) => {
      return state.datadefs.get(dsname);
    });
  }

  static formx(id: number) {
    return createSelector([IsApplicationState], (state: IsApplicationStateModel) => {
      return [state.forms.get(id)];
    });
  }

  static getaccount() {
    return createSelector([IsApplicationState], (state: IsApplicationStateModel) => {
      return state.account;
    });
  }

  appsetdsname(name) {
    return IsApplicationState.dataset(name);
  }
  /*
  static formasnavigator(id: number) {
    return createSelector([IsApplicationState], (state: IsApplicationStateModel) => {
      let a:IsComponent=state.forms.get(id);
      if (a){
        let b = new IsComponent(a,{children:true});
        a.compaschild(a,b);
      return [classToPlain(b)];
      } else
      { return null }
    });
  }*/

  @Action(SetChangeTranslation)
  setChangeTranslation(){
    this.store
    .select((state) => state['isapplicationstate'].appconfig.language)
    .subscribe((language) => {
      //if (item) {
        if (language && (language != this.comp.language)) {
          this.comp.language = language;
          this.comp.widgets.forEach((cmpRef:ComponentRef<any>) => {
            console.log(cmpRef);
            if (cmpRef.instance.translate) {
              cmpRef.instance.translate(language)
            }
          })  
        }
      //}
    });
  }

  @Action(SendofflineCache)
  sendofflineCache() {
    const offlinecache = JSON.parse(localStorage.getItem('offlinecache')); //state.offlinecache;
    //console.log('send offline cahce ', offlinecache);
    if (!Array.isArray(offlinecache)) return;
    localStorage.removeItem('offlinecache');

    if (offlinecache.length > 0 && window.navigator.onLine) {
      const cache = offlinecache.map((item) => {
        return {
          name: item.payload.command,
          action: 'write',
          data: item.payload.data,
          role: '1',
          language: 'nl',
          ismessage: item.payload.ismessage,
          batch: item.payload.batch ? item.payload.batch : false,
          logging: { unimonitor: true /*state.unimonitor*/ },
          offline: true,
        };
      });

      //this.store.dispatch(cache);
      //patchState({ offlinecache: [] });
      return this.ic.writeArrayActionsData({ actions: cache }).subscribe((c) => console.log(c)); //.pipe(tap(() => {}));
    }
  }

  @Action(OnlineStatusAction)
  onLineStatus({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: OnlineStatusAction) {
    const state = getState();
    //console.log(' online status : ', payload);
    patchState({ online: payload });

    if (payload) {
      this.sendofflineCache();
    }
  }

  openApplication(a,onlinestate){
    if(a.params){
      
      if (a.params.languages){
        this.languageService.translations = a.params.languages;
      }
      if (a.params.colorschemas){
        const colorschemas = JSON.parse(a.params.colorschemas);
        colorschemas.forEach((item) => {
          const data = JSON.parse(item.data)
          this.themeService.colorThemes.push(data);
        });
      }
      if (a.params.portalname){
        this.comp.appname = a.params.portalname;
      }
      if (a.params.colorschema){
        this.themeService.addThemeToComposite(this.themeService.getColorTheme(a.params.colorschema), 'colorTheme');
      }

    }
    this.store.dispatch(
      new InitApplication({ id: Number(a.siteStartid), name: 'login', account: a.account, outlet: 0 ,params: a.params, serveronline:onlinestate})
    );
  }

  @Action(InitPortal)
  initPortal({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: InitPortal) {
    //console.log(`%cClass: IsApplicationState, Function: initPortal(payload): `, 'color: red;', payload);

    if (window.navigator.onLine){
      console.log('online');  
      patchState({apptype:ApplicationTypes.UserApp});
      return this.ic.start(payload.applicationname).pipe(      
      tap((a) => {
        this.ic.credentials = null;
        a = a.body;
        a.siteStartid = Number(a.id);
        localStorage.setItem('siteParams', JSON.stringify(a));
        this.openApplication(a,true);      
      }),
      catchError((error: any) => {
        if ([502,504].includes(error.status)){
          console.log('server offline');  
          const siteParams = JSON.parse(localStorage.getItem('siteParams'));
          this.openApplication(siteParams,false);    
          return EMPTY;
        } else
        {
          console.error(error);
          return EMPTY;
        }
      })
      );
    } else {
      console.log('offline');  
      const siteParams = JSON.parse(localStorage.getItem('siteParams'));
      this.openApplication(siteParams,false);
      //this.store.dispatch(new LoadComponent({id:32031,name:'intersoftmobile', outlet: 0 }));
    }
  }

  @Action(InitEditor)
  initEditor({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: InitEditor) {
    //console.log(`%cClass: IsApplicationState, Function: initPortal(payload): `, 'color: red;', payload);

    if (window.navigator.onLine){
      console.log('online');  
      patchState({apptype:ApplicationTypes.BuilderApp,mainform:1});
      return this.ic.start('IcBuilderEditor').pipe(      
      tap((a) => {
        this.ic.credentials = null;
        a = a.body;
        a.siteStartid = Number(a.editorloginform)?Number(a.editorloginform):1;
        localStorage.setItem('siteParams', JSON.stringify(a));
        this.openApplication(a,true);      
      }),
      catchError((error: any) => {
        if (error.status == 502){
          console.log('server offline');  
          debugger
          const siteParams = JSON.parse(localStorage.getItem('siteParams'));
          this.openApplication(siteParams,false);    
          return of('Server Offline');
        } else
        {
          console.error(error);
        }
      })
      );
    } else {
      console.log('offline');  
      const siteParams = JSON.parse(localStorage.getItem('siteParams'));
      this.openApplication(siteParams,false);
      //this.store.dispatch(new LoadComponent({id:32031,name:'intersoftmobile', outlet: 0 }));
    }
  }

  @Action(LogoutAction)
  logoutAction() {
    this.ic.logout().subscribe(() => {
      this.sessionService.setCurrentUser(null);
      window.location.reload();
    });
  }

  @Action(LoginAction)
  loginAction({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: LoginAction) {
    const state = getState();
    //if (payload.applicationid) {
    //  this.store.dispatch(new LocalLoginAction(payload));
    //} else {
      const optionalcfg: Record<string, unknown> = {};
      if (payload.company) {
        optionalcfg.companynr = parseInt(payload.company);
      }  


      this.ic.login(payload.applicationname, payload.company, payload.username, payload.password, payload.language).subscribe((data) => {
        if (data?.ISSESSION != null) {
          this.ic.credentials = null;
          const appform = data?.relationtype
          optionalcfg.companyname = data?.companyname?data?.companyname:data?.lastname;
          optionalcfg.companynr = data?.companynr?data?.companynr:data?.employeenr;
          optionalcfg.relationtype = data?.relationtype
          optionalcfg.relkind = data?.relkind
          optionalcfg.userid = data?.userid;
          optionalcfg.email = data?.email;
          optionalcfg.desktopmenu = data?.desktopmenuitems?JSON.parse(data?.desktopmenuitems):null;
          optionalcfg.mobilemenu = data?.menuitems?JSON.parse(data?.menuitems):null;
          optionalcfg.portalmenu = data?.portalmenuitems?JSON.parse(data?.portalmenuitems):null;
          patchState({
            appconfig: {
              ...state.appconfig,
              username: payload.username,
              password: payload.password,
              language: data.language?data.language:payload.language,                   
              title: payload.applicationname,
              ...optionalcfg,
            },
          });

          if (payload.applicationid == 2 ){
            this.store.dispatch(payload.succes[appform]);
          } else
          {
             this.store.dispatch(payload.succes);
          }

        } else {
          patchState({
            loginfailed: {message:'test',status:0},
          });
        }
      },
      (error) => {
        patchState({
          loginfailed: {message:error.message,status:error.status}
        });
      });
    //}
  }

  @Action(LoginFailed)
  loginFailed({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: LoginFailed) {
    const state = getState();
    patchState({
      loginfailed: {message:payload.message,status:0}
    });
  }

  @Action(SetDataConnectionId)
  setDataConnectionId(
    { getState, patchState }: StateContext<IsApplicationStateModel>,
    { payload }: SetDataConnectionId
  ) {
    const state = getState();
    if (payload != null) {
      //console.log('ApplicationState:SetDataConnectionId');
      const currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const connection = state.dssets['application.accounts'];
      //console.log('connection : ', connection);
      if (connection) {
        const admin = connection.find((item) => {
          return item.account == payload.connectionid.toString();
        });
        currentUser['applicationid'] = parseInt(admin.applicationform);
        currentUser['account'] = payload.connectionid;
      } else {
        //currentUser['applicationid'] = parseInt(payload.applicationform);
        currentUser['account'] = payload.connectionid;
      }
      localStorage.setItem('currentUser', JSON.stringify(currentUser));

      patchState({
        account: payload.connectionid,
      });

      this.sessionService.setCurrentUser(currentUser);
    }
  }

  @Action(SelectAccount)
  selectAccount({ getState }: StateContext<IsApplicationStateModel>, { payload }: SelectAccount) {
    //thithis.store.dispatch(new LoadForm({ id: payload.id, name: 'IntersoftOne', outlet: payload.outlet }));

    const request = { role: '1', language: 'nl', logging: { unimonitor: false } };
    if (payload?.account) {
      request['account'] = payload.account;
    }
    return this.ic.execute('selectaccount', request).pipe(
      tap((data) => {
        if (data) {
          this.store.dispatch(new SetDataset({ dsname: 'application.accounts', dataset: data }));
        }
      })
    );
  }

  @Action(InitAccount)
  initAccount({ getState }: StateContext<IsApplicationStateModel>, { payload }: InitAccount) {
    const state = getState();
    //console.log(`%cClass: IsApplicationState, Function: initAccount(payload): `, 'color: black;', payload);

    this.ic.execute(state.appname, state.appconfig.company, payload.username).subscribe(() => {
      this.ic.credentials = null;
      this.store.dispatch(new InitApplication({ id: 11261, name: 'login', account: '370', outlet: 0 }));
    });
  }

  @Action(InitApplication)
  initApplication({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: InitApplication) {
    //console.trace(`%cClass: IsApplicationState, Function: initApplication(payload): `, 'color: black;', payload);
    const state = getState();
    //thithis.store.dispatch(new LoadForm({ id: payload.id, name: 'IntersoftOne', outlet: payload.outlet }));
    this.store.dispatch({
      type: '[SetFormConnectionId]',
      payload: { connectionid: payload.account ? payload.account : 10 },
    });
    this.store.dispatch(new LoadComponent({ id: payload.id, name: 'applicationstate', outlet: 0, params: payload.params ,serveronline: payload.serveronline }));
    const appconfig = state.appconfig;
    patchState({
      appconfig: { ...appconfig, name: payload.name },
    });
  }

  @Action(LocalLoginAction)
  localloginAction({ payload }: LocalLoginAction) {
    const cfg: IIsAppConfig = {
      company: payload.customername,
      companynr: payload.customernr,
      title: payload.applicationname,
      username: payload.username,
      id: payload.applicationid,
      name: '',
      language: 'nl',
      password: '',
      outlet: 0,
    };
    if (payload.application) {
      this.store.dispatch(
        new InitApplicationByName({ id: payload.application, name: payload.applicationame, outlet: cfg.id })
      );
    } else {
      this.store.dispatch(new InitApplication({ id: cfg.id, name: cfg.title, outlet: cfg.id }));
    }
  }

  @Action(InitApplicationByName)
  getApplicationByName({ payload }: InitApplicationByName) {
    //console.log(`%cClass: IsApplicationState, Function: getApplicationByName(): `, 'color: black;', payload);
    //this.store.dispatch(new LoadForm({ id: payload.id, name: 'IntersoftOne', outlet: payload.outlet }));

    return this.ic.getData('getcomponentid', { name: payload.name, role: '1', language: 'nl' }).pipe(
      tap((data) => {
        if (data) {
          this.store.dispatch(new LoadComponent({ id: data[0].id, name: 'applicationstate1', outlet: 0 }));
        }
      })
    );
  }

  @Action(RemoveDataset)
  removeDataset({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: RemoveDataset) {
    const state = getState();
    //const datasets = new Map<string, any>(state.datasets);
    //datasets.delete(payload.dsname);

    const dssets = state.dssets;
    dssets[payload.dsname] = null;

    patchState({
      //datasets: datasets,
      dssets: dssets,
    });
  }

  @Action(ClearDSChildCache)
  clearDSCh1ildCache({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: ClearDSChildCache) {
    function inItems(value): boolean {
      return !payload.items || payload.items.includes(value);
    }
    const state = getState();
    let datasets = state.dssets;
    const dataset = datasets[payload.dsname];
    if (dataset !== null) {
      dataset.map((item) => {
        if (item[payload.field]) {
          if (inItems(item[payload.field])) {
            delete item[payload.field];
          }
        }
      });
      datasets[payload.dsname] = dataset;
    }

    patchState({
      dssets: datasets,
    });
  }

  @Action(ClearLoadedSet)
  clearLoadedSet({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: ClearLoadedSet) {
    const state = getState();
    const dssets = state.dssets;
    dssets[payload] = null;
    patchState({
      //datasets: datasets,
      dssets: dssets,
    });
  }

  @Action(RunQuery)
  runQuery({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: RunQuery) {
    const state = getState();
    const account = payload.account ? payload.account : state.account;
    let datadefs = state.datadefs;
    const dssets = state.dssets;
    payload.params.dsname = payload.dsname;
    return this.ic
      .getData('runsql', {
        definition: payload.definition,
        params: payload.params,
        sql: payload.sql,
        role: '1',
        language: 'nl',
        logging: { unimonitor: state.unimonitor },
        account: account,
      })
      .pipe(
        tap((data) => {
          if (data) {
            if (Array.isArray(data)) {
              const definition = payload.definition ? data.shift() : null;

              if (definition) {
                const datadef = datadefs.get(payload.dsname);
                if (datadef == null) {
                  datadefs.set(payload.dsname, [...definition]);
                } else {
                  datadefs = new Map<string, any>(datadefs);
                  datadefs.set(payload.dsname, definition);
                }
              }
              if (payload.reducer) {
                data = payload.reducer(data);
              }
              /*
              let datasets = state.datasets;
              const dataset = datasets.get(payload.dsname);
              if (dataset == null) {
                datasets.set(payload.dsname, data);
              } else {
                datasets = new Map<string, any>(datasets);
                datasets.set(payload.dsname, data);
              }*/

              if (payload.transformtodict){
                const dict = {};
                data.forEach((item ) => {dict[item[payload.transformtodict]] = item})
                dssets[payload.dsname+'.dict'] = dict;
              } 
              dssets[payload.dsname] = data;             

              patchState({
                //datasets: datasets,
                dssets: dssets,
                datadefs: datadefs,
                datasetloaded: payload.dsname,
              });

              if (payload.ondataLoaded) {
                payload.ondataLoaded(data, payload.params, this.store, this.comp, this, payload.component);
              }
            } else {
              console.log('Data is not an array '+payload.dsname);
              if (data['missingParams']) {
                if (payload.fixparams) {
                  if (typeof payload.fixparams === 'string') {
                    this.store.dispatch({ action: payload['fixparams'], payload: data['missingParams'] });
                  } else if (typeof payload.fixparams === 'function') {
                    payload.fixparams(data['missingParams']);
                  }
                }
              }
            }
          }
        }),
        catchError((error: any) => {          
          if (error.status == 0) {
            return of('Network Offline');
          } else {
            this.store.dispatch(
              new AppError({
                code: error.status,
                source: 'appstate.runsql '+payload.dsname,
                message: error.message + '\n ' + error?.error?.error,
                level: 'error',
              })
            );
            return of('from CatchError');
          }
        })
      );
  }

  @Action(RunScript)
  runScript({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: RunScript) {
    const state = getState();
    const account = payload.account ? payload.account : state.account;
    //let datadefs = state.datadefs;
    //const dssets = state.dssets;
    return this.ic
      .getData('rundbscript', {
        definition: payload.definition,
        params: payload.params,
        sql: payload.sql,
        role: '1',
        language: 'nl',
        logging: { unimonitor: state.unimonitor },
        account: account,
      })
      .pipe(
        tap((data) => {
          if (data) {
            if (Array.isArray(data)) {
              console.log(data);
            } else {
              if (data['missingParams']) {
                if (payload.fixparams) {
                  if (typeof payload.fixparams === 'string') {
                    this.store.dispatch({ action: payload['fixparams'], payload: data['missingParams'] });
                  } else if (typeof payload.fixparams === 'function') {
                    payload.fixparams(data['missingParams']);
                  }
                }
              }
            }
          }
        }),
        catchError((error: any) => {
          if (error.status == 0) {
            return of('Network Offline');
          } else {
            this.store.dispatch(
              new AppError({
                code: error.status,
                source: 'appstate.runscript',
                message: error.message + '\n ' + error.error.error,
                level: 'error',
              })
            );
            return of('from CatchError');
          }
        })
      );
  }

  @Action(ErrorAsMessage)
  errorasMessage({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: ErrorAsMessage) {
    const state = getState();
    patchState({ logerrorasmessage: payload });
  }

  @Action(AppError)
  appError({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: AppError) {
    const state = getState();
    patchState({ error: payload });
    switch (payload.level) {
      case 'error':
        console.trace(payload.source, ': ', payload.message);
        break;
      case 'warning':
        console.log(payload.source, ': ', payload.message);
        break;
      default:
    }
    if (state.senderrorstoserver) {
      this.store.dispatch(new Execute({ command: 'application.error', data: payload, fromerror: true }));
    }
    if (state.logerrorasmessage) {
      this.store.dispatch(
        new IsMessage({
          name: payload.source + ': ' + payload.message,
          time: this.comp.getDbStrDateTime(0),
          color: payload.level == 'error' ? 'red' : 'yellow',
        })
      );
    }
  }

  @Action(EnumToDataset)
  enumToDataset({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: EnumToDataset) {
    const state = getState();
    /*
    let datasets = state.datasets;
    const dataset = datasets.get(payload.group);
    if (dataset == null) {
      datasets.set(payload.group, payload.data);
    } else {
      datasets = new Map<string, any>(datasets);
      datasets.set(payload.group, payload.data);
    }*/

    const dssets = state.dssets;
    dssets[payload.group] = payload.data;

    let datadefs = state.datadefs;
    const datadef = datadefs.get(payload.group);
    const definition = [
      { datatype: 'integer', name: 'id', width: '20%' },
      { datatype: 'string', name: 'description', width: '80%' },
      { datatype: 'string', name: 'groep', width: '0%' },
      { datatype: 'boolean', name: 'selected', width: '0%' },
    ];
    if (datadef == null) {
      datadefs.set(payload.group, [...definition]);
    } else {
      datadefs = new Map<string, any>(datadefs);
      datadefs.set(payload.group, definition);
    }

    patchState({
      //datasets: datasets,
      dssets: dssets,
      datadefs: datadefs,
      datasetloaded: payload.group,
    });
  }

  getDataset(payload: any) {
    const dsname = payload.master
      ? payload.master.dsname + '.' + payload.dsname + '.' + payload.master.key + '.' + payload.master.value
      : payload.dsname;
    return this.store.selectSnapshot(IsApplicationState.dataset(dsname));
  }

  loadDatasetObs() {}

  @Action(SetDataset)
  setDataset({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: SetDataset) {
    const state = getState();
    /*
    let datasets = state.datasets;
    const dataset = datasets.get(payload.dsname);
    if (dataset == null) {
      datasets.set(payload.dsname, payload.dataset);
    } else {
      datasets = new Map<string, any>(datasets);
      datasets.set(payload.dsname, payload.dataset);
    }*/

    const dssets = state.dssets;
    dssets[payload.dsname] = payload.dataset;
    //console.log('setdataset : ', payload.dsname);
    
    patchState({
      //datasets: datasets,
      dssets: dssets,
      datasetloaded: payload.dsname,
    });
  }

  @Action(AppendDataSet)
  appendDataSet({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: AppendDataSet) {
    const { dssets } = getState();

    if (Array.isArray(payload.data)) {
      if (dssets[payload.dsname]) {
        dssets[payload.dsname] = [...dssets[payload.dsname], ...payload.data];
      } else {
        dssets[payload.dsname] = [...payload.data];
      }
    } else {
      if (dssets[payload.dsname]) {
        dssets[payload.dsname] = [...dssets[payload.dsname], payload.data];
      } else {
        if (payload.data) {
          dssets[payload.dsname] = [payload.data];
        } else {
          dssets[payload.dsname] = [];
        }
      }
    }

    patchState({
      dssets: dssets, // { ...dssets}
    });
  }

  @Action(UnshiftDataSet)
  unshiftDataSet({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: UnshiftDataSet) {
    const { dssets } = getState();

    if (Array.isArray(payload.data)) {
      if (dssets[payload.dsname]) {
        dssets[payload.dsname] = [...payload.data, ...dssets[payload.dsname]];
      } else {
        dssets[payload.dsname] = [...payload.data];
      }
    } else {
      if (dssets[payload.dsname]) {
        dssets[payload.dsname] = [payload.data, ...dssets[payload.dsname]];
      } else {
        if (payload.data) {
          dssets[payload.dsname] = [payload.data];
        } else {
          dssets[payload.dsname] = [];
        }
      }
    }

    patchState({
      dssets: dssets, // { ...dssets}
    });
  }

  @Action(MutateStateByFunction)
  mutateStateByFunction({ getState, patchState }: StateContext<IsApplicationState>, { fc }: MutateStateByFunction) {
    const state = getState();

    patchState(fc(state));
  }

  @Action(IsMessage)
  IsMessage({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: IsMessage) {
    const state = getState();
    patchState({
      messages: null,
    });
    const state1 = getState();
    patchState({
      messages: { ...payload, counter: this.counter++ },
    });
  }
 

  @Action(PreviewReport)
  Previewreport({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: PreviewReport) {
        
    return this.ic.reportpreview(payload).pipe(
      tap((data) => {
        const { reportcode,base64 } = data;
        if (data) {
          //const b64 = !base64?'base64=false':'base64=true'
          const b64 = 'base64=true'
          window.open(
            location.protocol + '//' + location.host + '/form/32082?reportcode=' + reportcode+'&'+b64,
            'Controle_' + reportcode,
            "name='test',location=0,menubar=0,status=1,scrollbars=1,width=800,height=1000"
          );
        }
      })
    );
  }

  @Action(IsMessageHandled)
  IsMessageHandled({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: IsMessageHandled) {
    const state = getState();

    const messages = state.messages;

    messages[payload.id];

    patchState({
      messages: messages,
    });
  }

  async storeDatasetLocal(datasetname: string, definition: any, records: any[]) {
    //try {
    await db.datasets.put({
      name: datasetname,
      definition: definition,
      records: records,
    });
    //} catch (error) {
    //  console.log('fout schrijven cache ', datasetname);
    //}
  }

  putRecordsinState(payload, patchState, state, data) {
    if (data) {
      if (Array.isArray(data)) {
        const dssets = state.dssets;

        const definition = payload.definition ? data.shift() : null;

        let datadefs = state.datadefs;
        if (definition) {
          const datadef = datadefs.get(payload.dsname);
          if (datadef == null) {
            datadefs.set(payload.alias ? payload.alias : payload.dsname, definition);
          } else {
            datadefs = new Map<string, any>(datadefs);
            datadefs.set(payload.alias ? payload.alias : payload.dsname, definition);
          }
        }

        //const alias = payload.alias ? payload.alias : payload.dsname;
        const alias = payload.alias ? payload.alias : payload.dsname;

        if (payload.transformtodict){
          const dict = {};
          data.forEach((item ) => {dict[item[payload.transformtodict]] = item})
          dssets[alias+'.dict'] = dict;
        } 
        dssets[alias] = data;

        patchState({
          dssets: dssets,
          datadefs: datadefs,
          datasetloaded: alias,
        });

        if (payload.ondataLoaded) {
          payload.ondataLoaded(data, payload.params, this.store, this.comp, this, payload.component);
        }

        if (payload.storeoffline) {
          const x = this.storeDatasetLocal(alias, definition, data);
        }
      } else {
        if (data['missingParams']) {
          if (payload.fixparams) {
            if (typeof payload.fixparams === 'string') {
              this.store.dispatch({ action: payload['fixparams'], payload: data['missingParams'] });
            } else if (typeof payload.fixparams === 'function') {
              payload.fixparams(data['missingParams']);
            }
          }
        }
      }
    }
  }

  async getdatasetData(payload, patchState, state) {
    const dslocalExists =
      payload.checklocalfirst || !window.navigator.onLine
        ? await db.datasets.get({ name: payload.alias ? payload.alias : payload.dsname })
        : null;

    let account = payload.account ? payload.account : state.account;
    account = !account ? '' : account;
    const alias = payload.alias ? payload.alias : payload.dsname;

    const records = state.dssets[payload.dsname];

    const x = dslocalExists
      ? from(
          db.datasets.get(alias).then((c) => {
            return c?.records;
          })
        )
      : records && payload.reuse
      ? from(records)
      : this.ic.getData(payload.dsname, {
          definition: payload.definition,
          params: payload.params,
          role: payload.role ? payload.role : '1',
          language: 'nl',
          logging: { unimonitor: state.unimonitor },
          account: account,
        });

    x.subscribe((data) => {
      if (data) {        
        this.putRecordsinState(payload, patchState, state, data);
      }
    });
  }
  
  @Action(LoadDataset)
  loadDataset({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: LoadDataset) {
    const state = getState();
    this.getdatasetData(payload, patchState, state);   
  }

  @Action(LoadTreeDataset)
  loadTreeDataset({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: LoadTreeDataset) {
    const state = getState();
    this.getdatasetData(payload, patchState, state);   
  }

  @Action(LoadDatasets)
  loadDatasets({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: LoadDatasets) {
    const state = getState();
    //let account = payload.account ? payload.account : state.account;
    //account = !account ? '' : account;
    const dssets = state.dssets;
    let teller = 0;
    return this.ic.getDatasets(payload).pipe(
      tap((data) => {
        if (data) {
          if (Array.isArray(data)) {
            data.forEach((item) => {
              // console.log(item);
              dssets[payload.actions[teller++].name] = item;
            });
            patchState({
              //datasets: datasets,
              dssets: dssets,
            });
          }
        }
      })
    );
  }

  @Action(AddRecord)
  addRecord({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: AddRecord) {
    const state = getState();
    const dsname = payload.dsname;
    const datasets = state.dssets;
    const dataset = datasets[dsname];

    if (dataset) {
      //dataset.push(payload.data);
      datasets[dsname] = [...dataset,payload.data];
      patchState({
        dssets: datasets,
      });
    }
  }

  @Action(UpdateRecord)
  updateRecord({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: UpdateRecord) {
    const state = getState();
    const dsname = payload.dsname;
    const datasets = state.dssets;
    const dataset = datasets[dsname];

    if (dataset) {
      const idx = dataset.findIndex((item) => item[payload.keyfield] === payload.key);
      dataset.splice(idx, 1, payload.data);
      datasets[dsname] = [...dataset];
      
      patchState({
        dssets: datasets,
      });
    }
  }

  @Action(UpdateRecordByKey)
  updateRecordByPrimaryKey({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: UpdateRecordByKey) {
    const { dssets } = getState();
    dssets[payload.dsname][payload.key] = { ...dssets[payload.dsname][payload.key], ...payload.data }; 
    patchState({
      dssets
    });
  }

  @Action(DeleteRecord)
  deleteRecord({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: DeleteRecord) {
    const state = getState();
    const dsname = payload.dsname;
    const datasets = state.dssets;
    const dataset = datasets.get(dsname);

    if (dataset) {
      const idx = dataset.findIndex((item) => item[payload.keyfield] === payload.key);
      dataset.splice(idx, 1);
      datasets[dsname] = [...dataset];
      patchState({
        dssets: datasets,
      });
    }
  }

  @Action(LoadModule)
  loadModule({ getState }: StateContext<IsApplicationStateModel>, { payload }: LoadModule) {
    const state = getState();
    if (payload) {
      this.store.dispatch(
        new LoadComponent({ id: payload.id, name: payload.name, outlet: payload.outlet, account: '10' })
      );
    }
  }

  @Action(LogState)
  logState({ getState }) {
    const state = getState();
    console.log(state);
    console.log(this.store['_stateStream']['_value']);
  }

  @Action(GetMaxComponentNumber)
  getmaxComponentNumber({ getState, patchState }: StateContext<IsApplicationStateModel>) {
    const state = getState();
    let maxid: number;
    return this.ic
      .getData('getmaxcomponentnumber', { role: '1', language: 'nl', logging: { unimonitor: state.unimonitor } })
      .pipe(
        tap((tree) => {
          //let data = JSON.parse(widgets);
          tree.map((a) => {
            maxid = a.maxid;
          });
          patchState({ newcompid: maxid });
        })
      );
  }

  datakey(action: string): string {
    const now = new Date();
    return (
      action +
      '.loaddata' +
      now.getFullYear() +
      now.getMonth() +
      now.getDay() +
      '_' +
      now.getHours() +
      now.getMinutes() +
      now.getSeconds() +
      now.getMilliseconds()
    );
  }

  @Action(LoadData)
  loadData({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: LoadData) {
    const state = getState();
    const tmp: IsData[] = state.data;
    const key = this.datakey(payload.dsname);
    tmp[key] = payload;
    patchState({ data: tmp });
    this.store.dispatch(new DataLoadedAction(key));
  }

  @Action(FetchData)
  fetchData({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: FetchData) {
    const state = getState();
    const tmp: IsData[] = state.data;
    const key = this.datakey(payload);
    tmp[key] = payload;
    patchState({ data: tmp });
  }

  @Action(RegisterOutlet)
  registeroutlet({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: RegisterOutlet) {
    const state = getState();

    const outlets = state.outlets;
    if (!outlets[payload.outlet]) {
      outlets[payload.outlet] = {
        id: payload.outlet,
        name: payload.name,
        editable: payload.editable,
        updatecounter: 0,
        defaultview: payload.defaultview,
        selectedview: null,
        rendercomponent: payload.rendercomponent,
        views: [],
        formgroups: [],
      };
      patchState({ outlets: outlets });
    }
  }

  // @Action(SetFormGroupOutlet)
  // setFormBuilderOutlet(
  //   { getState, patchState }: StateContext<IsApplicationStateModel>,
  //   { payload }: SetFormGroupOutlet
  // ) {
  //   const state = getState();
  //   const outlets = state.outlets;
  //   const outlet = outlets[payload.outlet];
  //   outlet.formgroups[payload.formid] = new FormGroup({});
  //   outlets[payload.outlet] = outlet;
  //   patchState({
  //     outlets: outlets,
  //   });
  // }

  @Action(SetOutlet)
  setoutlet({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: SetOutlet) {
    const state = getState();
    //console.log('set outlet ; ', payload);

    let outlets = state.outlets;
    const outlet = Object.assign({}, outlets[payload.outlet]);
    if (outlet) {
      outlet.selectedview = payload.id;
      const x = function (element) {
        return element.id === outlet.selectedview;
      };
      if (outlet.views) {
        if (!outlet.views.find(x)) {
          outlet.views.push(payload.id);
        } else {
          outlet.views.push(payload.id);
        }
      } else {
        outlet.views = [];
        outlet.views.push(payload.id);
      }
      outlets[payload.outlet] = outlet;
      outlets = Object.assign({}, outlets);
      patchState({ outlets: outlets });
    }
  }

  convertjson(obj) {
    if (obj.config) {
      obj.config = JSON.parse(obj.config);
    }
    if (obj.payload) {
      obj.payload = JSON.parse(obj.payload);
    }
    if (obj.children) {
      (obj.children as Array<any>).map((a) => {
        this.convertjson(a);
      });
    }

    return obj;
  }

  @Action(LoadDataTree)
  loadDataTree({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: LoadDataTree) {
    const state = getState();

    if (payload) {
      if (!state.datatree[payload.dsname]) {
        if (!payload.params) {
          payload.params = {};
        }
        payload.params.logging = { unimonitor: state.unimonitor };
        payload.params.language = 'nl';
        payload.params.role = '1';
        return this.ic.getData(payload.dsname, payload.params).pipe(
          tap((tree) => {
            const d = state.datatree;
            d[payload.dsname] = tree;
            patchState({
              datatree: d,
            });
            if (payload.action) {
              //this.store.dispatch()
            }
          })
        );
      }
    }
  }

  @Action(SetConfig)
  setConfig({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: SetConfig) {
    const state = getState();
    if (payload) {
      let name = '';
      if (!payload.name) {
        name = new Date().getUTCMilliseconds().toString();
      } else {
        name = payload.name;
      }
      if (!state.config[name]) {
        if (!payload.config) {
          payload.config = {};
        }
        const c = state.config;
        c[name] = payload.config;
        patchState({
          config: c,
        });
        if (!payload.name) {
        }
      }
    }
  }

  @Action(SelectTreeNode)
  selectTreeNode({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: SelectTreeNode) {
    const state = getState();

    if (payload) {
      if (!state.datatree[payload.dsname]) {
        payload.payload.params.logging = { unimonitor: state.unimonitor };
        return this.ic.getData(payload.dsname, payload.payload.params).pipe(
          tap((tree) => {
            //let data = JSON.parse(widgets);
            const d = state.datatree;
            d[payload.dsname] = tree;
            patchState({
              datatree: d,
            });
          })
        );
      }
    }
  }

  objecttoproparray(config, proparray) {
    const data = [];
    for (const [key, value] of Object.entries(config)) {
      if (value && key !== 'events') {
        if (typeof value === 'object') {
          {
            const prop: any = { id: this.teller++, name: key };
            prop.children = [];
            this.objecttoproparray(value, prop.children);
            proparray.push(prop);
          }
        } else {
          proparray.push({ id: this.teller++, name: key, value: value });
        }
      } else {
        proparray.push({ id: this.teller++, name: key, value: value });
      }
    }
    return data;
  }

  objecttoeventarray(config) {
    const data = [];
    for (const [, value] of Object.entries(config)) {
      if (typeof value === 'object') {
        data.push(value);
      }
    }
    return data;
  }

  findIdx(tree, id, checkcmp = 0) {
    let temp;
    if (tree.id === id) {
      return tree;
    }
    if (tree.children) {
      for (const child of tree.children) {
        temp = this.findIdx(child, id, checkcmp);
        if (temp) {
          return temp;
        }
      }
    }
  }

  checkParams(params: any, sql: string, name?: string, id?: number): boolean {
    const regExp = new RegExp(`[:?]([a-zA-Z])+`, 'gmi');
    const sqlparams = sql?.match(regExp)?.filter((v, i, a) => a.indexOf(v) === i);
    if (Object.entries(params)?.length < sqlparams?.length) {
      this.store.dispatch(
        new AppError({
          source: name + '-' + id.toString(),
          code: 1,
          level: 'warning',
          message: `no parameters expected ${sqlparams.length} parameters found ${sql}`,
        })
      );
      return false;
    }

    sqlparams?.forEach((param) => {
      if (!(param.slice(1) in params)) {
        this.store.dispatch(
          new AppError({
            source: name + '-' + id.toString(),
            code: 1,
            level: 'warning',
            message: 'parameter missing' + param.substring(1),
          })
        );
        return false;
      }
    });

    return Object.values(params).every((x) => x !== null && x !== '');
  }

  @Action(GetEventListners)
  getEventListners({ getState, patchState }: StateContext<IsApplicationStateModel>) {
    const elements = [];
    const allElements = document.querySelectorAll('*');
    const types = [];
    for (const ev in window) {
      if (/^on/.test(ev)) types[types.length] = ev;
    }

    for (let i = 0; i < allElements.length; i++) {
      const currentElement = allElements[i];
      for (let j = 0; j < types.length; j++) {
        if (typeof currentElement[types[j]] === 'function') {
          elements.push({
            node: currentElement,
            listeners: [
              {
                type: types[j],
                func: currentElement[types[j]].toString(),
              },
            ],
          });
        }
      }
    }

    patchState({
      eventstest: elements.filter((element) => element.listeners.length),
    });
  }

  @Action(ExecQry)
  execQry({ getState }: StateContext<IsApplicationStateModel>, { account, payload }: ExecQry) {
    const state = getState();
    return this.ic
      .writeData('execqry', {
        account: account,
        data: payload,
        role: '1',
        language: 'nl',
        batch: payload.batch ? payload.batch : false,
        logging: { unimonitor: state.unimonitor },
      },null,false)
      .pipe(tap(() => {}));
  }

  @Action(StoreInOfflineCache)
  storeInOfflineCache({ typeAction, payload }: StoreInOfflineCache) {
    let offlinecache = JSON.parse(localStorage.getItem('offlinecache')); //state.offlinecache;
    if (Array.isArray(offlinecache)) {
      offlinecache.push({ type: typeAction, payload: payload });
    } else {
      offlinecache = [{ type: typeAction, payload: payload }];
    }
    localStorage.setItem('offlinecache', JSON.stringify(offlinecache));
  }

  processScreenUpdates(payload) {
    if (payload.ismessage) {
      this.store.dispatch({
        type: '[iMessage]',
        payload: {
          name: payload.ismessage.name,
          id: payload.ismessage.id,
          isessioncode: payload.ismessage.sessioncode,
          sender: payload.data,
          responder: [payload.backupresponder],
        },
      });
    }
  }

  processOffline(payload, state) {
    /* mimic websocket message to update interface in case of error
       as if broadcast was succesfull
       the backupresponer contains info to mimic what would have been received by websocket
       sent from succesfull operation
    */
    this.processScreenUpdates(payload);
    this.storeInOfflineCache({ typeAction: '[Execute]', payload: payload });
  }

  @Action(UpdateLocalCache)
  updateLocalCache({ getState }: StateContext<IsApplicationStateModel>, { payload }: UpdateLocalCache) {
    const state = getState();
    const data = state.dssets[payload];
    const x = this.storeDatasetLocal(payload, null, data);
  }

  @Action(Execute)
  execute({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: Execute) {
    const state = getState();
    const messages = state.sessionmessages;
    //messages.set('1', payload);

    if (payload.ismessage) {
      const issessioncode = this.store.selectSnapshot((state) => state['iscloudstate'].isessioncode);
      payload.ismessage = { ...payload.ismessage, issessioncode };
    }

    if (!window.navigator.onLine) {
      this.processOffline(payload, state);
    } else {
      return this.ic
        .writeData(
          payload.command,
          {
            data: payload.data,
            role: '1',
            language: 'nl',
            ismessage: payload.ismessage,
            batch: payload.batch ? payload.batch : false,
            logging: { unimonitor: true /*state.unimonitor*/ },
            offline: payload.offline ? payload.offline : false,
          },
          null,
          payload.usepayload != null ? payload.usepayload : false
        )
        .pipe(
          tap((data) => {
            payload['result'] = data;

            patchState({
              sessionmessages: messages,
            });

            if (data['success'] == true) {
              if (payload.validated) {
                payload.validated(data['result'], payload.data, this.comp, this.outlet, this.store, payload.sender);
              }
              if (payload.backupresponder && payload.ismessage) {
                this.processScreenUpdates(payload);
              }
            }
          }),
          catchError((error: any) => {
            console.log(error);
            if (error.status == 0) {
              this.processOffline(payload, state);
              return of('Network Offline');
            } else {              
              if (payload.errorfunction){
                payload.errorfunction(error);
              } else
              if (!payload.fromerror) {
                this.store.dispatch(
                  new AppError({
                    code: error.status,
                    source: 'appstate.execute',
                    message: error?.message + ' ' + error?.error,
                    level: 'error',
                  })
                );
                if (payload.retrycount < 10) {
                  payload.retrycount ? payload.retrycount++ : (payload.retrycount = 1);
                  this.store.dispatch({ type: '[Execute]', payload: payload });
                } else {
                  this.store.dispatch({
                    type: '[iMessage]',
                    payload: { name: 'ConnectionError', message: error?.message + ' ' + error?.error },
                  });
                }
              }
              return of('from CatchError');
            }
          })
        );
    }
  }

  @Action(ExecuteAndRead)
  executeandread({ getState, patchState }: StateContext<IsApplicationStateModel>, { payload }: ExecuteAndRead) {
    const state = getState();
    const messages = state.sessionmessages;
    messages.set('1', payload);

    return this.ic
      .getData(payload.command, {
        data: payload.data,
        role: '1',
        language: 'nl',
        logging: { unimonitor: true /*state.unimonitor*/ },
      })
      .pipe(
        tap((data) => {
          payload['result'] = data;
          messages.set('1', payload);
          patchState({
            sessionmessages: messages,
          });
          if (data['succes'] == true) {
            if (payload.validated) {
              payload.validated();
            }
          }
        })
      );
  }

  @Action(ExecCommand)
  execCommand({ getState }: StateContext<IsApplicationStateModel>, { payload }: ExecCommand) {
    const state = getState();
    const account = payload.account ? payload.account : state.account;

    return this.ic
      .execute(payload.name, {
        action: payload.action,
        account: account,
        role: '1',
        language: 'nl',
        command: payload.command ?? payload.command,
        logging: { unimonitor: true /*state.unimonitor*/ },
      })
      .pipe(
        tap((data) => {
          console.log('ExecCommand : ', data);
          //payload['result'] = data;
          //messages.set('1', payload);
          //patchState({
          //  sessionmessages: messages,
          //});

          //if (data['succes'] == true) {
          //if (payload.validated) {
          //  payload.validated();
          //}
          //}
        })
      );
  }

  @Action(LoggingonServer)
  logonServer({ getState }: StateContext<IsApplicationStateModel>, { payload }: LoggingonServer) {
    return this.ic
      .execute('logserver', {
        action: 'logserver',
        account: 10,
        role: '1',
        language: 'nl',
        message: payload,
        datetime: this.comp.getCurrentStrDate() + ' ' + this.comp.hourstoHMS(new Date().getTime()),
        logging: {
          unimonitor: false,
        },
      })
      .pipe(
        tap((data) => {
          console.log('logserver : ', data);
        })
      );
  }
}
