<span class="year-header-container">
  <span class="material-icons button" (click)="changeYear(-1)"
    >chevron_left</span
  >
  <span class="year-header"> Jaar: {{ year }} </span>
  <span class="material-icons button" (click)="changeYear(1)"
    >chevron_right</span
  >
</span>
<ismonth2
  (daySelect)="daySelect($event)"
  (daysDetermined)="daysDetermined($event)"
  (init)="initmonth($event)"
  *ngFor="let m of months"
  [month]="m"
  [year]="year"
>
</ismonth2>
