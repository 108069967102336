import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { ConfigService } from '@ic-builder/data-access-iscloud';

@Component({
  selector: 'ic-builder-isimage',
  templateUrl: './isimage.component.html',
  styleUrls: ['./isimage.component.scss'],
})
export class IsimageComponent implements OnInit {
  @Input() url: string;
  @Input() fromdb = false;
  @Input() scale = 1;
  img = new Image();
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  data: any;
  width_ratio: number;
  height_ratio: number;

  constructor(private config:ConfigService,private el: ElementRef) {}

  ngOnInit(): void {
    this.canvas = this.el.nativeElement.querySelector('canvas');
    this.context = this.canvas.getContext('2d');

    if (this.fromdb) {
      if (this.data) {
        this.img.src = this.data[this.url];
        this.afterInsertInDom();
      }
    } else {
      this.img.src = this.config.getDbUrl('') + this.url;
      this.afterInsertInDom();
    }
  }

  afterInsertInDom() {
    this.img.onload = () => {
      this.setImage();
      //this.initResizeObserver();
    };
  }

  initResizeObserver() {
    const resizeObserver = new ResizeObserver((entries) => {
      this.setImage();
    });

    resizeObserver.observe(this.el.nativeElement);
  }

  setImage() {
    this.canvas.width = this.el.nativeElement.offsetWidth;
    //this.canvas.height = this.el.nativeElement.offsetHeight;
    //const imgratio = this.img.height / this.img.width;
    if (this.width_ratio) {
      this.canvas.height = this.width_ratio * this.img.height;
      this.context.clearRect(
        0,
        0,
        this.width_ratio * this.img.width,
        this.width_ratio * this.img.height
      );
      this.context.drawImage(
        this.img,
        0,
        0,
        this.width_ratio * this.img.width,
        this.width_ratio * this.img.height
      );
    } else if (this.height_ratio) {
      this.canvas.height = this.height_ratio * this.img.height;
      this.context.clearRect(
        0,
        0,
        this.height_ratio * this.img.width,
        this.height_ratio * this.img.height
      );
      this.context.drawImage(
        this.img,
        0,
        0,
        this.height_ratio * this.img.width,
        this.height_ratio * this.img.height
      );
    } else {
      const scaleratio = this.el.nativeElement.offsetWidth / this.img.width;
      this.canvas.height = scaleratio * this.img.height;
      this.context.clearRect(
        0,
        0,
        scaleratio * this.img.width,
        scaleratio * this.img.height
      );
      this.context.drawImage(
        this.img,
        0,
        0,
        scaleratio * this.img.width,
        scaleratio * this.img.height
      );
    }
  }
}
