import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentsService, Outlet2Service, IscontrolComponent } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ic-builder-isfrequention',
  templateUrl: './isfrequention.component.html',
  styleUrls: ['./isfrequention.component.scss']
})
export class IsfrequentionComponent extends IscontrolComponent implements OnInit {

  @Input('mode') mode: 'days' | 'weeks' | 'months' = 'months';
  
  formControl: FormControl | null = null;

  initialValue = '';
  disabled: boolean = false;

  controlid: string;

  AMOUNT_OF_CHARACTERS = {
    'days': 7,
    'weeks': 52,
    'months': 12,
  }

  constructor(
    public outlet: Outlet2Service,
    public compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(compserv, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {

    if (!['days', 'weeks', 'months'].includes(this.mode)) {
      throw new Error(
        `Mode should be any of the values 'days', 'weeks' or 'months'. Not the value ${this.mode} `
      );
    }

    for (let i = 0; i < this.AMOUNT_OF_CHARACTERS[this.mode]; i += 1) {
      this.initialValue += '0';
    }

    this.controlid = super.initControl(this.id.toString() + '.frequention', this.initialValue, 'frequention', null, this.disabled);
    this.formControl = super.getFormControl(this.controlid);
  }
}
