import {
  AfterViewInit,
  Component,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { ComponentsService } from '@ic-builder/is-base';

@Component({
  selector: 'isdialog',
  templateUrl: './isdialog.component.html',
  styleUrls: ['./isdialog.component.scss'],
})
export class IsdialogComponent implements OnInit, AfterViewInit {
  activeDialog: MatDialogRef<any>;

  @Input('data') data: any[];
  @Input('outlet') outlet: number;
  @Input('height') height: string;
  @Input('id') id: number;
  @Input('formid') formid: number;

  @ViewChild('dia', { read: TemplateRef }) dia: TemplateRef<any>;
  dialogConfig: any;
  sub: Subscription;
  loadas: number;
  renderloadasimmediate: boolean;

  constructor(
    private compservice: ComponentsService,
    private dialog: MatDialog,
    private store: Store,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.dialogConfig = {
      //position:{'top':'40vh','left':'25vw'},
      data: this.data,
    };
    if (typeof this.formid === 'string') {
      this.formid = parseInt(this.formid);
    }
    if (typeof this.loadas === 'string') {
      this.loadas = parseInt(this.loadas);
    }
  }

  ngAfterViewInit() {
    const formgroup = this.compservice.formgroup.get(this.formid);
    if (formgroup) {
      for (const key of Object.keys(formgroup.controls)) {
        formgroup.controls[key].setValue(this.data[key]);
      }
    }
    this.activeDialog = this.dialog.open(this.dia, this.dialogConfig);
  }

  dialogTemplateLoaded(ev) {
    console.log('outlet form loaded', ev);
  }

  ngDestroy() {
    this.compservice.removeformgroup(this.formid);
    this.sub.unsubscribe();
  }

  close() {
    this.ngZone.run(() => {
      this.activeDialog.close();
      //this.store.dispatch(new RemoveComponent({id:35001}))
    });
  }

  cancel() {
    this.ngZone.run(() => {
      this.activeDialog.close();
      //this.store.dispatch(new RemoveComponent({id:35001}))
    });
  }
}
