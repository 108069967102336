import { formatDate } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentsService } from '@ic-builder/is-base';

export const DATE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateValueAccessor),
  multi: true,
};

/**
 * The accessor for writing a value and listening to changes on a date input element
 *
 * ### Example
 * `<input name="myBirthday" type="date" />`
 */
@Directive({
  selector: '[useValueAsDate]',
  providers: [DATE_VALUE_ACCESSOR],
})
export class DateValueAccessor implements ControlValueAccessor {
  // @HostListener('input', ['$event.target.valueAsDate']) onChange = (_: any) => {
  //   console.log('write change value ');
  // };
  @HostListener('input', ['$event.target.valueAsDate']) onInput = (date?: Date) => {
    this.setreturnvalue(date)
  };
  onChange: any = () => {};

  @HostListener('blur', []) onTouched = () => {};
  @Input() format = 'mm/dd/yyyy';
  @Input() returnformat = 'mm/dd/yyyy';

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef, private comp:ComponentsService) {}

  setreturnvalue(date){
    let localdate = this.comp.setdatetoformat(date,this.returnformat);
    
    this.onChange(localdate)      
  }

  writeValue(value): void {
    if (typeof value == 'string') {
      const d = this.comp.setdatetoformat(value,this.format,this.format);
      d.setDate(d.getDate()+1);
      this._renderer.setProperty(this._elementRef.nativeElement, 'valueAsDate', d);
      d.setDate(d.getDate()-1);
      this.setreturnvalue(d);
    } else {
      if (value) {
        const d = (value.getMonth() + 1).toString() + '/' + value.getDate().toString() + '/' + value.getYear();
        this._renderer.setAttribute(this._elementRef.nativeElement, 'value', d);
      } else
      {
        this._renderer.setProperty(this._elementRef.nativeElement, 'valueAsDate', null);
      }
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }
}
