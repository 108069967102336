import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { ComponentsService } from '@ic-builder/is-base';

@Component({
  selector: 'isbutton',
  templateUrl: './isbutton.component.html',
  styleUrls: ['./isbutton.component.scss'],
})
export class IsbuttonComponent implements OnInit {
  //button:any = {icon:'',text:''};
  @Input() icon: any;
  @Input() text: any;
  version: number;
  translation: any = null;
  //id:number;
  informbuilder = false;

  @Input() action: any;
  @Input() config: any;
  @Input() cfg: any;

  @ViewChild('mat-icon', { read: ComponentRef }) iconRef: ComponentRef<any>;
  iMessage: any;

  constructor(
    private comp: ComponentsService,
    public store: Store,
    //public outlet: Outlet2Service,
    //public NgZone: NgZone,
    //public dialog: MatDialog,
    private el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    //super(compservice, store, outlet, NgZone, dialog, el, cd);
  }

  @HostListener('click')
  click(ev) {
    if (!this.informbuilder) {
      //console.log('test');
      if (this.iMessage) {
        this.store.dispatch({ type: '[iMessage]', payload: this.iMessage });
      }
      if (this.action) {
        console.log(ev);
        this.store.dispatch({ type: this.action.name, payload: this.action.payload });
      } else if (this.config) {
        if (this.config.action) {
          this.store.dispatch({ type: this.config.action.name, payload: this.config.action.payload });
        }
      }
    }
  }

  translate(lang){    
    if (this.translation){
      this.text = this.translation[lang];
    }
  }

  ngOnInit(): void {
    if (this.cfg) {
      if (!this.version) {
        this.setProp(this, JSON.parse(this.cfg));
      } else {
        this.config = Object.assign({}, JSON.parse(this.cfg));
      }
    }
    if (this.config) {
      for (const key of Object.keys(this.config)) {
        //    this.button[key] = this.config[key];
      }
    }
    if (this.icon?.includes('mdi')) {
      this.el.nativeElement.classList.add('mdi');
      this.el.nativeElement.classList.add(this.icon);
      this.el.nativeElement.innerHTML = '';
    }
    if (this.iconRef && (this.icon = '')) {
      this.icon = null;
    }
  }

  disable() {
    this.el.nativeElement.setAttribute('disabled');
  }

  enable() {
    this.el.nativeElement.removeAttribute('disabled');
  }

  setProp(cmp, cfg) {
    for (const [key, value] of Object.entries(cfg)) {
      if (value) {
        if (typeof value === 'string' || typeof value === 'number') {
          cmp[key] = value;
        }
        if (typeof value === 'object') {
          {
            cmp[key] = Object.assign({}, value);
          }
        }
      }
    }
  }
}
