import { LoadEnumlist } from './../../../isenum/enum.action';
import { EnumerationItem } from './../../../isenum/enum.model';
import { EnumState } from './../../../isenum/enum.state';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Component, HostListener, OnInit, ElementRef } from '@angular/core';
import { Enumeration } from '@ic-builder/iscomp';
import { Store } from '@ngxs/store';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'ic-builder-editenum',
  templateUrl: './editenum.component.html',
  styleUrls: ['./editenum.component.scss'],
})
export class EditenumComponent implements OnInit {
  @HostListener('focus')
  focus() {
    let input = this.el.nativeElement.querySelector('input');
    input.focus();
  }

  resetValue() {
    this.formControl.setValue('');
  }

  constructor(
    private el: ElementRef,
    private dialog: MatDialog,
    private store: Store
  ) {}

  initialValue: string = '';
  formControl: FormControl;

  data: Enumeration[] = [];
  enum: string = '';

  enumerationlist$: Observable<EnumerationItem[]>;

  ngOnInit(): void {
    this.formControl = new FormControl(this.initialValue);

    if (this.enum) {
      this.store.dispatch(new LoadEnumlist(this.enum, null, null));
    }

    this.enumerationlist$ = this.store.select(EnumState.getenumlist).pipe(
      map((filterFn) => {
        return filterFn(this.enum);
      })
    );

    this.enumerationlist$.subscribe((e) => console.log('enumeration', e));
  }
}
