import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'iscustomradio',
  templateUrl: './iscustomradio.component.html',
  styleUrls: ['./iscustomradio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IscustomradioComponent),
      multi: true,
    },
  ],
})
export class IscustomradioComponent
  implements ControlValueAccessor, AfterViewInit
{
  //control: FormControl = new FormControl(false);
  get value(): any | null {
    return this._value;
  }

  private _value: any | null = null;

  // @HostListener('click',['$event'])
  // click(ev:any){
  //   this.writeValue(!this._value);
  // }

  private _options: any[] = [];
  private _selectedclass = 'selected';

  @Input('options') set options(val: any[]) {
    this._options = val;
  }

  get options() {
    return this._options;
  }

  @Input('disabled') disabled = false;
  @Input('usecheckmarks') usecheckmarks = false;
  @Input('selectedclass') set selectedclass(val:string) {
    this._selectedclass = val;
  }  
  
  @Input('chackmarkclass') checkmarkclass = 'check';

  @HostBinding('style.opacity')
  get opacity() {
    return this.disabled ? 0.25 : 1;
  }
  classdefs:any;

  constructor(private cd: ChangeDetectorRef, private el: ElementRef) {}

  // ControlValueAccessor implementation
  _onChange: (_: any) => void = (_) => {};

  _onTouched = () => {};

  writeValue(value: any, byClick: boolean = false): void {
    if (!this.disabled) {
      if (
        (value === null || value === undefined) &&
        (this._value === null || this._value === undefined)
      )
        return;
      if (value === this._value && byClick) {
        value = null;
      }

      const prev = this.el.nativeElement.querySelector(`#val${this._value}`);
      const next = this.el.nativeElement.querySelector(`#val${value}`);

      prev?.classList.remove(this._selectedclass);
      if (this.usecheckmarks) {
        //prev?.classList.remove('check');
        const prevcheck = prev?.children[0];
        prevcheck?.classList.remove('check');
      }
      next?.classList.add(this._selectedclass);
      if (this.usecheckmarks) {
        const nextcheck = next?.children[0];
        nextcheck?.classList.add('check');
      }

      this.cd.detectChanges();

      this._onChange(value);
      this._value = value;
    }
  }

  ngAfterViewInit(): void {
    const cur = this.el.nativeElement.querySelector(`#val${this._value}`);

    cur?.classList.add('selected');
    if (this.usecheckmarks) {
      const curcheck = cur?.children[0];
      curcheck?.classList.add('check');
    }

    // if (this.usecheckmarks) {
    //   const items = this.el.nativeElement.children;
    //   for (let i = 0; i < items.length; i++) {
    //     items[i].classList.add('checkmarks');
    //   }
    // }
    this.el.nativeElement.setAttribute('istag', 'custom-radio');
  }

 

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
