import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ic-builder-menuhandler3',
  templateUrl: './menuhandler3.component.html',
  styleUrls: ['./menuhandler3.component.scss'],
})
export class Menuhandler3Component implements AfterViewInit {
  @ViewChildren('children', { read: ViewContainerRef }) public children: QueryList<ViewContainerRef>;

  constructor(private comp: ComponentsService, private el: ElementRef, private store: Store) {}

  renderDirection = 'vertical';

  id: number;
 
  ngAfterViewInit(): void {}

  afterformReady() {
    if (this.renderDirection == 'vertical') {
      this.el.nativeElement.style['grid-auto-flow'] = 'row';
    }

    let hasIcon = false;
    let hasChildren = false;

    const children = this.comp.widgets.get(this.id)['ischildren'] ? this.comp.widgets.get(this.id)['ischildren'] : [];

    children.map((c) => {
      c.instance.renderDirection = this.renderDirection;

      if (c.instance.icon) {
        hasIcon = true;
      }

      if (this.comp.widgets.get(c.instance.id)['ischildren']?.length) {
        hasChildren = true;
      }
    });

    let template = '1fr';

    if (hasIcon) {
      template = '25px ' + template;
    } else {
      template = '0px ' + template;
    }

    if (hasChildren) {
      template = template + ' 25px';
    } else {
      template = template + ' 0px';
    }

    children.map((c) => {
      c.location.nativeElement.style['grid-template-columns'] = template;
    });
  }

  info: any;

  setInfo(p1, p2) {
    this.info = p1;
    const children = this.comp.widgets.get(this.id)['ischildren'] ? this.comp.widgets.get(this.id)['ischildren'] : [];

    children?.forEach((c) => {
      c.instance.setInfo(p1, p2);
    });

    if (this['_setInfo']) this['_setInfo'](p1);
  }

 
}
