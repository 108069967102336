import { map, startWith } from 'rxjs/operators';
import { EnumState } from '../isenum/enum.state';
import { Observable } from 'rxjs';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EnumerationItem } from '../isenum/enum.model';
import { Store } from '@ngxs/store';
import { SelectionGridComponent } from '../selection-grid/selection-grid.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentsService } from '@ic-builder/is-base';
import { LoadEnumlist } from '../isenum/enum.action';

@Component({
  selector: 'isinputenumerate',
  templateUrl: './isinputenumerate.component.html',
  styleUrls: ['./isinputenumerate.component.scss'],
})
export class IsinputenumerateComponent implements OnInit, AfterViewInit {
  config: any = {};

  @Input('placeHolder') placeHolder: string;
  @Input('list') list: string;
  @Input('id') id: string;
  @Input('formGroup') formGroup: FormGroup;
  @Input('data') data: any;
  @Output() isValueChange = new EventEmitter<number>();

  PlaceholderId: string;
  idnr: string;
  filteredOptions: Observable<string[]>;
  enumerationlist$: Observable<EnumerationItem[]>;
  enumerationlist: EnumerationItem[];
  selectednr: number;
  popupRef: MatDialogRef<SelectionGridComponent>;
  stateselector: string;
  parentid: number;
  field: string;

  constructor(
    private compservice: ComponentsService,
    private store: Store,
    private cd: ChangeDetectorRef,
    private dialogRefCom: MatDialog
  ) {}

  ngOnInit() {
    this.store.dispatch(new LoadEnumlist(this.list));
    this.stateselector = 'wizardstate';
    this.PlaceholderId = this.placeHolder + 'id';
    this.formGroup = this.compservice.formgroup.get(this.parentid);
    if (this.formGroup) {
      if (this.data) {
        if (this.data[this.field]) {
          this.formGroup.addControl(
            this.field,
            new FormControl(this.data[this.field])
          );
        }
      }

      this.idnr = this.field + 'nr';
      //this.formGroup.addControl(this.id, new FormControl({value:''}))
      this.formGroup.addControl(this.idnr, new FormControl(''));

      this.formGroup.get(this.idnr).valueChanges.subscribe((a) => {
        this.selectednr = a;
      });

      if (this.formGroup.get(this.field)) {
        this.filteredOptions = this.formGroup.get(this.field).valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
      }
    }

    this.enumerationlist$ = this.store.select(EnumState.getenumlist).pipe(
      map((filterFn) => {
        return filterFn(this.list);
      })
    );

    this.enumerationlist$.subscribe((a) => {
      this.enumerationlist = a;
    });

    if (this.selectednr !== undefined) {
      this.formGroup.get(this.idnr).setValue(this.selectednr);
      this.isValueChange.emit(this.selectednr);

      this.store
        .select(EnumState.getenumlist)
        .pipe(map((filterFn) => filterFn(this.list)))
        .subscribe((a) => {
          let temp;
          a.map((a) => {
            if (a.id === this.selectednr) {
              temp = a.description;
            }
          });
          this.formGroup.get(this.field).setValue(temp);
        });
    }
  }

  private _filter(value: string): string[] {
    if (!value && !this.enumerationlist) {
      const filterValue = value.toLowerCase();

      return this.enumerationlist
        .map((a) => a.description)
        .filter((option) => option.toLowerCase().includes(filterValue));
    }
  }

  select(option) {
    this.formGroup.get(this.field).setValue(option);
    this.formGroup
      .get(this.idnr)
      .setValue(
        this.enumerationlist.filter((a) => a.description === option)[0].id
      );
  }

  TabPressed() {
    const temp = this.formGroup.get(this.idnr).value;
    //this.enumerationlist.map(a => console.log(a.id.toString()=== temp))
    if (
      this.enumerationlist.filter((a) => a.id.toString() === temp).length > 0
    ) {
      this.formGroup
        .get(this.id)
        .setValue(
          this.enumerationlist.filter((a) => a.id.toString() === temp)[0]
            .description
        );
      this.selectednr = parseInt(temp);
    } else {
      console.log('No LIST', this.selectednr);
      this.formGroup.get(this.idnr).setValue(this.selectednr);
      this.formGroup.get(this.field).setValue('');
    }
  }

  ngAfterViewInit() {
    /*
    this.store.select(state => state[this.stateselector].object[this.id.split('.')[0]]).subscribe(
      a => {
        if(a){
          this.formGroup.get(this.id).setValue(a)
        } else{
          this.formGroup.get(this.id).setValue('');
        }
          let value = this.enumerationlist.filter(b => b.description.toString() === a);
          if(value.length>0){
            this.formGroup.get(this.idnr).setValue(value[0].id);
          } else{
            this.formGroup.get(this.idnr).setValue('');
          }
      }
    )*/
    //this.cd.detectChanges();
  }

  search() {
    this.popupRef = this.dialogRefCom.open(SelectionGridComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        enumeration: this.list,
        //action: this.action,
        formGroup: this.formGroup,
        idnr: this.idnr,
        id: this.field,
        selectednr: this.selectednr,
      },
    });
  }

  clear() {
    this.formGroup.get(this.field).setValue('');
    this.formGroup.get(this.idnr).setValue('');
  }
}
