import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { StartDragNode, DragNode, EndDragNode } from './istree.action';
import {
  SelectTreeNode,
  LoadDataTree,
} from '../isapplication/isapplication.action';

@Component({
  selector: 'istree',
  templateUrl: './istree.component.html',
  styleUrls: ['./istree.component.scss'],
})
export class IstreeComponent implements OnInit {
  config: any;
  name: string;
  @Input('datatree') datatree: string;
  @Input('clicklog') clicklog = false;
  @Input()
  //@Output() click: EventEmitter<any> = new EventEmitter();
  @Output()
  startdragging: EventEmitter<any> = new EventEmitter();
  @Output() enddragging: EventEmitter<any> = new EventEmitter();
  @Output() dragging: EventEmitter<any> = new EventEmitter();
  @Output() click: EventEmitter<any> = new EventEmitter();
  constructor(private cd: ChangeDetectorRef, private store: Store) {}

  nodes: Observable<any>;

  mode = '';
  editMode = true;
  options = { useVirtualScroll: true, nodeHeight: 22 };

  ngOnInit(): void {
    let state = 'isapplicationstate';
    if (!this.config) {
      this.store
        .select((state) => state[state].config['istree'])
        .subscribe((istree) => {
          this.config = istree;
        });
    }

    if (this.config.state) {
      state = this.config.state;
    }

    if (this.config.tree) {
      this.store.dispatch(
        new LoadDataTree({
          dsname: this.config.tree.dsname,
          params: this.config.tree.params,
        })
      );
      this.store
        .select((state) => state[state].datatree[this.config.tree.dsname])
        .subscribe((tree) => {
          this.nodes = tree;
        });
    }
  }

  dragstart(node, ev) {
    if (this.config.dragstart) {
      this.store.dispatch(new StartDragNode(node.data));
    }
    if (this.startdragging) {
      this.startdragging.emit(node);
    }
  }

  drag(node, ev) {
    if (this.config.dragging) {
      this.store.dispatch(new DragNode(node.data));
    }
    if (this.dragging) {
      this.dragging.emit(node);
    }
  }

  dragend(node, ev) {
    if (this.config.dragend) {
      this.store.dispatch(new EndDragNode(node.data));
    }
    if (this.enddragging) {
      this.enddragging.emit(node);
    }
  }

  onclick(node, ev) {
    if (this.clicklog) {
      console.log(node.data);
    }
    if (this.config.clickaction) {
      this.store.dispatch(new SelectTreeNode(node.data));
    }
    if (node) {
      node.mouseAction('click', ev);
    }
  }
}
