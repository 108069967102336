export function getTreeChild<
  K extends keyof T,
  T extends Record<K, T[K]>,
  C extends keyof T = keyof T,
  I extends keyof T = keyof T
>(
  haystackTree: T,
  config: { idProperty: I; childrenProperty: C },
  needleId: T[I]
): T | undefined {
  const workArray = [haystackTree];
  let item;
  while ((item = workArray.shift()) != null) {
    if (item[config.idProperty] === needleId) {
      return item;
    }
    if (
      Object.prototype.hasOwnProperty.call(item, config.childrenProperty) &&
      Array.isArray(item[config.childrenProperty])
    ) {
      const childrenArray: Array<T> = item[config.childrenProperty];
      workArray.push(...childrenArray);
    }
  }
  return undefined;
}
