import { Component, ElementRef, Input } from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ic-builder-isfileinput',
  templateUrl: './isfileinput.component.html',
  styleUrls: ['./isfileinput.component.scss'],
})
export class IsfileinputComponent {

  @Input() label: string;
  @Input() accept = '*';
  ongetfile: Function = null;

  constructor(
    private el: ElementRef,
    private store: Store,
    private comp: ComponentsService
  ) {}

  getfile(ev) {
    const files = ev.target.files;
    const file = files[0];
    if (this.ongetfile){
      this.ongetfile(file);
    }
  }

}
