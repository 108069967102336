import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  UP_ARROW = 38,
  DOWN_ARROW = 40
}

@Component({
  selector: 'iscustomselect',
  templateUrl: './iscustomselect.component.html',
  styleUrls: ['./iscustomselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IscustomselectComponent),
      multi: true,
    },
  ],
})
export class IscustomselectComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() get options() {
    return this._options;
  }

  set options(v: any[]) {
    
      this._options = v;
      this.writeValue(this.value);
    
  }

  private _options: any[] = [];

  @HostListener('click')
  click() {
    this.open = !this.open;
    this.cd.detectChanges();
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event);

    if (event.keyCode === KEY_CODE.DOWN_ARROW) {
      this.open = true;
      this.cd.detectChanges();
    }

    if (event.keyCode === KEY_CODE.UP_ARROW) {
      this.open = false;
      this.cd.detectChanges();
    }
  }

  control = new FormControl();
  open: boolean = false;
  @Input() valuefield:string='';
  @Input() displayfield:string='description';
  @Output() customselect = new EventEmitter();

  constructor(private cd: ChangeDetectorRef, private el: ElementRef) {}

  private _value: string | null = null;

  get value(): any | null {
    return this._value;
  }

  windowListener = (event: any) => {
    const select = this.el.nativeElement;
    if (!select.contains(event.target)) {
      this.open = false;
      this.cd.detectChanges();
    }
  };

  ngOnInit(): void {
    window.addEventListener('click', this.windowListener);
  }

  ngOnDestroy(): void {
    window.removeEventListener('click', this.windowListener);
  }

  select(value: any): void {
    // set value internally
    // const value = option[this.displayfield] ? option[this.displayfield]:(option[this.valuefield] ?? option);
    //this.control.setValue(option.value ?? option);
    this.writeValue(value);
    // this.customselect.emit(option)
    // let angular know the value has been changed       
    // this._onChange(value);
  }

  // ControlValueAccessor implementation
  _onChange: (_: any) => void = (_) => {};
  _onTouched: () => void = () => {};

  writeValue(value: any): void {

    let description = value;

    if (this.valuefield) {
      const item = (this.options ? this.options : []).find((o) => o[this.valuefield] === value);
      description = item ? item[this.displayfield] : null;
    }

    //const value = option[this.displayfield] ? option[this.displayfield]:(option[this.valuefield] ?? option);

    this.control.setValue(description);

    this._onChange(value);
    this._onTouched();

    this._value = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
    //
  }
}
