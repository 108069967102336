import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Month } from '../isyearplanner2.component';

@Component({
  selector: 'ismonth2',
  templateUrl: './ismonth2.component.html',
  styleUrls: ['./ismonth2.component.scss'],
})
export class Ismonth2Component implements AfterViewInit {
  @Output('daySelect') daySelect: EventEmitter<daySelectEvent> =
    new EventEmitter<daySelectEvent>();
  @Output('daysDetermined') daysDetermined: EventEmitter<any> =
    new EventEmitter<any>();
  @Output('init') init: EventEmitter<any> = new EventEmitter<any>();

  @Input('month') month: Month;
  @Input('year') set year(val: number) {
    this.fillMonth(this.month.number, val);
    this._year = val;
  }

  get year() {
    return this._year;
  }

  private _year: number;

  weekdays: string[] = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];
  weeknumbers = [];

  multiSelect = true;

  amountOfDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  days = [
    { day: 1, weeknr: 0, weekday: 0, mode: [] },
    { day: 2, weeknr: 0, weekday: 1, mode: [] },
    { day: 3, weeknr: 0, weekday: 2, mode: [] },
    { day: 4, weeknr: 0, weekday: 3, mode: [] },
    { day: 5, weeknr: 0, weekday: 4, mode: [] },
    { day: 6, weeknr: 0, weekday: 5, mode: [] },
    { day: 7, weeknr: 0, weekday: 6, mode: [] },
    { day: 8, weeknr: 1, weekday: 0, mode: [] },
    { day: 9, weeknr: 1, weekday: 1, mode: [] },
    { day: 10, weeknr: 1, weekday: 2, mode: [] },
    { day: 11, weeknr: 1, weekday: 3, mode: [] },
    { day: 12, weeknr: 1, weekday: 4, mode: [] },
    { day: 13, weeknr: 1, weekday: 5, mode: [] },
    { day: 14, weeknr: 1, weekday: 6, mode: [] },
    { day: 15, weeknr: 2, weekday: 0, mode: [] },
    { day: 16, weeknr: 2, weekday: 1, mode: [] },
    { day: 17, weeknr: 2, weekday: 2, mode: [] },
    { day: 18, weeknr: 2, weekday: 3, mode: [] },
  ];
  formControl: FormControl;
  elements: Element[];

  constructor(public el: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    // this.fillMonth(this.month.number, this.year);
    this.formControl = new FormControl();
  }

  ngAfterViewInit(): void {
    // calculate days here:

    //this.daysDetermined.emit({ days: this.days, elements: this.el.nativeElement.querySelectorAll('isday2') });
    this.elements = this.el.nativeElement.querySelectorAll('isday2');

    this.init.emit(this);
  }

  dayClick($event, day) {}

  dayDown($event, dayInfo) {
    // only left clicks
    if ($event.which > 1) return;

    if (
      dayInfo.mode.includes('planned') &&
      !dayInfo.mode.includes('ready_to_plan')
    ) {
      $event.currentTarget.classList.add('move');
      return;
    }

    const mode = [...dayInfo.mode];

    const alldays = this.el.nativeElement.querySelectorAll('isday2');

    let initial = Number($event.currentTarget.getAttribute('day'));
    let prevSelection = [];
    let currentSelection = [];

    const trackVisited = (ev) => {
      let currentday = Number(ev.currentTarget.getAttribute('day'));

      currentSelection = [];

      const start = initial < currentday ? initial : currentday;
      const end = initial < currentday ? currentday : initial;

      for (let i = start; i <= end; i += 1) {
        currentSelection.push(i);
      }

      currentSelection
        .filter((c) => !prevSelection.includes(c))
        .forEach((d) => {
          alldays[d - 1].classList.add('inselection');
        });

      prevSelection
        .filter((c) => !currentSelection.includes(c))
        .forEach((d) => {
          alldays[d - 1].classList.remove('inselection');
        });

      prevSelection = currentSelection;
    };

    trackVisited($event);

    for (let day of alldays) {
      day.addEventListener('mouseenter', trackVisited);
    }

    const mouseUpTracker = (ev) => {
      for (let day of alldays) {
        day.classList.remove('inselection');
        day.removeEventListener('mouseenter', trackVisited);
      }

      currentSelection.forEach((day) => {
        this.daySelect.emit({
          ev: $event,
          el: alldays[day - 1],
          day: day,
          month: 1,
          dayInfo: this.days.find((d) => d.day === day),
          keepSelect: mode.includes('ready_to_plan'),
        });
      });

      window.removeEventListener('mouseup', mouseUpTracker);
    };

    window.addEventListener('mouseup', mouseUpTracker);
  }

  getWkNumber(date) {
    const d = new Date(date.getTime());
    d.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    d.setDate(d.getDate() + 3 - ((d.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(d.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return (
      1 +
      Math.round(
        ((d.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    );
  }

  fillMonth(month, year) {
    this.days = [];

    const date = new Date(year, month, 1);
    const weekday = date.getDay();
    const startx = (weekday ? weekday : 7) - 1;
    let amountDays = this.amountOfDays[month];
    if (year % 4 == 0 && month == 1) {
      amountDays = 29;
    }
    // for (let i = 0; i < this.days.length; i++) {
    //   this.datamnd[0][i + 1] = { name: this.days[i] };
    // }
    for (let i = 0; i < amountDays; i++) {
      const xpos = ((startx + i) % 7) + 1;
      const ypos = Math.floor((startx + i) / 7) + 1;
      //this.datamnd[ypos]['col' + xpos.toString()] = (i+1).toString();
      // this.datamnd[ypos][xpos] = {
      //   day: i + 1,
      //   month: month,
      //   year: year,
      //   color: 'startedItems'
      //   // , wknr: this.getWkNumber(new Date(year, month, (i+1)))
      // };
      this.days.push({ day: i + 1, weeknr: ypos, weekday: xpos, mode: [] });

      if (!this.weeknumbers[ypos - 1]) {
        this.weeknumbers.push(this.getWkNumber(new Date(year, month, i + 1)));
      }
    }
    this.cd.detectChanges();
    this.elements = this.el.nativeElement.querySelectorAll('isday2');
  }
}

export class daySelectEvent {
  ev: any;
  el: Element;
  day: number;
  dayInfo: any;
  month: number;
  keepSelect?: boolean;
}
