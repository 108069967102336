import { Component } from '@angular/core';

@Component({
  selector: 'is-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.css'],
})
export class SpinnerOverlayComponent {
  constructor() {}
}
