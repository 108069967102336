import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';
import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'isdate',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent extends IscontrolComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() label: string;
  @Input() initialValue: string;
  @Input('frmGrp') frmGrp: FormGroup;
  @ViewChild('.content') dateinput;

  get fc() {
    return this.formControl;
  }

  get fcf() {
    return this.formControlformatted;
  }

  get fdate() {
    const dt = new Date(this.fc.value);
    return this.comp.setdatetoformat(dt,this.format,this.returnformat);
  }

  // formInput:boolean = false;

  start: any;
  end: any;
  today = true;
  
  controlid: number;
  formControl: FormControl;
  controlformattedid: number;
  formControlformatted: FormControl;
  format='mm/dd/yyyy'
  returnformat='mm/dd/yyyy'
  directChange:Function | undefined;
  translation : any | null = null;

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput) {
      this.controlid = super.initControl(this.id.toString() + '.date', this.initialValue, 'date');
      this.formControl = super.getFormControl(this.controlid);
      this.controlformattedid = super.initControl(this.id.toString() + '.fdate', this.initialValue, 'fdate');
      this.formControlformatted = super.getFormControl(this.controlformattedid);
    } else {
      this.formControl = new FormControl(this.initialValue);
    }
  }

  ngAfterViewInit() {
    this.el.nativeElement.querySelector('input').focus();
    if (this.formControlformatted.value) {
      const dt = new Date(this.formControlformatted.value);
      this.formControl.setValue(dt);
    }
  }

  valueChanges(value) {
    if (typeof value === 'string') {
      this.formControlformatted.setValue(value);
      if (this.directChange){
        this.directChange(value);
      }
      return;
    }
    if (value) {
      const dtval = (value.getMonth() + 1).toString() + '/' + value.getDate().toString() + '/' + value.getFullYear();
      this.formControlformatted.setValue(dtval);
    } else
    {
      this.formControlformatted.setValue(undefined);
    }
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControl.value);
        });
      }
      if (this.today) {
        const dt = new Date();        
        const dtval = this.comp.setdatetoformat(dt,this.format,this.format);
        this.formControl.setValue(dtval);
        this.formControlformatted.setValue(dtval);
      }
    }
  }

  controlnameValueChanges(newVal) {
    this.compserv.formcontrols.get(this.controlid.toString()).name = newVal;
  }

  reset() {
    this.formControl.reset();
    this.formControlformatted.reset();
    this.cd.detectChanges();
  }

  translate(lang){
    if (this.translation){
      this.label = this.translation[lang];
    }
  }

  ngOnDestroy() {
    // super.destroyControls([this.controlname]);
  }
}
