export class EnumerationItem {
  id: any;
  description: string;
  groep: string;
  selected: boolean;
  internalid?: number;
  data?: number;
}

//export class EnumerationExtItem extends EnumerationItem {
//    internalid:number
//}

export class Enumeration {
  group: string;
  items: EnumerationItem[];
  selection: EnumerationItem[];
}
