import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigService, IsCloudService } from '@ic-builder/data-access-iscloud';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { base64StringToBlob } from 'blob-util';

@Component({
  selector: 'ic-builder-isframe',
  templateUrl: './isframe.component.html',
  styleUrls: ['./isframe.component.scss'],
})
export class IsframeComponent implements OnInit, AfterViewInit {
  documentname: string;
  sansource: SafeResourceUrl;
  loading = false;
  mimetype: string;
  usesanitize=false;
  embed=false;
  _embsrc:SafeResourceUrl;
  embtype:string;

  set pdf(value: string) {
    console.log('pdf printing');
    this.mimetype = 'data:application/pdf;base64,';
    this.source = value;
  }

  set xls(value: string) {
    this.mimetype = 'data:application/vnd.ms-excel;base64,';
    this.source = value;
  }

  set doc(value: string) {
    this.mimetype = 'data:application/vnd.ms-word;base64,';
    this.source = value;
  }

  set xlsx(value: string) {
    this.mimetype =
      'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    this.source = value;
  }

  set docx(value: string) {
    this.mimetype =
      'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,';
    this.source = value;
  }

  set html(value: string) {
    this.mimetype = 'data:text/html;base64,';
    this.source = value;
  }

  set source(value: string) {
    this.cd.detectChanges();
    //this.sansource = this.sanitizer.bypassSecurityTrustResourceUrl(value);

    const iframe = this.el.nativeElement.querySelector('iframe');
    iframe.setAttribute('src', this.mimetype + value['data']);
    //iframe.setAttribute('src', this.mimetype + value);
    this.loading = false;
  }

  set site(value: string) {
   
    debugger;
    if (this.usesanitize){
      value = this.config.getDbUrl('')+'/'+value;
      this.sansource = this.sanitizer.bypassSecurityTrustResourceUrl(value+"?ngsw-bypass=true&embedded=true");
    } else
    {
      this.sansource = value;
    }

   // const iframe = this.el.nativeElement.querySelector('iframe');
   // debugger
   // iframe.setAttribute('src', this.sansource);
    this.loading = false;
    this.cd.detectChanges();
  }

  set embsrc(value: string){
    this.embtype = 'application/pdf';
    this._embsrc = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(base64StringToBlob(value)));

    this.loading = false;
    this.cd.detectChanges();
  }

  headers: any[];
  link: string;

  set errorsource(value) {
    this.loading = false;
    this.cd.detectChanges();
    //this.sansource = this.sanitizer.bypassSecurityTrustResourceUrl(value);

    const iframe = this.el.nativeElement.querySelector('iframe');
    iframe.setAttribute(
      'src',
      'data:text/html;charset=utf-8,%3Chtml%3E%3Cbody%3E' +
        value.message +
        '%3C/body%3E%3C/html%3E'
    );
  }

  x: Observable<any>;
  constructor(
    private store: Store,
    private ic: IsCloudService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private config:ConfigService
  ) {}

  @ViewChild('iframe') iframe: ElementRef;

  ngOnInit(): void {
    this;
    // this.store.dispatch(new ExecCommand({name:'docxtohtml',action:'docxtohtml'}));
  }

  beforeRequest(){
    console.log('befire request');
  }

  populateIframe(url, headers) {
    const iframe = this.el.nativeElement.querySelector('iframe');
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.onreadystatechange = handler;
    xhr.responseType = 'blob';
    if (headers) {
      headers.forEach((header) => {
        xhr.setRequestHeader(header.key, header.value);
      });
    }
    xhr.send();

    function handler() {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          iframe.src = URL.createObjectURL(this.response);
          //console.log(this.response.documentElement.innerHTML);
        } else {
          iframe.attr(
            'srcdoc',
            '<html><head></head><body>Error loading page.</body></html>'
          );
        }
      }
    }
  }

  loaded() {
    console.log('loaded');
    this.loading = false;
  }

  ngAfterViewInit(): void {
    this.iframe = this.el.nativeElement.querySelector('iframe');

    if (this.link) {
      this.populateIframe(this.link, this.headers);
      //this.mimetype = 'data:text/html,';
      //this.source = this.link;
    }

    //console.log('after', iframe);
    // this.source = 'https://pdfviewer.net/extended-pdf-viewer/intro';
    // const c = this.ic
    //   .execute('docxtohtml', {
    //     action: 'exec',
    //     account: 330,
    //     role: '1',
    //     language: 'nl',
    //     logging: { unimonitor: true /*state.unimonitor*/ },
    //   })
    //   .pipe(
    //     tap((data) => {
    //       let doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
    //       doc.open();
    //       doc.write(atob(data['html']));
    //       doc.close();
    //     })
    //   )
    //   .subscribe();
  }
}
