<ng-template #dia>
  <outlet
    [id]="10"
    [formid]="formid"
    [data]="data"
    height="700px"
    [loadas]="loadas"
    class="gridoutlet"
    (outletloadedform)="dialogTemplateLoaded($event)"
  ></outlet>

  <div style="display: block">
    <button mat-button class="button" (click)="close()">Oke</button>
    <button mat-button class="button" (click)="close()">Cancel</button>
  </div>
</ng-template>
