import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'iscolorpicker',
  templateUrl: './iscolorpicker.component.html',
  styleUrls: ['./iscolorpicker.component.scss'],
})
export class IscolorpickerComponent
  extends IscontrolComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() label: string;
  @Input() initialValue: string;

  controlid: number;
  formControl: FormControl;

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public el: ElementRef,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput) {
      this.controlid = super.initControl(
        this.id.toString() + '.colorpicker',
        this.initialValue,
        'colorpicker'
      );
      this.formControl = super.getFormControl(this.controlid);
    } else {
      this.formControl = new FormControl(this.initialValue);
    }
  }

  controlnameValueChanges(newVal) {
    this.compserv.formcontrols.get(this.controlid.toString()).name = newVal;
  }

  ngAfterViewInit() {
    this.el.nativeElement.querySelector('input').focus();
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControl.value);
        });
      }
    }
  }

  reset() {
    this.formControl.setValue(null);
  }

  ngOnDestroy() {
    // super.destroyControls([this.controlname]);
  }
}
