import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-builder-istoggle',
  templateUrl: './istoggle.component.html',
  styleUrls: ['./istoggle.component.scss']
})
export class IstoggleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
