import { ComponentsService, Outlet2Service } from '@ic-builder/is-base';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CvcolorPipe } from '../color/color-helpers.service';

@Component({
  selector: 'islabeldata',
  templateUrl: './islabeldata.component.html',
  styleUrls: ['./islabeldata.component.scss'],
})
export class IslabeldataComponent implements OnInit, AfterViewInit {
  @Select(Outlet2Service.getInDesigner)
  public inDesigner: Observable<boolean>;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private store:Store,
    private comp:ComponentsService,
    private outlet:Outlet2Service
  ) {}

  data: any = {};
  orgdata: any = {};
  key = '';
  orgkey = '';
  backgroundcolorkey = '';
  fontcolorkey = '';
  parentid: number;

  convertData: Function | undefined = undefined;

  isIcon = false;
  svgIcon = false;

  ngOnInit() {
    this.subdata();
  }

  subdata() {
    if (this.key.includes('.') && this.data) {
      this.orgdata = this.data;
      this.orgkey = this.key;
      const subkeys = this.key.split('.');
      for (let i = 0; i < subkeys.length - 1; i++) {
        if (this.data) {
          this.data = { ...this.data[subkeys[i]] };
        }
      }
      this.key = subkeys[subkeys.length - 1];
    }
  }

  ngAfterViewInit() {
    //if (!this.inDesigner) {
    const colorhelper = new CvcolorPipe();
    if (this.backgroundcolorkey != '') {
      try {
        this.el.nativeElement.style.background = colorhelper.transform(
          this.data[this.backgroundcolorkey]
        );
      } catch {
        this.el.nativeElement.style.background = '';
      }
    }
    if (this.fontcolorkey != '') {
      try {
        this.el.nativeElement.style.color = colorhelper.transform(
          this.data[this.fontcolorkey]
        );
      } catch {
        this.el.nativeElement.style.color = '';
      }
    }

    //}
    if (this.isIcon) {
      if (this.data[this.key]?.includes('mdi')) {
        this.el.nativeElement.classList.add('mdi');
        this.el.nativeElement.classList.add(this.data[this.key]);
        this.el.nativeElement.innerHTML = '';
      } else {
        if (this.svgIcon) {
          const svgIcon = this.renderer.createElement('mat-icon');
          //this.renderer.appendChild(svgIcon, this.renderer.createText(this.data[this.key]));
          this.el.nativeElement.innerHTML = '';
          svgIcon.setAttribute('svgIcon', this.data[this.key]);
          this.renderer.setStyle(svgIcon, 'width', '32px');
          this.renderer.setStyle(svgIcon, 'height', '32px');
          this.renderer.appendChild(this.el.nativeElement, svgIcon);
          this.cd.detectChanges();
        } else {
          this.el.nativeElement.classList.add('material-icons');
        }
      }
    }
  }
}
