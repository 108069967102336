import { Injectable, NgModuleRef, Type } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { KnownModule, LazyRegistryResponse } from './symbols';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/operators';

let instance = 0;

@Injectable()
export class WidgetsRegistryService {
  #lazyRegistry = new Map<string, KnownModule>();
  #widgetComponentLazyModuleRegistry = new Map<
    string,
    [KnownModule, Type<unknown>]
  >();
  #widgetComponentEagerlyModuleRegistry = new Map<
    string,
    [Type<unknown>, Type<unknown>]
  >();
  #eagerModuleRegistry = new Map<string, NgModuleRef<unknown>>();
  #instance = instance++;

  constructor(private httpClient: HttpClient) {
    console.log(
      `%cClass: WidgetsRegistryService, Function: constructor(this.#registry): `,
      'color: red;',
      this.#instance,
      this.#widgetComponentLazyModuleRegistry
    );
    this.httpClient
      .get<LazyRegistryResponse>('/assets/lazy-registry.json')
      .pipe(pluck('registries'))
      .subscribe((registries) => {
        registries.forEach(({ module, components }) => {
          components.forEach((component) => {
            this.#lazyRegistry.set(component, module);
          });
        });
        console.log(
          `%cClass: WidgetsRegistryService, Function: LazyRegistryResponse(this.#lazyRegistry): `,
          'color: black;',
          this.#lazyRegistry
        );
      });
  }

  public registerLazyModuleWithComponents(
    moduleConfig: { name: KnownModule },
    components: Record<string, Type<unknown>>
  ) {
    // console.log(
    //   `%cClass: WidgetsRegistryService, Function: registerModuleWithComponents(moduleConfig, components): `,
    //   'color: black;',
    //   moduleConfig,
    //   components
    // );
    Object.entries(components).forEach(([widgetName, type]) => {
      this.#widgetComponentLazyModuleRegistry.set(widgetName, [
        moduleConfig.name,
        type,
      ]);
    });
  }
  public registerEagerlyModuleWithComponents(
    moduleConfig: { ngModule: Type<unknown> },
    components: Record<string, Type<unknown>>
  ) {
    console.log(
      `%cClass: WidgetsRegistryService, Function: registerModuleWithComponents(moduleConfig, components): `,
      'color: black;',
      moduleConfig,
      components
    );
    Object.entries(components).forEach(([widgetName, type]) => {
      this.#widgetComponentEagerlyModuleRegistry.set(widgetName, [
        moduleConfig.ngModule,
        type,
      ]);
    });
  }

  public getWidgetType(type: string): ComponentType<unknown> | undefined {
    return (this.#widgetComponentEagerlyModuleRegistry.get(type) ??
      this.#widgetComponentLazyModuleRegistry.get(type))?.[1];
    // return this.#registry.get(type);
  }

  public getLazyWidgetInfo(name: string): [KnownModule, Type<unknown>] | null {
    return this.#widgetComponentLazyModuleRegistry.get(name);
  }
  public getEagerWidgetInfo(
    name: string
  ): [Type<unknown>, Type<unknown>] | null {
    return this.#widgetComponentEagerlyModuleRegistry.get(name);
  }

  public getLazyRegistry(): ReadonlyMap<string, KnownModule> {
    return this.#lazyRegistry;
  }

  setEagerModuleRef(moduleName: string, moduleRef: NgModuleRef<unknown>) {
    this.#eagerModuleRegistry.set(moduleName, moduleRef);
  }

  getEagerModuleRef(moduleName: string) {
    return this.#eagerModuleRegistry.get(moduleName);
  }
}
