import { ComponentsService } from '@ic-builder/is-base';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'lib-ismenu2',
  templateUrl: './ismenu2.component.html',
  styleUrls: ['./ismenu2.component.scss'],
})
export class Ismenu2Component implements OnInit, AfterViewInit {
  constructor(
    private compservice: ComponentsService,
    private cd: ChangeDetectorRef,
    private el: ElementRef
  ) {}

  @ViewChild('menuitems', { read: ViewContainerRef })
  menuitems: ViewContainerRef;

  sizes: Array<any>;
  left = 0;
  expanded = true;
  level: number;
  id: number;
  mainmenuid: number;
  startpoint = false;

  currentlyexpanded = [];

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.cd.detectChanges();

    for (const size of this.sizes) {
      const id = size.id;
      const comp = this.compservice.widgets.get(id);

      if (this.startpoint) {
        comp.instance.mainmenuid = this.id;
      }

      this.menuitems.insert(comp.hostView);
    }
  }
}
