import { Treeified } from './symbols';
import { cloneDeep, omit } from 'lodash/fp';

/**
 * Clone the given tree and return an array of its elements, removing the childrenProperty
 * @param haystackTree {Treeified<T, C>}
 * @param childrenProperty {C}
 */
export function flattenTree<
  K extends keyof T,
  T extends Record<K, T[K]>,
  C extends PropertyKey
>(haystackTree: Treeified<T, C>, childrenProperty: C): Array<T> {
  const workArray = [cloneDeep(haystackTree)];
  const result: Array<T> = [];

  let item;
  while ((item = workArray.shift()) != null) {
    result.push(
      <T>(<unknown>omit<Treeified<T, C>, C>([childrenProperty], item))
    );

    if (
      Object.prototype.hasOwnProperty.call(item, childrenProperty) &&
      Array.isArray(item[childrenProperty])
    ) {
      workArray.push(...(item[childrenProperty] as Array<Treeified<T, C>>));
    }
  }

  return result;
}
