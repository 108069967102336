<!--The display header-->
<span class="month-header">
  {{ month.description }}
</span>

<!--The days of the week-->
<span
  *ngFor="let w of weekdays; let i = index"
  [style.grid-column]="i + 2"
  class="weekday"
>
  {{ w }}
</span>

<!-- The weeknumbers -->
<span *ngFor="let w of weeknumbers" [style.grid-column]="1" class="weeknumber">
  {{ w }}
</span>

<isday2
  *ngFor="let d of days"
  [style.grid-column]="1 + d.weekday"
  [style.grid-row]="2 + d.weeknr"
  [day]="d.day"
  [mode]="d.mode"
  (click)="dayClick($event, d)"
  (mousedown)="dayDown($event, d)"
>
</isday2>
