import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'iscustomcheckbox',
  templateUrl: './iscustomcheckbox.component.html',
  styleUrls: ['./iscustomcheckbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IscustomcheckboxComponent),
      multi: true,
    },
  ],
})
export class IscustomcheckboxComponent implements ControlValueAccessor {
  //control: FormControl = new FormControl(false);

  get value(): boolean {
    return this._value;
  }

  private _value: boolean = false;

  @HostListener('click', ['$event'])
  click(ev: any) {
    this.writeValue(!this._value);
  }

  @Input('disabled') disabled = false;

  @HostBinding('style.opacity')
  get opacity() {
    return this.disabled ? 0.25 : 1;
  }

  constructor(private cd: ChangeDetectorRef, private el: ElementRef) {}

  // ControlValueAccessor implementation
  _onChange: (_: any) => void = (_) => {};

  _onTouched = () => {};

  writeValue(value: any): void {
    if (!this.disabled) {
      if (value) {
        this.el.nativeElement.classList.add('check');
      } else {
        this.el.nativeElement.classList.remove('check');
      }
      this.cd.detectChanges();

      this._onChange(value);
      this._value = value;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
