import {
  IIsComponent,
  IIsMenuItem,
  IsMenuItem,
  LoadComponent,
  Outlet2Service,
} from '@ic-builder/is-base';
import { ArrayDataSource } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Store } from '@ngxs/store';
//import { LoadMenu } from '../isapplication/isapplication.action';
import { Observable, Subscription } from 'rxjs';
import { WidgetsRegistryService } from '@ic-builder/widgets-registry';
import { filter, publish, take } from 'rxjs/operators';
//import { LoadEnumlist } from '../isenum/enum.action';

@Component({
  selector: 'ispageform',
  templateUrl: './ispageform.component.html',
  styleUrls: ['./ispageform.component.scss'],
})
export class IspageformComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() cfg: any;
  config: any;
  @Input() id: number;
  outlet: number;
  @Input() height: string;
  forms: IIsComponent;
  vcRef: EmbeddedViewRef<any>;
  direction: string;
  first: string;
  outlet$: Observable<any>;
  ds: ArrayDataSource<IIsMenuItem>;
  menuds: IIsMenuItem[];
  treeControl = new NestedTreeControl<IIsMenuItem>((node) => node.children);

  pageformcomp: ComponentRef<any>;
  @ViewChild('module', { read: ViewContainerRef })
  public moduleHost: ViewContainerRef;

  module: string;
  pageDirection: string;
  name: string;
  editmode: number;
  period: string;
  menu: IIsMenuItem[];
  menucmp: number;
  menuname: string;
  menuservice: Observable<IsMenuItem>;
  subscription: Subscription;
  default: number;
  menuView: any;
  firsttime: boolean;
  toload: Observable<IIsComponent>;

  @Input() version: number;
  @Output() changepageEvent = new EventEmitter<IIsComponent>();
  @ViewChild('vc', { read: ViewContainerRef }) private vc;
  @ViewChild('pagecontainer', { read: ViewContainerRef })
  public pagecontainer: ViewContainerRef;
  //@ViewChild('pagecontainer', {read: TemplateRef}) pagecontainer: TemplateRef<any>;
  @ViewChild('menu') private menutemp: TemplateRef<object>;
  @ViewChild('main') private main: TemplateRef<object>;
  @ViewChildren('widgetLayer', { read: ElementRef })
  public widgetlayer: QueryList<ElementRef>;
  hasChild = (_: number, node: IIsMenuItem) =>
    !!node.children && node.children.length > 0;

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    private el: ElementRef,
    private outletservice: Outlet2Service,
    private componentFactoryResolver: ComponentFactoryResolver,
    private registry: WidgetsRegistryService
  ) {}

  ngOnInit(): void {
    //this.store.dispatch(new LoadEnumlist('FORM', undefined, 10));
    this.store.dispatch({
      type: '[LoadEnumlist]',
      payload: { payload: 'FORM', id: undefined, account: 10 },
    });
    let loaded: boolean;
    if (this.cfg) {
      this.setProp(this, JSON.parse(this.cfg));
    }
    if (this.version) {
      if (this.period) {
        // @TODO: removed the entire action
        // this.store.dispatch(new LoadPeriodMenu(this.period));
      } else if (this.menu) {
        this.ds = new ArrayDataSource(this.menu);
        this.menuds = this.menu;
      } else if (this.menucmp) {
        //this.store.dispatch(new LoadComponent({ id: this.menucmp, name: 'pageformmenu', kind: 'Menu' }));
        this.store.dispatch({
          type: '[LoadComponent]',
          payload: { id: this.menucmp, name: 'pageformmenu', kind: 'Menu' },
        });
        this.menuservice = this.store.select(Outlet2Service.menu(this.menucmp));
        this.subscription = this.menuservice.subscribe((menu) => {
          //console.log('subscribe outlet2menu', this.menucmp, menu);

          if (menu) {
            this.ds = new ArrayDataSource(menu.children);
            this.menuds = menu.children;
            const item: IsMenuItem = menu;
            if (item.default) {
              const node: IsMenuItem = item.children.find((f) => {
                return f && f.id === item.default;
              });
              if (node) {
                this.componentLoadedSubscription(node.payload.id);
                this.store.dispatch({
                  type: '[LoadComponent]',
                  payload: {
                    id: node.payload.id,
                    name: node.name,
                    outlet: node.payload.outlet,
                    account: '10',
                  },
                });
              }
            }
          } else {
            loaded = false;
          }
        });
      }
      if (this.menuname) {
        //this.store.dispatch(new LoadMenu({ module: this.module, menu: this.menuname }));
        const x = this.module + '.' + this.menuname;

        this.store
          .select((state) => state['isapplicationstate'].menus.get(x))
          .subscribe((data) => {
            //console.log('subscribe menuname', data);
            if (data) {
              this.ds = new ArrayDataSource(data.children);
              this.menuds = data.children;
            } else {
              loaded = false;
            }
          });
      }
    }
  }

  setProp(cmp, cfg) {
    for (const [key, value] of Object.entries(cfg)) {
      if (value) {
        if (typeof value === 'string' || typeof value === 'number') {
          cmp[key] = value;
        }
        if (typeof value === 'object') {
          {
            cmp[key] = Object.assign({}, value);
          }
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.version) {
      // this.renderer.setStyle(this.el.nativeElement, 'display', 'flex')
      this.renderer.setStyle(this.el.nativeElement, 'height', '100%');
      this.renderer.setStyle(this.el.nativeElement, 'position', 'relative');
      // this.renderer.setStyle(this.el.nativeElement, 'display', 'block')

      if (this.pageDirection == 'left' || this.pageDirection == 'right') {
        // this.renderer.setStyle(this.el.nativeElement, 'flex-direction', 'row')
      }
      if (this.pageDirection == 'top' || this.pageDirection == 'bottom') {
        // this.renderer.setStyle(this.el.nativeElement, 'flex-direction', 'column')
      }

      if (this.pageDirection == 'left') {
        if (!this.menuView) {
          this.menuView = this.vc.createEmbeddedView(this.menutemp, {
            width: '250px',
            display: 'block',
          });
        }
        //this.vcRef = this.vc.createEmbeddedView(this.pagecontainer,null);
      }
      if (this.pageDirection == 'right') {
        if (!this.menuView) {
          this.menuView = this.vc.createEmbeddedView(this.menutemp, {
            width: '250px',
            display: 'block',
          });
        }
      }

      if (this.pageDirection == 'top') {
        if (!this.menuView) {
          this.menuView = this.vc.createEmbeddedView(this.menutemp, {
            height: '50px',
            display: 'inline-block',
          });
        }
        //this.vcRef = this.vc.createEmbeddedView(this.pagecontainer,null);
      }

      if (this.pageDirection == 'bottom') {
        //this.vcRef = this.vc.createEmbeddedView(this.pagecontainer,null);
        if (!this.menuView) {
          this.menuView = this.vc.createEmbeddedView(this.menutemp, {
            height: '50px',
            display: 'inline-block',
          });
        }
      }

      this.cd.detectChanges();
    }
  }

  componentLoadedSubscription(pageid: number) {
    this.toload = this.outletservice.getComponentById$(pageid).pipe(
      filter((component) => Boolean(component)),
      take(1)
    );

    let pagesub: Subscription | null;
    pagesub = this.toload.subscribe((l) => {
      if (l) {
        this.changepageEvent.emit(l);
        pagesub?.unsubscribe();
      }
    });
  }

  click(node) {
    //this.store.dispatch(new SelectPageFormItem({id:'pgRelation',mod:'2',mes:{parent:this.config.parentkey,key:[node]}}))

    if (!this.editmode) {
      const unselel = this.el.nativeElement.querySelector('.selected');
      if (unselel) {
        this.renderer.removeClass(unselel, 'selected');
      }
      const selel = document.getElementById(node.id);
      if (selel) {
        this.renderer.addClass(selel, 'selected');
      }

      this.componentLoadedSubscription(node.payload.id);

      this.store.dispatch({
        type: '[LoadComponent]',
        payload: {
          id: node.payload.id,
          name: node.name,
          outlet: node.payload.outlet,
          account: '10',
        },
      });
    }
  }

  clickeditmode(node) {
    //this.store.dispatch({"type":node.payload.action,"payload":{id:node.payload.id,name:node.payload.name,outlet:node.payload.outlet}})
  }
}
