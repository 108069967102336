import { NgModule, NgModuleRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  WidgetsRegistryModule,
  WidgetsRegistryService,
} from '@ic-builder/widgets-registry';
import { StackedbarComponent } from './stackedbar/stackedbar.component';
import { BarComponent } from './bar/bar.component';
import { DonutComponent } from './donut/donut.component';
import { LineComponent } from './line/line.component';
import { VegaembComponent } from './vegaemb/vegaemb.component';

/*

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import bar charts, all with Chart suffix
import { BarChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
// Import the Canvas renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';
import 'echarts/theme/macarons.js';

echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer]);
*/

let instanceNr = 0;

@NgModule({
  imports: [
    CommonModule,

    WidgetsRegistryModule.forChild({
      module: { ngModule: IsChartsModule },
      components: {
        isstackedbarchart: StackedbarComponent,
        isbar: BarComponent,
        isdonut: DonutComponent,
        isline: LineComponent,
        isvegaembed: VegaembComponent
      },
    }),
  ],
  declarations: [
    StackedbarComponent,
    BarComponent,
    DonutComponent,
    LineComponent,
    VegaembComponent,
  ],

  exports: [StackedbarComponent, BarComponent, DonutComponent, LineComponent,VegaembComponent],
  providers: [],
})
export class IsChartsModule {
  #instanceNr = instanceNr++;

  constructor(
    moduleRef: NgModuleRef<IsChartsModule>,
    widgetsRegistryService: WidgetsRegistryService
  ) {
    widgetsRegistryService.setEagerModuleRef('IsChartsModule', moduleRef);
    console.log(
      `%cClass: ChartsModule, Function: constructor(this.#instanceNr): `,
      'color: black;',
      this.#instanceNr
    );
  }
}
