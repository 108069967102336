<div
  *ngFor="let o of options"
  istag="option"
  class="option"
  [id]="'val' + o.value"
  (click)="writeValue(o.value, true)"
>
  <div *ngIf="usecheckmarks" class="checkmarks" istag="selected"></div>
  <!-- {{o.value}} -->
  {{ o.label }}
</div>
