import { Injectable, OnDestroy } from '@angular/core';
import { UserModel } from './user.model';
import { fromEvent, merge, Observable, ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  take,
  tap,
} from 'rxjs/operators';
import { BroadcastChannel } from 'broadcast-channel';
import { createBroadcastChannelObservable } from './utils';
import { SubSink } from 'subsink';

type BroadCastEvent = { type: 'LOGOUT'; session: string | null };

@Injectable()
export class SessionService implements OnDestroy {
  #subs = new SubSink();

  #currentUserSubject = new ReplaySubject<UserModel | null>(1);
  readonly #currentUser$: Observable<UserModel | null>;
  #broadcastChannel$ =
    createBroadcastChannelObservable<BroadCastEvent>('session');
  get currentUser$() {
    return this.#currentUser$;
  }

  constructor() {
    const currentUserEvent$ = fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(
        (event) =>
          event.storageArea === sessionStorage &&
          ['currentUser', null].includes(event.key)
      ),
      map((event) => event.newValue),
      startWith(sessionStorage.getItem('currentUser'))
    );
    this.#currentUser$ = merge(
      // convert userData to string for faster comparison
      this.#currentUserSubject.pipe(
        map((userData) => (userData ? JSON.stringify(userData) : null))
      ),
      currentUserEvent$
    ).pipe(
      distinctUntilChanged(),
      map<string | null, UserModel | null>((userData) =>
        userData ? JSON.parse(userData) : null
      ),
      shareReplay(1)
    );
    this.#subs.sink = this.#broadcastChannel$.subscribe((channel) => {
      console.log(
        `%cClass: SessionService, Function: new BroadcastChannel(): `,
        'color: black;'
      );
      channel.onmessage = (ev) => {
        console.log(
          `%cClass: SessionService, Function: onmessage(ev): `,
          'color: black;',
          ev
        );
        switch (ev.type) {
          case 'LOGOUT': {
            if (ev.session === this.getSessionId()) {
              this.setCurrentUser(null);
            }
          }
        }
      };
    });
  }

  getSessionId(): string | null {
    const currentUser = sessionStorage.getItem('currentUser');
    if (currentUser) {
      return JSON.parse(currentUser).ISSESSION;
    }
    return null;
  }

  setCurrentUser(userData: UserModel | null) {
    console.log(
      `%cClass: SessionService, Function: setCurrentUser(userData): `,
      'color: black;',
      userData
    );
    if (userData) {
      sessionStorage.setItem('currentUser', JSON.stringify(userData));
    } else {
      const sessionId = this.getSessionId();
      this.#broadcastChannel$
        .pipe(take(1))
        .subscribe((channel) =>
          channel.postMessage({ type: 'LOGOUT', session: sessionId })
        );
      sessionStorage.removeItem('currentUser');
    }
    this.#currentUserSubject.next(userData);
  }

  ngOnDestroy() {
    this.#subs.unsubscribe();
  }
}
