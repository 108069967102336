import { Injectable, EventEmitter } from '@angular/core';

export interface ISBaseTheme {
  name: string;
  properties: {};
}

export interface ISColorTheme extends ISBaseTheme {
  properties: {
    10: string;
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    textcolor: string;
    valuetextcolor: string;
    icontextcolor: string;
    gridheader: string;
    gridfooter: string;
    gridbody: string;
    gridrowselected: string;
    gridroweditcell: string;
    gridrowselectedfocused: string;    
    gridcellborderbottom: string;
    gridcellborderright: string;
    gridheadercolor: string;
    gridfootercolor: string;
    gridbodycolor: string;
    gridmarkcolor: string;
    gridloadercolor: string;
    disabled: string;
    mobileheader: string;
    mobilefooter: string;
    mobilemenutextcolor: string;
    mobilelogonbutton: string;
    mobilelogonbackground:string;
    mobilelogontextcolor:string;
    focusedgridheader:string;
    pageformbordertop: string;
    pageformborderbottom: string;
    pageformbordercolor: string;
    pageformborderwidth: string;
    pageformbackgroundcolor: string;
    formbackgroundcolor:string;
    wizardbackgroundcolor:string;
    wizardtextcolor:string;
    multiselchckboxbkgcolor:string;
    radiocheckbackgroundcolor:string;
  };
}

export interface ISShapeTheme extends ISBaseTheme {
  properties: {
    borderradius: string;
    boxshadow: string;
    border: string;
  };
}

export interface ISTreeTheme extends ISBaseTheme {
  properties: {
    treeleftpadding: string;
  };
}

export interface ISCompositeTheme {
  colorTheme: ISColorTheme;
  shapeTheme: ISShapeTheme;
  treeTheme: ISTreeTheme;
}

export const matIntersoftOne: ISColorTheme = {
  name: 'intersoftone',
  properties: {
    10: 'white',
    50: '#F2F8FF',
    100: '#E8ECEF',
    200: '#90caf9',
    300: '#64b5f6',
    400: '#42a5f5',
    500: 'white',
    600: '#1e88e5',
    700: '#1976d2',
    800: '#1565c0',
    900: '#0d47a1',
    textcolor: 'black',
    valuetextcolor: '#4EA9CE',
    icontextcolor: 'grey',
    gridheader: 'white',
    gridfooter: 'white',
    gridbody: 'white',
    gridrowselected: '#90caf9',
    gridroweditcell: '#F2F8FF',
    gridrowselectedfocused: '#64b5f6',    
    gridcellborderbottom: 'solid 1px rgb(228, 214, 214)',
    gridcellborderright: 'none',
    gridheadercolor: '#F2F8FF',
    gridfootercolor: '#F2F8FF',
    gridbodycolor: '#F2F8FF',
    gridmarkcolor: 'yellow',
    gridloadercolor: '#049CE4',
    disabled: 'grey',
    mobileheader: '#049CE4',
    mobilefooter: '#049CE4',
    mobilemenutextcolor: '#049CE4',
    mobilelogonbutton: '#049CE4',
    mobilelogonbackground: 'white',
    mobilelogontextcolor: 'white',
    focusedgridheader: '' ,
    pageformbordertop: 'none',
    pageformborderbottom: 'none',
    pageformbordercolor: 'white',
    pageformborderwidth: '1px',
    pageformbackgroundcolor:'#90caf9',
    formbackgroundcolor:'#0d47a1',
    wizardbackgroundcolor:'#0d47a1',
    wizardtextcolor:'black',
    multiselchckboxbkgcolor:'black',
    radiocheckbackgroundcolor:'black'
  }
};


export const matPowerPortal: ISColorTheme = {
  name: 'powerportal',
  properties: {
    10: 'white',
    50: '#e3f2fd',
    100: '#bbdefb',
    200: '#90caf9',
    300: '#64b5f6',
    400: '#42a5f5',
    500: '#2196f3',
    600: '#1e88e5',
    700: '#1976d2',
    800: '#1565c0',
    900: '#0d47a1',
    textcolor: 'black',
    valuetextcolor: 'grey',
    icontextcolor: 'grey',
    gridheader: '#2196f3',
    gridfooter: '#2196f3',
    gridbody: '#2196f3',
    gridrowselected: '#e3f2fd',
    gridroweditcell: '#e3f2fd',
    gridrowselectedfocused: '#64b5f6',            
    gridcellborderbottom: 'solid 1px rgb(228, 214, 214)',
    gridcellborderright: 'none',
    gridheadercolor: '#e3f2fd',
    gridfootercolor: '#e3f2fd',
    gridbodycolor: '#e3f2fd',
    gridmarkcolor: 'yellow',
    gridloadercolor: '#049CE4',
    disabled: 'grey',
    mobileheader: '#049CE4',
    mobilefooter: '#049CE4',
    mobilemenutextcolor: '#049CE4',
    mobilelogonbutton: '#049CE4',
    mobilelogonbackground: 'white',
    mobilelogontextcolor: 'white',
    focusedgridheader: 'ligth-grey', 
    pageformbordertop: 'none',
    pageformborderbottom: 'none',
    pageformbordercolor: 'white',
    pageformborderwidth: '1px',
    pageformbackgroundcolor:'#90caf9'    ,
    formbackgroundcolor:'#0d47a1',
    wizardbackgroundcolor:'#0d47a1',
    wizardtextcolor:'black',
    multiselchckboxbkgcolor:'black',
    radiocheckbackgroundcolor:'black'
  },
};

export const matThemeBlue: ISColorTheme = {
  name: 'matblue',
  properties: {
    10: 'white',
    50: '#e3f2fd',
    100: '#bbdefb',
    200: '#90caf9',
    300: '#64b5f6',
    400: '#42a5f5',
    500: '#2196f3',
    600: '#1e88e5',
    700: '#1976d2',
    800: '#1565c0',
    900: '#0d47a1',
    textcolor: '#0d47a1',
    valuetextcolor: 'grey',
    icontextcolor: 'grey',
    gridheader: '#2196f3',
    gridfooter: '#2196f3',
    gridbody: '#2196f3',
    gridrowselected: '#90caf9',
    gridroweditcell: '#e3f2fd',
    gridrowselectedfocused: '#64b5f6',                
    gridcellborderbottom: 'solid 1px rgb(228, 214, 214)',
    gridcellborderright: 'none',
    gridheadercolor: '#e3f2fd',
    gridfootercolor: '#e3f2fd',
    gridbodycolor: '#e3f2fd',   
    gridmarkcolor: 'yellow',
    gridloadercolor: '#049CE4',
    disabled: 'grey',
    mobileheader: '#049CE4',
    mobilefooter: '#049CE4',
    mobilemenutextcolor: '#049CE4',
    mobilelogonbutton: '#049CE4',
    mobilelogonbackground: 'white',
    mobilelogontextcolor: 'white',
    focusedgridheader: 'ligth-grey', 
    pageformbordertop: 'none',
    pageformborderbottom: 'none',
    pageformbordercolor: 'white',
    pageformborderwidth: '1px',
    pageformbackgroundcolor:'#90caf9',
    formbackgroundcolor:'#0d47a1',
    wizardbackgroundcolor:'#0d47a1',
    wizardtextcolor:'black',
    multiselchckboxbkgcolor:'black',
    radiocheckbackgroundcolor:'black'
  },
};

export const matGreyTheme: ISColorTheme = {
  name: 'matgrey',
  properties: {
    10: 'white',
    50: '#f5f5f5',
    100: '#e9e9e9',
    200: '#d9d9d9',
    300: '#c4c4c4',
    400: '#9d9d9d',
    500: '#7b7b7b',
    600: '#555555',
    700: '#434343',
    800: '#262626',
    900: '#f5f5f5',
    textcolor: 'black',
    valuetextcolor: 'grey',
    icontextcolor: 'grey',
    gridheader: '#7b7b7b',
    gridfooter: '#7b7b7b',
    gridbody: '#7b7b7b',
    disabled: 'grey',
    gridrowselected: '#d9d9d9',
    gridroweditcell: '#f5f5f5',
    gridrowselectedfocused: '#c4c4c4',       
    gridcellborderbottom: 'solid 1px rgb(228, 214, 214)',
    gridcellborderright: 'none',
    gridheadercolor: '#f5f5f5',
    gridfootercolor: '#f5f5f5',
    gridbodycolor: '#f5f5f5',       
    gridmarkcolor: 'yellow',
    gridloadercolor: '#1867C0',
    mobileheader: '#049CE4',
    mobilefooter: '#049CE4',
    mobilelogonbutton: '#049CE4',
    mobilemenutextcolor: '#049CE4',
    mobilelogonbackground: 'white',
    mobilelogontextcolor: 'white',
    focusedgridheader: '#D3D3D3',
    pageformbordertop: 'none',
    pageformborderbottom: 'none',
    pageformbordercolor: 'white',
    pageformborderwidth: '1px',
    pageformbackgroundcolor:'#d9d9d9',
    formbackgroundcolor:'#f5f5f5',
    wizardbackgroundcolor:'#f5f5f5',
    wizardtextcolor:'black',
    multiselchckboxbkgcolor:'black',
    radiocheckbackgroundcolor:'black'
  },
};

export const matDarkTheme: ISColorTheme = {
  name: 'matdark',
  properties: {
    10: 'white',
    50: '#000000',
    100: '#262626',
    200: '#434343',
    300: '#555555',
    400: '#7b7b7b',
    500: '#9d9d9d',
    600: '#c4c4c4',
    700: '#d9d9d9',
    800: '#e9e9e9',
    900: '#f5f5f5',
    textcolor: 'white',
    valuetextcolor: 'grey',
    icontextcolor: 'grey',
    gridheader: '#9d9d9d',
    gridfooter: '#9d9d9d',
    gridbody: '#9d9d9d',
    gridrowselected: '#434343',
    gridroweditcell: '#000000',
    gridrowselectedfocused: '#555555',           
    gridcellborderbottom: 'solid 1px rgb(228, 214, 214)',
    gridcellborderright: 'none',
    gridheadercolor: '#000000',
    gridfootercolor: '#000000',
    gridbodycolor: '#000000',               
    gridmarkcolor: 'yellow',
    gridloadercolor: '#049CE4',
    disabled: 'grey',
    mobileheader: '#049CE4',
    mobilefooter: '#049CE4',
    mobilelogonbutton: '#049CE4',
    mobilemenutextcolor: '#049CE4',
    mobilelogonbackground: 'white',
    mobilelogontextcolor: 'white',
    focusedgridheader: 'ligth-grey', 
    pageformbordertop: 'none',
    pageformborderbottom: 'none',
    pageformbordercolor: 'white',
    pageformborderwidth: '1px',
    pageformbackgroundcolor:'#434343',
    formbackgroundcolor:'#f5f5f5',
    wizardbackgroundcolor:'#f5f5f5',
    wizardtextcolor:'black',
    multiselchckboxbkgcolor:'black',
    radiocheckbackgroundcolor:'black'
  },
};

export const matThemeRed: ISColorTheme = {
  name: 'matred',
  properties: {
    10: 'white',
    50: '#ffebee',
    100: '#ffcdd2',
    200: '#ef9a9a',
    300: '#e57373',
    400: '#ef5350',
    500: '#f44336',
    600: '#e53935',
    700: '#d32f2f',
    800: '#c62828',
    900: '#b71c1c',
    textcolor: '#b71c1c',
    valuetextcolor: 'grey',
    icontextcolor: 'grey',
    gridheader: '#f44336',
    gridfooter: '#f44336',
    gridbody: '#f44336',
    gridrowselected: '#ef9a9a',
    gridroweditcell: '#ffebee',
    gridrowselectedfocused: '#e57373',               
    gridcellborderbottom: 'solid 1px rgb(228, 214, 214)',
    gridcellborderright: 'none',
    gridheadercolor: '#ffebee',
    gridfootercolor: '#ffebee',
    gridbodycolor: '#ffebee',      
    gridmarkcolor: 'yellow',
    gridloadercolor: '#049CE4',
    disabled: 'grey',
    mobileheader: '#049CE4',
    mobilefooter: '#049CE4',
    mobilelogonbutton: '#049CE4',
    mobilemenutextcolor: '#049CE4',
    mobilelogonbackground: 'white',
    mobilelogontextcolor: 'white',
    focusedgridheader: 'ligth-grey', 
    pageformbordertop: 'none',
    pageformborderbottom: 'none',
    pageformbordercolor: 'white',
    pageformborderwidth: '1px',
    pageformbackgroundcolor: '#ef9a9a',    
    formbackgroundcolor: '#b71c1c',
    wizardbackgroundcolor:'#b71c1c',
    wizardtextcolor:'#ffffff',
    multiselchckboxbkgcolor:'black',
    radiocheckbackgroundcolor:'black'
  },
};

export const shapeTheme1: ISShapeTheme = {
  name: 'shape1',
  properties: {
    borderradius: '0%',
    boxshadow: 'none',
    border: 'none',
  },
};

export const shapeTheme2: ISShapeTheme = {
  name: 'shape2',
  properties: {
    borderradius: '50%',
    boxshadow: '0px 0px 5px 0px black',
    border: 'none',
  },
};

export const treeTheme1: ISTreeTheme = {
  name: 'tree1',
  properties: {
    treeleftpadding: '20px',
  },
};

export const treeTheme2: ISTreeTheme = {
  name: 'tree1',
  properties: {
    treeleftpadding: '1px',
  },
};

@Injectable({
  providedIn: 'root',
})
export class CSSService {
  prefix = 'is';

  makeColReady(gutter: number, collapsedGutter: number) {
    const properties = {
      '--make-col-ready': {
        '': {
          width: '100%',
          'padding-right': `${gutter / 2}px`,
          'padding-left': `${gutter / 2}px`,
        },
        '--row--condensed': {
          'padding-right': `${collapsedGutter / 2}px`,
          'padding-left': `${collapsedGutter / 2}px`,
        },
        '--grid--condensed': {
          'padding-right': `${collapsedGutter / 2}px`,
          'padding-left': `${collapsedGutter / 2}px`,
        },
        '--row--narrow': {
          'padding-right': `${gutter / 2}px`,
          'padding-left': `0px`,
        },
      },
    };
    return properties;
  }

  JSONtoCSSRules(properties) {
    const selector = Object.keys(properties)[0];
    Object.entries(properties[selector]).forEach((prop) => {
      //console.log(this.prefix + selector + this.test(prop));
    });
    // debugger;
  }

  test(properties) {
    let str = properties[0] + ' {';
    Object.entries(properties[1]).forEach((prop) => {
      str += prop[0] + ':' + prop[1];
    });
    return (str += ' }');
  }
}

@Injectable({
  providedIn: 'root',
})
export class IsthemingService {
  public colorThemes: ISColorTheme[] = [
    matGreyTheme,
    matDarkTheme,
    matThemeBlue,
    matThemeRed,
    matIntersoftOne,
    matPowerPortal,
  ];
  public activeColorTheme: string = this.colorThemes[0].name;

  public shapeThemes: ISShapeTheme[] = [shapeTheme1, shapeTheme2];
  public activeShapeTheme: string = this.shapeThemes[0].name;

  public treeThemes: ISTreeTheme[] = [treeTheme1, treeTheme2];
  public activeTreeTheme: string = this.treeThemes[0].name;

  public activeComposite: ISCompositeTheme = {
    colorTheme: this.colorThemes[0],
    shapeTheme: this.shapeThemes[0],
    treeTheme: this.treeThemes[0],
  };

  themeChange = new EventEmitter();

  //constructor() {}

  getColorTheme(name: string) {
    const theme = this.colorThemes.find((t) => t.name === name);
    if (!theme) {
      throw new Error(`Theme not found: '${name}'`);
    }
    return theme;
  }

  getShapeTheme(name: string) {
    const theme = this.shapeThemes.find((t) => t.name === name);
    if (!theme) {
      throw new Error(`Theme not found: '${name}'`);
    }
    return theme;
  }

  getColorThemeList() {
    return this.colorThemes;
  }

  getThemeCSSString(theme: ISBaseTheme) {
    let returnString = '{';
    Object.entries(theme.properties).forEach((prop) => {
      returnString = returnString + '--' + prop[0] + ':' + prop[1] + ';';
    });
    returnString = returnString + '}';
    return returnString;
  }

  getCompositeCSSString(composite: ISCompositeTheme) {
    let returnString = '{';
    Object.entries(composite).forEach((theme) => {
      if (theme[1]) {
        Object.entries(theme[1].properties).forEach((prop) => {
          returnString = returnString + '--' + prop[0] + ':' + prop[1] + ';';
        });
      }
    });
    returnString = returnString + '}';
    return returnString;
  }

  getActiveComposite() {
    return this.activeComposite;
  }

  addThemeToComposite(theme: ISBaseTheme, type: string) {
    this.activeComposite[type] = theme;
    // this.activeTheme = name;
    this.themeChange.emit(this.activeComposite);
  }

  // registerTheme(theme: ISColorTheme) {
  //   this.themes.push(theme);
  // }

  // updateTheme(name: string, properties: { [key: string]: string; }) {
  //   const theme = this.getTheme(name);
  //   theme.properties = {
  //     ...theme.properties,
  //     ...properties
  //   };

  //   if (name === this.activeTheme) {
  //     // this.themeChange.emit(name);
  //   }
  // }
}
