import { ExtractStringPropertyNames } from './symbols';

export function generateTreeChildPropertyValue<
  K extends keyof T,
  T extends Record<K, T[K]>,
  C extends keyof T
>(
  haystackTree: T,
  config: {
    indexProperty: ExtractStringPropertyNames<T>;
    childrenProperty: C;
  },
  prefix: string
): string {
  const workArray = [haystackTree];
  let item;
  let currentIndexer = 0;
  while ((item = workArray.shift()) != null) {
    const itemValue: string = item[config.indexProperty];
    if (itemValue.startsWith(prefix)) {
      const itemIdx = parseInt(itemValue.replace(prefix, ''), 10);
      if (currentIndexer < itemIdx) {
        currentIndexer = itemIdx;
      }
    }
    if (
      Object.prototype.hasOwnProperty.call(item, config.childrenProperty) &&
      Array.isArray(item[config.childrenProperty])
    ) {
      const childrenArray: Array<T> = item[config.childrenProperty];
      workArray.push(...childrenArray);
    }
  }
  return `${prefix}${currentIndexer + 1}`;
}
