<div id="headerDragger" class="headerDragger"></div>

<div class="grid">
  <isheader istag="header" [loading]="loading"></isheader>
  <ng-template #filter></ng-template>
  <isbody istag="body" class="gridbody" (scroll)="handleScroll()"></isbody>
  <isfooter istag="footer"></isfooter>
  <isfilterpanel></isfilterpanel>
</div>

<!--<isbody istag="body" class="gridbody" [style.flex]="1" (scroll)="handleScroll()"></isbody>-->

<!--<isfooter istag="footer"></isfooter>-->
