import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { EnumState } from '../isenum/enum.state';
import { EnumerationItem } from '../isenum/enum.model';
import { Store } from '@ngxs/store';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { IsgridComponent } from '../isgrid/isgrid.component';

@Component({
  selector: 'selection-grid',
  templateUrl: './selection-grid.component.html',
  styleUrls: ['./selection-grid.component.scss'],
})
export class SelectionGridComponent implements OnInit, AfterViewInit {
  @ViewChild('enum_grid', { static: true }) enum_grid: IsgridComponent;

  list: EnumerationItem[];
  selectedlist: EnumerationItem = null;
  temp: EnumerationItem[];
  sellist$: Observable<EnumerationItem[]>;
  a: Observable<EnumerationItem[]>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private store: Store,
    private dialogRef: MatDialogRef<SelectionGridComponent>,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    const a = this.store
      .select(EnumState.getenumlist)
      .pipe(map((filterFn) => filterFn(this.dialogData.enumeration)));

    this.sellist$ = a;
    a.subscribe((a) => {
      this.list = a;
      this.temp = a;
      if (this.dialogData != null) {
        if (this.dialogData.selectednr != null) {
          a.map((b) => {
            if (b.id === this.dialogData.selectednr) {
              if (this.selectedlist == null) {
                this.selectedlist = b;
              }
            }
          });
        }
        this.cd.detectChanges();
      }
    });
  }

  updateFilter(event) {
    if (event.key === 'Enter') {
      if (event.target.value !== '') {
        const a = this.store
          .select(EnumState.getenumlist)
          .pipe(map((filterFn) => filterFn(this.dialogData.enumeration)));

        a.pipe(
          map((a) => a.filter((a) => a.description === event.target.value))
        ).subscribe((b) => (this.list = b));
      } else {
        const a = this.store
          .select(EnumState.getenumlist)
          .pipe(map((filterFn) => filterFn(this.dialogData.enumeration)));

        a.subscribe((a) => {
          this.list = a;
          console.log('list', this.list);
        });
      }
    }
    const val = event.target.value.toLowerCase();

    this.list = this.temp.filter(function (d) {
      return (
        d.description.toLowerCase().indexOf(val) !== -1 ||
        !val ||
        d.id.toString().toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
  }
  select(event) {
    this.selectedlist = event.selected[0];
  }

  cancel() {
    this.selectedlist = null;
    this.dialogRef.close();
  }

  oke() {
    this.dialogData.formGroup
      .get(this.dialogData.idnr)
      .setValue(this.selectedlist.id);
    this.dialogData.formGroup
      .get(this.dialogData.field)
      .setValue(this.selectedlist.description);
    //this.store.dispatch(new this.dialogData.action(this.selectedlist));
    this.selectedlist = null;
    this.dialogRef.close();
  }
}
