import { EnumerationItem } from './enum.model';
import { Enumeration } from './enum.model';

export class LoadEnumlist {
  static readonly type = '[LoadEnumLists]';
  constructor(
    public payload: string,
    public id?: number,
    public account?: number,
    public loadoffline?: boolean,
    public typeenum?: string
  ) {}
}

export class ClearEnumList {
  static readonly type = '[ClearEnumList]';
}

export class addSelectionEnum {
  static readonly type = '[addSelectionEnum]';
  constructor(public payload: EnumerationItem[]) {}
}

export class setSelectionEnum {
  static readonly type = '[setSelectionEnum]';
  constructor(public payload: EnumerationItem[]) {}
}

export class clearSelectionEnum {
  static readonly type = '[clearSelectionEnum]';
  constructor(public payload: string) {}
}

export class addEnumFromInterclean {
  static readonly type = '[addEnumFromInterclean]';
  constructor(public payload: Enumeration) {}
}
