<div #wrapper>
  <svg #meter>
    <circle #outline_curves class="circle outline" cx="50%" cy="50%"></circle>

    <circle
      #low
      class="circle range"
      cx="50%"
      cy="50%"
      stroke="#FDE47F"
    ></circle>

    <circle
      #avg
      class="circle range"
      cx="50%"
      cy="50%"
      stroke="#7CCCE5"
    ></circle>

    <circle
      #high
      class="circle range"
      cx="50%"
      cy="50%"
      stroke="#E04644"
    ></circle>

    <circle #mask class="circle" cx="50%" cy="50%"></circle>

    <circle #outline_ends class="circle outline" cx="50%" cy="50%"></circle>
  </svg>
  <img #meter_needle src="gauge-needle.svg" alt="" />
  <input #slider type="range" min="0" max="100" value="0" />
  <label #lbl id="value" for="">{{ lblValue }}</label>
</div>
