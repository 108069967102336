export class IsConnectWebSocket {
  static readonly type = '[IsConnectWebSocket]';

  constructor(public payload: any | null) {}
}
export class IsEditorConnectWebSocket {
  static readonly type = '[IsEditorConnectWebSocket]';

  constructor(public payload: any | null) {}
}

export class WebSocketConnected {
  static readonly type = '[WSIC] Websocket Connected';

  constructor(public wscode: string) {}
}

export class WebSocketRegistered {
  static readonly type = '[WSIC] Websocket Registered';

  constructor(public wscode: string) {}
}

export class Ping {
  static readonly type = '[ping]';
  //constructor() {}
}
export class DisconnectIsWebsocket {
  static readonly type = '[WSIC] DisConnect';
  constructor(public manual: boolean) {}
}

export class ChannelRegistered {
  static readonly type = '[ChannelRegisterd]';
  constructor(public payload: string) {}
}

export class CheckwebSocketStatus {
  static readonly type = '[CheckWebSocketStatus]';
  constructor(public payload: any | null) {}
}

export class WebSocketDisconnected {
  static readonly type = '[WSIC] Websocket DisConnected';

  constructor(public wscode: string) {}
}

export class SubscribeChannel {
  static readonly type = '[WSIC] SubscribeChannel';

  constructor(public payload: string | any) {}
}

export class UnSubscribeChannel {
  static readonly type = '[WSIC] UnSubscribeChannel';

  constructor(public payload: string) {}
}

export class SetISSESSIONID {
  static readonly type = '[WSIC] SetISSESSIONID';

  constructor(public code: string) {}
}

export class SetWSPayload {
  static readonly type = '[WSIC] SetWsPayload';

  constructor(public payload: any) {}
}

export class SendMessage {
  static readonly type = '[WSIC] SendMessage';

  constructor(
    public message: string,
    public payload: any,
    public returnmessage: string,
    public channel?: string,
    public canbeusedindesigner?: boolean
  ) {}
}
