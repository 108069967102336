import { Overlay } from '@angular/cdk/overlay';
import { ComponentsService } from '@ic-builder/is-base';
import { IsGridRegistry } from './../isgrid.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'isheader',
  templateUrl: './isheader.component.html',
  styleUrls: ['./isheader.component.scss'],
})
export class IsheaderComponent implements AfterViewInit {
  @ViewChild('headerContainer', { read: ViewContainerRef })
  public headerContainer: ViewContainerRef;

  @Input() id: number;
  @Input() set visible(val: boolean) {
    this.setVisible(val);
  }
  private _visible = true;
  @Input() height = 20;
  @Input() loading: boolean = false;

  constructor(
    private isgrid: IsGridRegistry,
    private el: ElementRef,
    private comp: ComponentsService,
    private overlay: Overlay,
    private renderer: Renderer2
  ) {}

  setVisible(val: boolean) {
    if (!val) {
      this.el.nativeElement.setAttribute('hidden', '');
      this.renderer.setStyle(this.el.nativeElement, 'height', '0px');
    }
    this._visible = val;
  }

  setClass(headerclass) {
    this.el.nativeElement.classList.add(headerclass);
  }

  ngAfterViewInit(): void {
    //this.setHeaderinView();
    this.setHeaderinView();
    this.setVisible(this._visible);
  }

  setHeaderinView(): void {
    //if(!this.visible){
    this.isgrid.get(this.id).headerContainer = this.headerContainer;
    //}
  }

  //11117

  test() {
    debugger;
  }
}
