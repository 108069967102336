import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
} from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';

@Component({
  selector: 'menuhandleritem',
  templateUrl: './menuhandleritem.component.html',
  styleUrls: ['./menuhandleritem.component.scss'],
})
export class MenuhandleritemComponent implements AfterViewInit {
  constructor(
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private comp: ComponentsService
  ) {}

  label: string;
  children: any;
  level: number;
  icon: string;
  direction = 'vertical';
  id: number;
  parentid: number;
  phonemenu = false;

  applyStyle(styleMap: Map<number, Map<string, any>>) {
    let def = styleMap
      .get(this.id)
      .get(this.phonemenu ? 'phonemenuitem' : 'menuitem');
    let parent = this.comp.widgets.get(this.parentid);

    while (!def && parent && parent) {
      def = styleMap
        .get(parent.instance.id)
        .get(this.phonemenu ? 'phonemenuitem' : 'menuitem');
      const p = parent;
      parent = this.comp.widgets.get(parent.instance.parentid);
    }
    if (def) {
      styleMap.get(this.id).set('', def);
    }
  }

  ngAfterViewInit(): void {
    //this.el.nativeElement.setAttribute('istag','menuitem')
    this.cd.detectChanges();
  }
}
