import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'isclock',
  templateUrl: './isclock.component.html',
  styleUrls: ['./isclock.component.scss'],
})
export class IsclockComponent implements AfterViewInit {
  hourhand!: ElementRef;
  minutehand!: ElementRef;
  sechand!: ElementRef;
  clockbackground!: HTMLElement;
  
  @Input() time:string='06:00:05';

  constructor(private el: ElementRef) {}

  rotate(el, deg) {
    el.setAttribute('transform', 'rotate(' + deg + ' 50 50)');
  }

  ngAfterViewInit(){
   this.hourhand = this.el.nativeElement.querySelector('#hourhand');
   this.minutehand = this.el.nativeElement.querySelector('#minutehand');
   this.sechand = this.el.nativeElement.querySelector('#sechand');
   this.clockbackground = this.el.nativeElement.querySelector('#clockbackground')
   
   this.setTime(new Date());
  }

  setTime(time) {
    // const d = new Date();
    // d.setHours(8, 10, 20);
    this.rotate(this.sechand, 6 * time.getSeconds());
    this.rotate(this.minutehand, 6 * time.getMinutes());
    this.rotate(this.hourhand, 30 * (time.getHours() % 12) + time.getMinutes() / 2);
  }
}
