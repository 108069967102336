<ng-template #vc></ng-template>

<!--<ng-template #pagecontainer style="height:100%;width:100%;display:flex"></ng-template>-->

<!--     [style.width] = "width ? width:'100%'"
    [style.height] = "'40px'"
    style="background-color: var(--500);"
    [style.flex-basis]="height" -->
<outlet
  [id]="id"
  height="100%"
  style="overflow-y: auto; overflow-x: hidden; height: 100%; display: block"
></outlet>

<ng-template
  #menu
  let-height="height"
  let-width="width"
  let-class="class"
  let-display="display"
  let-fontsize="fontsize"
>
  <div style="width: 100%; height: 40px; top: 0; display: flex">
    <div
      [class]="class ? class : 'pg-tree-node-final'"
      style="flex-grow: 1"
      [id]="node.id"
      *ngFor="let node of menuds"
      (click)="click(node)"
    >
      {{ node.description }}
      <!-- </mat-icon> -->
    </div>

    <!-- <cdk-tree [dataSource]="ds" [treeControl]="treeControl" >
        <cdk-nested-tree-node
        *cdkTreeNodeDef="let node"
        [class]="class ?class:'pg-tree-node-final'"
        [style.display]="display"
        style="height:80%"
        (click)="click(node)">
          <div style="display:flex;flex-direction: row;height:100%;width:100px" >
            <mat-icon style="width:20%;
            font-size: 24px"

            >{{node.icon}}</mat-icon>
            <div style="width:50%;text-align: center;vertical-align: middle"
            [style.font-size]="fontsize? fontsize:'14px'"
            ><label>{{node.description}}</label></div>
          </div>
        </cdk-nested-tree-node>
      </cdk-tree> -->
  </div>
</ng-template>
