import { Inject, Injectable, isDevMode } from '@angular/core';
import { LibraryOptions, OPTIONS } from './data-access-iscloud.module';


@Injectable()
export class ConfigService {
  production: boolean;

  constructor(@Inject(OPTIONS) options: LibraryOptions) {
    this.production = options.production;
  }

  getDbUrl(protocol: string) {
    //if (isDevMode()) {
      if (!this.production){
      //console.log('debug intercleancloud');
      if (protocol == 'wseditor') {
        console.log('ws://demo.u3p.nl');
        return 'ws://demo.u3p.nl';
        //}
        //if (protocol == 'ws') {
        //  console.log('ws://localhost:8094');
        //  return 'ws://localhost:8094';
      } else {
        if (document.location.port == '4200') {
          return protocol == 'ws' ? 'ws://localhost:7072' : '/api';
        } else if (document.location.port == '4201') {
          return protocol == 'ws' ? 'ws://localhost:7070' : '/api1';
        } else if (document.location.port == '4202') {
          return protocol == 'ws' ? 'ws://localhost:8199' : '/api2';
        } else if (document.location.port == '4203') {
          return protocol == 'ws' ? 'ws://localhost:8094' : '/api3';
        } else if (document.location.port == '4204') {
          return protocol == 'ws' ? 'ws://localhost:8090' : '/api4';
        } else if (document.location.port == '4205') {
          return protocol == 'ws' ? 'ws://localhost:8095' : '/api5';
        } else if (document.location.port == '4206') {
          return protocol == 'ws' ? 'ws://cleanpower.u3p.nl/' : '/api6';
        } else if (document.location.port == '4207') {
          return protocol == 'ws' ? 'wss://portal.group-f.be/' : '/api7';
        } else {
          console.log('sourceMap debug intercleancloud');
          return (
            document.location.protocol +
            '//' +
            document.location.host +
            ':' +
            document.location.port
          );
        }
        //return document.location.protocol+'//'+document.location.host;

        //return document.location.protocol+'//facility.u3p.nl';
        //return document.location.protocol+'//eds.intersoft.solutions';
      }
    } else {
      //console.log('production intercleancloud');
      if (protocol == 'ws') {
        //console.log('ws://' + document.location.host);
        return 'ws://' + document.location.host;
      } else if (protocol == 'wss') {
        //console.log('wss://' + document.location.host);
        return 'wss://' + document.location.host;
      } else {
        //console.log(document.location.protocol + '//' + document.location.host);
        return document.location.protocol + '//' + document.location.host;
      }
    }
  }
}
