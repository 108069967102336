import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ischeckbox',
  templateUrl: './ischeckbox.component.html',
  styleUrls: ['./ischeckbox.component.scss'],
})
export class IscheckboxComponent
  extends IscontrolComponent
  implements OnInit, AfterViewInit
{
  @Input() label: string;
  @Input() initialValue = false;

  controlid: number;
  formControl: FormControl;
  translation: any | null = null;

  constructor(
    comp: ComponentsService,
    public outlet: Outlet2Service,
    private compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput) {
      if (this.id) {
        this.controlid = super.initControl(
          this.id.toString() + '.checkbox',
          this.initialValue,
          'checkbox'
        );
        // this.controlname = this.controlid;
        this.formControl = super.getFormControl(this.controlid);
      }
    } else {
      this.formControl = new FormControl(this.initialValue);
    }
  }

  ngAfterViewInit() {
    if (this.formInput) {
      if (!this.formControl && this.id) {
        this.controlid = super.initControl(
          this.id.toString() + '.checkbox',
          this.initialValue,
          'checkbox'
        );
        // this.controlname = this.controlid;
        this.formControl = super.getFormControl(this.controlid);
      }
    }
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](this.formControl.value);
        });
      }
    }
  }

  controlnameValueChanges(newVal) {
    this.compserv.formcontrols.get(this.controlid.toString()).name = newVal;
  }

  translate(lang){
    if (this.translation){
      this.label = this.translation[lang];
    }
  }
}
