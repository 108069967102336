<!--<form [formGroup]="frmGrp" class="row" [style.visibility]="(visFunc(wizGrp))? 'visible':'hidden'">-->
<div *ngIf="label" istag="label" class="label" id="labeltxt">
  {{ label }}
</div>

<input
  *ngIf="!multiline"
  (focus)="makeValid()"
  istag="input"
  ng-valid
  class="content input"
  autocomplete="off"
  [disabled]="disabled"
/>
<textarea
  *ngIf="multiline"
  (focus)="makeValid()"
  istag="input"
  ng-valid
  class="input"
  autocomplete="off"
></textarea>
<mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
<mat-icon
  istag="warning"
  *ngIf="warning"
  style="color: orange"
  (mouseenter)="openwarning($event)"
  (mouseleave)="closewarning()"
  >warning</mat-icon
>
<!--<input disabled *ngIf="!controlname" istag="input" class="content input">-->
<!--</form>-->

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
