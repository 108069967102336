<!-- zero -->
<svg class="zero">
  <text x="25%" y="75%">0</text>
</svg>

<!-- y-as -->
<svg class="y-as"></svg>

<!--plot area-->
<svg class="plot-area">
  <line x1="0%" y1="0%" y2="100%" x2="0%" class="line"></line>
  <line x1="0%" y1="100%" y2="100%" x2="100%" class="line"></line>

  <svg
    *ngFor="let stack of stacked"
    [attr.x]="stack.getX()"
    [attr.y]="stack.getY()"
    [attr.width]="stack.getWidth()"
    [attr.height]="stack.getHeight()"
  >
    <rect
      *ngFor="let s of stack.bars; let i = index"
      class="bar"
      [attr.y]="s.getTop()"
      [attr.height]="s.getPercentage()"
      x="10%"
      width="80%"
      [attr.fill]="_usedKeys[i].color"
    ></rect>
  </svg>
</svg>

<!-- x-as -->
<svg class="x-as">
  <svg
    *ngFor="let stack of stacked"
    height="100%"
    [attr.x]="stack.getX()"
    [attr.width]="stack.getWidth()"
  >
    <text x="50%" y="50%" text-anchor="middle">{{ stack.position }}</text>
  </svg>
</svg>

<!--title-->
<span class="title" *ngIf="title">{{ title }}</span>

<!--legend-->
<div class="legend">
  <span
    class="color-indication"
    *ngFor="let uk of _usedKeys"
    [style.background-color]="uk.color"
  ></span>
  <span style="grid-column-start: -2" *ngFor="let uk of _usedKeys">{{
    uk.description
  }}</span>
</div>
