import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { IsyearplannerService } from '../isyearplanner/isyearplanner.service';
import { trimCharsEnd } from 'lodash/fp';

const PeriodType = {
  Week: 'week' as 'week',
  Month: 'month' as 'month',
  Year: 'year' as 'year',
};

type PeriodType = typeof PeriodType[keyof typeof PeriodType];
type Period = { number: number; desc: string; from: Date; until: Date };
export { PeriodType };

@Component({
  selector: 'ic-builder-isperiodselector',
  templateUrl: './isperiodselector.component.html',
  styleUrls: ['./isperiodselector.component.scss'],
})
export class IsperiodselectorComponent extends IscontrolComponent implements OnInit {
  @Input() initialValue = PeriodType.Week;
  @Input() periodType: PeriodType;

  controlid:number;
  controlidFrom: number;
  controlidUntil: number;
  controlidPeriod: number;
  controlidFromfdate : number;
  controlidUntilfdate : number;
  formControl:FormControl;
  formControlfrom: FormControl;
  formControlfromdate: FormControl;
  formControluntil: FormControl;
  formControluntildate: FormControl;
  formControlPeriod: FormControl;
  nbrPeriod: number;
  nbrRows: number;
  periods: Array<Period> = [];
  fromDate: Date;
  untilDate: Date;
  prevselitem: Element;
  period: PeriodType;
  onselectPeriod: Function;

  get fromdateval(){
    return this.formControlfromdate.value;
  }

  get untildateval(){
    return this.formControluntildate.value;
  }

  constructor(
    public comp: ComponentsService,
    public store: Store,
    public outlet: Outlet2Service,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef,
    private yearplannerservice: IsyearplannerService
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput) {
      this.controlidFrom = super.initControl(this.id.toString() + '.from', null, 'from');
      this.controlidUntil = super.initControl(this.id.toString() + '.until', null, 'until');
      this.controlidPeriod = super.initControl(this.id.toString() + '.week', null, 'week');
      this.controlid = super.initControl(this.id.toString() + '.period', null, 'period');
      this.controlidFromfdate = super.initControl(this.id.toString() + '.fromdate', this.initialValue, 'fromdate');
      this.controlidUntilfdate = super.initControl(this.id.toString() + '.untildate', this.initialValue, 'untildate');

      this.formControl = super.getFormControl(this.controlid);
      this.formControlfrom = super.getFormControl(this.controlidFrom);
      this.formControlfromdate = super.getFormControl(this.controlidFromfdate);
      this.formControluntil = super.getFormControl(this.controlidUntil);
      this.formControluntildate = super.getFormControl(this.controlidUntilfdate);
      this.formControlPeriod = super.getFormControl(this.controlidPeriod);
    }
    this.setNbrs(new Date().getFullYear());
  }

  setPeriod(period,el?:Element){
    this.formControlPeriod.setValue(period.from.getFullYear() * 100 + period.number);
    this.formControl.setValue(period);
    this.period = period;
    this.formControlfrom.setValue(period.from.toString());
    this.formControluntil.setValue(period.until.toString());
    const dt = new Date(this.formControlfrom.value);
    this.formControlfromdate.setValue((period.from.getMonth() + 1).toString() + '/' + period.from.getDate().toString() + '/' + period.from.getFullYear());
    this.formControluntildate.setValue((period.until.getMonth() + 1).toString() + '/' + period.until.getDate().toString() + '/' + period.until.getFullYear());
    if (el) {
      if (this.prevselitem) {
        this.prevselitem.classList.remove('is-' + this.id + '-selpi');
        this.prevselitem.classList.add('is-' + this.id + '-pi');
      }
      
      el.classList.remove('is-' + this.id + '-pi');
      el.classList.add('is-' + this.id + '-selpi');
      this.prevselitem = el;
      this.cd.detectChanges();
      
      if (this.onselectPeriod) {
        this.onselectPeriod(period);
      }
    }
  }

  selectPeriod(ev, period) {
    this.setPeriod(period,ev.currentTarget);   

    // if (this.prevselitem) {
    //   this.prevselitem.classList.remove('is-' + this.id + '-selpi');
    //   this.prevselitem.classList.add('is-' + this.id + '-pi');
    // }


    // ev.currentTarget.classList.remove('is-' + this.id + '-pi');
    // ev.currentTarget.classList.add('is-' + this.id + '-selpi');
    // this.prevselitem = ev.currentTarget;
    // this.cd.detectChanges();
    // if (this.onselectPeriod) {
    //   this.onselectPeriod(period);
    // }

    console.log('ev : ', ev);
    //this.el.nativeElement.querySelector('[data-index]=');
  }

  getWkNumber(date) {
    const d = new Date(date.getTime());
    d.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    d.setDate(d.getDate() + 3 - ((d.getDay() + 6) % 7));
    // January 4 is always in week 1.
    const week1 = new Date(d.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((d.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
  }

  addDays(day,days){
    let date = new Date(day);
    date.setDate(date.getDate() + days);
    return date;
  }

  createWeekPeriod(year: number) {
    const startyear = new Date(year, 0, 1);
    const endYear = new Date(year, 11, 31);

    let startweek = this.getWkNumber(startyear);

    let endWeek = this.getWkNumber(endYear);
    if (startweek==endWeek) {
      endWeek = 52
    }
    const weeks = [];
    if (startweek > 51) {
      weeks.push({
        number: startweek,
        desc:startweek.toString(),
        from: this.yearplannerservice.getDateOfISOWeek(startweek, year - 1),
        until: null,
      });
      weeks[0].until = this.addDays(weeks[0].from,6);
      startweek = 1;
    }
    for (let i = startweek; i <= endWeek; i++) {
      const wk = { number: i, desc:i.toString(), from: this.yearplannerservice.getDateOfISOWeek(i, year), until: null };
      wk.until = this.addDays(wk.from,6); 
      weeks.push(wk);
    }
    console.log('isperiodselector : ', weeks);
    return weeks;
  }

  createMonthPeriod(year) {
    const amountOfDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const names = [];
    const months = [];
    for (let i = 1; i <= 12; i++) {
      const from = new Date(year, i - 1, 1);
      const month = {
        number: i,
        desc: from.toLocaleString(navigator.language, { month: 'long' }),
        from: new Date(year, i - 1, 1),
        until: new Date(year, i - 1, amountOfDays[i]),
      };
      months.push(month);
    }
    return months;
  }

  createYearPeriod(year) {
    return [{ number: year, desc: year.toString(), from: new Date(year, 0, 1), until: new Date(year, 11, 31) }];
  }

  setNbrs(year: number) {
    if (this.periodType == PeriodType.Week) {
      this.nbrPeriod = 52;
      this.nbrRows = 2;
      this.periods = this.createWeekPeriod(year);
    }

    if (this.periodType == PeriodType.Month) {
      this.nbrPeriod = 12;
      this.nbrRows = 1;
      this.periods = this.createMonthPeriod(year);
    }

    if (this.periodType == PeriodType.Year) {
      this.nbrPeriod = 1;
      this.nbrRows = 1;
      this.periods = this.createYearPeriod(year);
    }
  }
}
