import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'ic-builder-donut',
  templateUrl: './donut.component.html',
  styleUrls: ['./donut.component.scss'],
})
export class DonutComponent implements AfterViewInit {
  constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

  private _pathLength: number = 0;

  @Input() showLegend: boolean = true;
  @Input() title: string = '';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateCircles();
  }

  colorDefault = 209;

  set data(d: any) {
    this.selectedIndex = -1;

    // remove zero values
    d = d.filter((dt: any) => dt.value > 0);

    const total = d
      .map((dt: any) => dt.value)
      .reduce((cur: number, prev: number) => {
        return prev + cur;
      }, 0);

    let count = 0;

    d = d.map((dt: any) => {
      const perc = 20 + (count / (d.length - 1)) * 60;
      count += 1;

      return {
        ...dt,
        percentage: dt.value / total,
        color: `hsl(${this.colorDefault}, 100%, ${100 - perc}%)`,
      };
    });

    this._data = d;

    this.calculateCircles();
  }

  private _data: any[] = [];

  get data() {
    return this._data;
  }

  showData() {}

  selectedIndex: number = -1;

  setFocus(i: number) {
    let counter;

    if (this.selectedIndex != i) {
      counter = 0;

      for (let el of this.el.nativeElement.querySelectorAll('#legend-color')) {
        if (i == counter) {
          el.classList.add('focused');
          el.classList.remove('unfocused');
        } else {
          el.classList.remove('focused');
          el.classList.add('unfocused');
        }

        counter += 1;
      }

      counter = 0;

      for (let el of this.el.nativeElement.querySelectorAll('.circle')) {
        if (i == counter) {
          el.classList.add('focused');
          el.classList.remove('unfocused');
        } else {
          el.classList.remove('focused');
          el.classList.add('unfocused');
        }

        counter += 1;
      }
      this.selectedIndex = i;
    } else {
      for (let el of this.el.nativeElement.querySelectorAll('#legend-color')) {
        el.classList.remove('focused');
        el.classList.remove('unfocused');
      }
      for (let el of this.el.nativeElement.querySelectorAll('.circle')) {
        el.classList.remove('focused');
        el.classList.remove('unfocused');
      }
      this.selectedIndex = -1;
    }
  }

  ngAfterViewInit(): void {
    var ro = new ResizeObserver((entries) => {
      this.calculateCircles();
    });

    // Observe one or multiple elements
    ro.observe(this.el.nativeElement);
  }

  squares = [
    { color: 'red', value: 0.025 },
    { color: 'blue', value: 0.95 },
    { color: 'green', value: 0.025 },
  ];

  getStrokeWidth(used: number, value: number) {
    const before = (used * this._pathLength).toString();
    const length = (value * this._pathLength).toString();
    let after = (1 - used - value) * this._pathLength;

    after = after < 0.0001 ? 0 : after;

    return '0 ' + before + ' ' + length + ' ' + after.toString();
  }

  calculateCircles() {
    const info = this.el.nativeElement.getBoundingClientRect();
    console.log(info);

    if (info.height > info.width) {
      this.el.nativeElement.style.setProperty(
        '--heightsize',
        info.width.toString() + 'px'
      );
      //   this.el.nativeElement.style.setProperty('--heightpadding',((info.height-info.width)/2).toString()+'px')
      //   this.el.nativeElement.style.setProperty('--widthpadding','0px')
    } else {
      this.el.nativeElement.style.setProperty(
        '--widthsize',
        info.height.toString() + 'px'
      );
      //   this.el.nativeElement.style.setProperty('--widthpadding',((info.width-info.height)/2).toString()+'px')
      //   this.el.nativeElement.style.setProperty('--heightpadding','0px')
    }

    this.cd.detectChanges();

    const circle = this.el.nativeElement.querySelector('#default');
try {
    this._pathLength = circle.getTotalLength();
} catch(err) {
    console.log('circle is not rendered');
}    

    const circles = this.el.nativeElement.querySelectorAll('.circle');

    let used = 0;

    for (let i = 0; i < this.data.length; i++) {
      try{
      const result = this.getStrokeWidth(used, this.data[i].percentage);
      circles[i].style['stroke-dasharray'] = result;
      } catch(err) {
          console.log(err);
      }
      used += this.data[i].percentage;
    }

    this.cd.detectChanges();
  }

  afterInsertInDom() {
    this.calculateCircles();
  }
}
