import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'istaginput',
  templateUrl: './istaginput.component.html',
  styleUrls: ['./istaginput.component.scss'],
})
export class IstaginputComponent
  extends IscontrolComponent
  implements OnInit, OnDestroy
{
  @Input() label: string;
  @Input() options: string[];
  @Input() initialValue: string;

  // @Output() tagRemove: EventEmitter<string> = new EventEmitter();
  // @Output() tagAdd: EventEmitter<string> = new EventEmitter();

  // @Input() tags:string[] = [];
  tags: string[] = [];
  localGroup: FormGroup = new FormGroup({});

  constructor(
    comp: ComponentsService,
    public store: Store,
    public outlet: Outlet2Service,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    // super.addControls([this.controlname], null)
    this.localGroup.addControl('tempval', new FormControl());
    // super.addControlsNew([{controlname: this.controlname, initialValue: (this.initialValue ? this.initialValue : null), disabled: false}]);
  }

  ngOnDestroy() {
    // super.destroyControls([this.controlname]);
  }

  externalAdd(value) {
    // this.tagAdd.emit(value);
    this.cd.detectChanges();
  }

  keydown(event) {
    if (event.key == 'Enter') {
      event.preventDefault();
      this.tags.push(this.localGroup.get('tempval').value);
      this.localGroup.get('tempval').setValue(null);
      // this.frmGrp.get(this.controlname).setValue(this.tags);
      this.cd.detectChanges();
    }
  }

  removeTag(tag) {
    this.tags = this.tags.filter((t) => t !== tag);
    // this.frmGrp.get(this.controlname).setValue(this.tags);
    // this.tagRemove.emit(tag);
    this.cd.detectChanges();
  }

  onAttach() {
    // let temp = this.frmGrp.get(this.controlname).value;
    // if (temp != "") {
    //   this.tags = temp;
    //   this.cd.detectChanges();
    // }
  }

  onDetach() {
    this.tags = [];
    this.cd.detectChanges();
  }

  click() {
    console.log('test');
  }
}
