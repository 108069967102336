import { Component } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadComponent } from '@ic-builder/is-base';

@Component({
  selector: 'ic-builder-form-viewer',
  templateUrl: './form-viewer.component.html',
  styleUrls: ['./form-viewer.component.scss'],
})
export class FormViewerComponent {
  constructor(public activatedRoute: ActivatedRoute, private store: Store) {
    activatedRoute.params.subscribe((params) => {
      console.log(
        `%cClass: FormViewerComponent, Function: activatedRoute.params(params): `,
        'color: green;',
        params,
        'route:',this.activatedRoute
      );
      if (params.cid) {
        store.dispatch(
          new LoadComponent({
            id: params.cid,
            name: '',
            outlet: 0,
            route: this.activatedRoute,
          })
        );
      }
    });
  }
}
