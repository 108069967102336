import {
  Component,
  OnInit,
  ElementRef,
  QueryList,
  ViewChildren,
  ViewChild,
  Renderer2,
  Input,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'speedometer',
  templateUrl: './speedometer.component.html',
  styleUrls: ['./speedometer.component.scss'],
})
export class SpeedometerComponent implements OnInit, AfterViewInit {
  r = 250;
  cf = 2 * Math.PI * this.r;
  semi_cf = this.cf / 2;
  semi_cf_1by3 = this.semi_cf / 3;
  semi_cf_2by3 = this.semi_cf_1by3 * 2;
  meter_dimension = this.r * 2 + 100;
  lblValue: string;

  @Input('percentage') percentage: number;

  public circles: ElementRef<any>[];
  @ViewChild('wrapper') public wrapper: ElementRef;
  @ViewChild('low') public low: ElementRef;
  @ViewChild('avg') public avg: ElementRef;
  @ViewChild('high') public high: ElementRef;
  @ViewChild('outline_curves') public outline_curves: ElementRef;
  @ViewChild('outline_ends') public outline_ends: ElementRef;
  @ViewChild('mask') public mask: ElementRef;
  @ViewChild('lbl') public lbl: ElementRef;
  @ViewChild('meter-needle') public meterneedle: ElementRef;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.circles = this.el.nativeElement.getElementsByTagName('circle');
    const total_circles = this.circles.length;
    for (let i = 0; i < total_circles; i++) {
      this.renderer.setAttribute(this.circles[i], 'r', this.r.toString());
    }
    const meter_dimension = this.r * 2 + 100;
    /* Set meter's wrapper dimension */

    this.renderer.setStyle(
      this.wrapper.nativeElement,
      'width',
      this.meter_dimension + 'px'
    );
    this.renderer.setStyle(
      this.wrapper.nativeElement,
      'height',
      this.meter_dimension + 'px'
    );

    /* Add strokes to circles  */
    this.renderer.setAttribute(
      this.outline_curves.nativeElement,
      'stroke-dasharray',
      this.semi_cf + ',' + this.cf
    );
    this.renderer.setAttribute(
      this.low.nativeElement,
      'stroke-dasharray',
      this.semi_cf + ',' + this.cf
    );
    this.renderer.setAttribute(
      this.avg.nativeElement,
      'stroke-dasharray',
      this.semi_cf_2by3 + ',' + this.cf
    );
    this.renderer.setAttribute(
      this.high.nativeElement,
      'stroke-dasharray',
      this.semi_cf_1by3 + ',' + this.cf
    );
    this.renderer.setAttribute(
      this.outline_ends.nativeElement,
      'stroke-dasharray',
      2 + ',' + (this.semi_cf - 2)
    );
    this.renderer.setAttribute(
      this.mask.nativeElement,
      'stroke-dasharray',
      this.semi_cf + ',' + this.cf
    );
  }

  range_change_event() {
    const meter_value = this.semi_cf - (this.percentage * this.semi_cf) / 100;
    this.renderer.setAttribute(
      this.mask.nativeElement,
      'stroke-dasharray',
      meter_value + ',' + this.cf
    );
    this.renderer.setStyle(
      this.meterneedle,
      'transform',
      'rotate(' + (270 + (this.percentage * 180) / 100) + 'deg)'
    );
    this.lblValue = this.percentage + '%';
  }
}
