import Dexie, { Table } from 'dexie';
import { IIsComponent } from './iis-component.interface';

export interface Item {
  id?: number;
  name: string;
}

export interface ComponentList {
  pid?: number;
  hash: undefined | string;
  name: undefined | string;
  components: IIsComponent[];
}

export interface Datasets {
  name: string;
  definition: any;
  records: any[];
}

export interface Enumerations {
  name: string;
  records: any[];
}

export interface DbAsset {
  path: string;
  content: string;
  hash: string;
}

export class AppDB extends Dexie {
  componentLists!: Table<ComponentList, number>;
  datasets!: Table<Datasets, string>;
  enumerations!: Table<Enumerations, string>;
  dbassets!: Table<DbAsset, string>;

  constructor() {
    super('u3pdb');
    this.version(4).stores({
      componentLists: 'pid',
      datasets: 'name',
      enumerations: 'name',
      dbassets: 'path'
    });
  }

  async resetDatabase() {
    await db.transaction('rw', 'datasets', 'componentLists','enumerations', 'dbassets',() => {
      this.datasets.clear();
      this.componentLists.clear();
      this.enumerations.clear();
      this.dbassets.clear();
    });
  }
}

export const db = new AppDB();
