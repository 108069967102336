import { Type } from 'class-transformer';
import 'reflect-metadata';

export interface IIsMenuItem {
  id?: number;
  parentid?: number;
  name: string;
  description: string;
  icon?: string;
  outlet?: number;
  action?: string;
  payload?: any;
  default?: number;
  children?: IIsMenuItem[];
  from?: any;
  until?: any;
}

export class IsMenuItem implements IIsMenuItem {
  id?: number;
  parentid?: number;
  name: string;
  description: string;
  icon?: string;
  outlet?: number;
  action?: string;
  payload?: any;
  default?: number;
  function?: string;
  menutype?: string;
  contextid?: number;
  @Type(() => IsMenuItem)
  children?: IsMenuItem[];

  constructor(values?: any) {
    if (values) {
      if (values.id != null) {
        this.id = values.id;
      }
      this.name = values.name;
      this.description = values.description;
      if (values.default != null) {
        this.default = values.default;
      }
      if (values.payload) {
        this.payload = values.payload;
      }
      if (values.action) {
        this.action = values.action;
      }
      if (values.parentid != null) {
        this.parentid = values.parentid;
      }
      if (values.icon) {
        this.icon = values.icon;
      }
      if (values.outlet) {
        this.outlet = values.outlet;
      }
      if (values.config) {
        if (values.config.default != null) {
          this.default = values.config.default;
        }
        if (values.config.description) {
          this.description = values.config.description;
        }
        if (values.config.payload) {
          this.payload = values.config.payload;
        }
        if (values.config.function) {
          this.function = values.config.function;
        }
        if (values.config.menutype) {
          this.menutype = values.config.menutype;
        }
        if (values.config.contextid) {
          this.contextid = values.config.contextid;
        }
      }
    }
    //this.children = values.children !== undefined ? toMenuTreeList(values.children) : undefined;
  }
}

//function toMenuTreeList(values: IIsMenuItem[]): IsMenuItem[] {
//    return values.map(tree => new IsMenuItem(tree));
//  }
