<!--<div class="row">-->
<div istag="label" class="label" id="labeltxt" *ngIf="hasLabel">
  {{ label }}
</div>

<ng-container *ngIf="!multiselect">
  <input
    *ngIf="hasID"
    istag="number_input"
    [type]="enumtype"
    ng-valid
    (keydown)="findDesc($event)"
    class="number_input"
    [formControl]="formControlID"
    autocomplete="off"
  />
  <input
    *ngIf="hasDescription"
    istag="desc_input"
    spellcheck="false"
    list="listname"
    class="desc_input"
    [formControl]="formControlDesc"
    autocomplete="off"
  />
</ng-container>

<ng-container *ngIf="multiselect">
  <div istag="input" class="tagsinput" *ngIf="(!datax && !multiselect) || ((selectedOptions?.length  < datax?.length) && (selectedOptions?.length>0))">
    <div istag="tag" class="tag" *ngFor="let tag of selectedOptions">
      <span>{{ tag.description }}</span>
      <button class="button material-icons" (click)="removeTag(tag)">close</button>
    </div>
  </div>
  <div istag="input" class="tagsinput" *ngIf="(!datax && multiselect) || selectedOptions.length == datax?.length">
    <span istag="allselected">All Selected</span>
  </div>
  <div istag="input" class="tagsinput" *ngIf="(datax && multiselect) && selectedOptions.length == 0">
    <span istag="noneselected">None Selected</span>
  </div>
</ng-container>

<datalist [id]="listname" *ngIf="enumerationlist$ | async as enumeration">
  <option *ngFor="let enum of enumeration" [value]="enum.description"></option>
</datalist>

<button istag="button_dots" class="button button_dots" (click)="button()">
  <mat-icon>more_horiz</mat-icon>
</button>
<button istag="button_x" class="button button_x" (click)="reset()">
  <mat-icon>clear</mat-icon>
</button>
<mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
<mat-icon
  istag="warrning"
  *ngIf="warning"
  style="color: orange"
  (mouseenter)="openwarning($event)"
  (mouseleave)="closewarning()"
  >warning
</mat-icon>
<!--</div>-->

<ng-template #grid >
  <div *ngIf="mobile" style="width:100%;height:30px;display:flex;flex-direction:row" [formGroup]="searchGroup">
     <input type="number" style="width:75px;height:26px" formControlName="searchID" #searchID>
     <input style="flex-grow:1;height:26px" formControlName="searchDesc" #searchDesc/>
  </div>
  <isgrid
    [selectCheckbox]="multiselect"
    [id]="id"
    [columns]="columns"
    [editmode]="false"
    [datamode]="4"
    [footervisible]="false"
    [ngClass]="gridclass"
    (afterview)="afterview()"
    (dblclick)="dblclick($event)"
    istag="grid"
  ></isgrid>
  <div style="height: 40px; display: flex">
    <isbutton text="OK" (click)="gridButtonOK()" istag="okbutton"></isbutton>
    <isbutton text="Cancel" (click)="gridButtonCancel()" istag="cancelbutton"></isbutton>
  </div>
</ng-template>

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
