import { IsGridRegistry } from './../../isgrid.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoadEnumlist } from '../../../isenum/enum.action';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { EnumState } from '../../../isenum/enum.state';

@Component({
  selector: 'editconfig',
  templateUrl: './editconfig.component.html',
  styleUrls: ['./editconfig.component.scss'],
})
export class EditconfigComponent implements OnInit {
  @ViewChild('configPopUp', { read: TemplateRef })
  configPopUp: TemplateRef<any>;

  constructor(
    private dialog: MatDialog,
    private el: ElementRef,
    private gridReg: IsGridRegistry,
    private store: Store
  ) {}

  dialogRef: MatDialogRef<any> | null = null;

  //columns = [{ name: 'property' }, { name: 'static', bodycomp: 'ischeckboxcel' }, { name: 'data', edit: true }];

  columns = [
    { name: 'id', label: 'No', width: '25%' },
    { name: 'description', label: 'Libelle', width: '75%' },
  ];

  id: number = -1100;
  enum;
  dataObject;
  formControl: FormControl = new FormControl();

  ngOnInit(): void {
    this.store.dispatch(new LoadEnumlist('FORM', null, 10));

    this.formControl.valueChanges.subscribe((f) => {
      const columneditor = this.gridReg.get(-1008);
      columneditor.selectedRow.trackby = f.includes('form')
        ? columneditor.selectedRow.trackby
        : null;
    });
  }

  openconfig() {
    this.dialogRef = this.dialog.open(this.configPopUp);

    this.dialogRef.afterOpened().subscribe(() => {
      const gridref = this.gridReg.get(this.id);
      gridref.removedataset = false;

      const enum$ = this.store.select(EnumState.getenumlist).pipe(
        map((filterFn) => {
          const d = filterFn('FORM');
          return d;
        })
      );

      const sub = enum$.subscribe((d) => {
        gridref.loadData({
          data: d,
          params: {},
          definition: this.columns ? 0 : 1,
          columns: this.columns,
          rowfunctions: null,
          account: null,
          masterdetail: null,
          master: null,
          fixparams: null,
        });
      });

      if (this.gridReg.get(-1008).selectedRow.trackby) {
        gridref.setInitalRow('id', this.gridReg.get(-1008).selectedRow.trackby);
      }

      sub.unsubscribe();
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.el.nativeElement.focus();
    });
  }

  afterview() {
    // const gridref = this.gridReg.get(this.id);
    // gridref.removedataset = false;
    // gridref.loadData({
    //   //dsname: this.enum,
    //   data: [{id:1,description:'test'}],
    //   params: {},
    //   definition: this.columns ? 0 : 1,
    //   columns: this.columns,
    //   rowfunctions: null,
    //   account: null,
    //   masterdetail: null,
    //   master: null,
    //   fixparams: null,
    // });
  }

  trackby: number;

  gridButtonOK() {
    const selected = this.gridReg.get(this.id).selectedRow;

    const columneditor = this.gridReg.get(-1008);
    columneditor.selectedRow.trackby = selected.id;
    this.trackby = selected.id;
    this.formControl.setValue('isform');

    this.dialogRef.close();
  }

  gridButtonCancel() {
    this.dialogRef.close();
  }
}
