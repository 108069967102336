/* eslint-disble no-alert */
import { EnumState, EnumerationItem } from '@ic-builder/iscomp';
import { FormGroup, FormControl } from '@angular/forms';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { any } from 'lodash/fp';
import { ComponentsService, IslanguageService } from '@ic-builder/is-base';
export interface expressieValue {
  value: string;
  label: string;
  translation: string;
  allowed: string[];
  template: string;
  func: any;
}
@Component({
  selector: 'isexpcontainer',
  templateUrl: './isexpcontainer.component.html',
  styleUrls: ['./isexpcontainer.component.scss'],
})
export class IsexpcontainerComponent implements AfterViewInit {
  constructor(private el: ElementRef, private cd: ChangeDetectorRef, private comp:ComponentsService ,private store: Store, private languages:IslanguageService) {}

  datatype: string = '';

  @Input() index: number;

  @ViewChild('valueContainer', { read: ViewContainerRef }) valueContainer;

  @ViewChild('stringInput', { read: TemplateRef }) stringInput;
  @ViewChild('numberInput', { read: TemplateRef }) numberInput;
  @ViewChild('dateInput', { read: TemplateRef }) dateInput;
  @ViewChild('isEnum', { read: TemplateRef }) isEnum;

  activeColumn: any = null;
  selectedexpression: any;

  activeFunction = null;
  cname = 'container';

  columnOptions: any[] = [];

  formGroup = new FormGroup({
    kolom: new FormControl(''),
    expressie: new FormControl(''),
    value: new FormControl(''),
  });

  formControl = new FormControl('');
  lblExpression:string;
  lblField:string;
  lblValue:string;

  equal = (row: any) => {
    return row[this.activeColumn?.value]?.toString() == this.formGroup.get('value').value?.toString();
  };

  inequality = (row: any) => {
    return row[this.activeColumn?.value]?.toString() != this.formGroup.get('value').value?.toString();
  };

  contains = (row: any) => {
    return row[this.activeColumn?.value]?.toString().includes(this.formGroup.get('value').value?.toString());
  };

  containsNot = (row: any) => {
    return !row[this.activeColumn?.value]?.toString().includes(this.formGroup.get('value').value?.toString());
  };

  greaterOrEqual = (row: any) => {
    return row[this.activeColumn?.value] >= this.formGroup.get('value').value;
  };

  lessOrEqual = (row: any) => {
    return row[this.activeColumn?.value] <= this.formGroup.get('value').value;
  };

  dateGreaterOrEqual = (row: any) => {
    let date = row[this.activeColumn?.value];

    if (date) {
      const splitdate = date.split('-');

      date = new Date(splitdate[2] + '-' + splitdate[1] + '-' + splitdate[0]);

      return date >= new Date(this.formGroup.get('value').value);
    }

    return false;
  };

  dateBeforeOrEqual = (row: any) => {
    let date = row[this.activeColumn?.value];

    if (date && this.formGroup.get('value').value) {
      const splitdate = date.split('-');

      date = new Date(splitdate[2] + '-' + splitdate[1] + '-' + splitdate[0]);

      return date <= new Date(this.formGroup.get('value').value);
    }

    return false;
  };

  IN = (row: any) => {
    const val = row[this.activeColumn?.value];
    let indices = this.formGroup
      .get('value')
      .value.split(',')
      .map((i) => Number(i));

    if (this.datatype.includes('string')) {
      const enumeration = this.store
        .selectSnapshot(EnumState)
        .enumeration.find((e) => e.group == this.activeColumn?.enum)?.items;
      indices = enumeration.filter((e) => indices.includes(e.id)).map((e) => e.description);
    }

    return indices.includes(val);
  };

  NOTIN = (row: any) => {
    const val = row[this.activeColumn?.value];
    let indices = this.formGroup
      .get('value')
      .value.split(',')
      .map((i) => Number(i));

    if (this.datatype.includes('string')) {
      const enumeration = this.store
        .selectSnapshot(EnumState)
        .enumeration.find((e) => e.group == this.activeColumn?.enum)?.items;
      indices = enumeration.filter((e) => indices.includes(e.id)).map((e) => e.description);
    }

    return !indices.includes(val);
  };


  // prettier-ignore
  expressieValues: expressieValue[] = [
    { value: '=', label: 'Egal Ã ', translation:'equalto',allowed: ['integer', 'float'], template: 'numberInput', func: this.equal },
    { value: '=', label: 'Egal Ã ', translation:'equalto',allowed: ['string'], template: 'stringInput', func: this.equal },
    {
      value: '!=',
      label: 'DiffÃ©rent de',
      translation:"differentto",
      allowed: ['integer', 'float'],
      template: 'numberInput',
      func: this.inequality,
    },
    { value: '!=', label: 'DiffÃ©rent de', translation:"differentto",allowed: ['string'], template: 'stringInput', func: this.inequality },
    //{value:'!=',label:'Ongelijk aan',allowed:['float','string']},
    {
      value: '>=',
      label: 'SupÃ©rieur ou Ã©gal Ã ',
      translation:'greaterthanoreequal',
      allowed: ['integer', 'float'],
      template: 'numberInput',
      func: this.greaterOrEqual,
    },
    {
      value: '<=',
      label: 'InfÃ©rieur ou Ã©gal Ã ',
      translation:'lessthanoreequal',
      allowed: ['integer', 'float'],
      template: 'numberInput',
      func: this.lessOrEqual,
    },
    {
      value: '>=',
      label: 'SupÃ©rieur ou Ã©gal Ã ',
      translation:'greaterthanoreequal',
      allowed: ['datetime'],
      template: 'dateInput',
      func: this.dateGreaterOrEqual,
    },
    { value: '<=', label: 'AntÃ©rieur ou Ã©gal Ã ', translation:'lessthan',allowed: ['datetime'], template: 'dateInput' ,func : this.dateBeforeOrEqual },
    { value: ' CONTAINING ', label: 'Contient', translation:'contains',allowed: ['string'], template: 'stringInput', func: this.contains },
    {
      value: ' NOT CONTAINING ',
      label: 'Ne contient pas',
      translation:'containsnot',
      allowed: ['string'],
      template: 'stringInput',
      func: this.containsNot,
    },
    { value: ' IN ', label: 'Dans', translation:'in',allowed: ['enum'], template: 'isEnum', func: this.IN },
    { value: ' NOT IN ', label: 'Non inclus', translation:'notin',allowed: ['enum'], template: 'isEnum', func: this.NOTIN },
  ];

  expressieValuesFiltered: expressieValue[] = this.expressieValues;

  generateString(kolom: string, expressie: string, val: string) {
    if (this.datatype == 'datetime') {
      let split = val.split('-');
      val = split[1] + '/' + split[2] + '/' + split[0];
    }

    if (this.selectedexpression?.allowed.includes('enum')) {
      const split = val.split(',');

      let result = '';

      const add = this.datatype.includes('string') ? "'" : '';

      let enumeration;

      if (this.datatype.includes('string')) {
        enumeration = this.store
          .selectSnapshot(EnumState)
          .enumeration.find((e) => e.group == this.activeColumn?.enum)?.items;
      }

      for (let s of split) {
        if (enumeration) {
          s = enumeration.find((e) => e.id == Number(s))?.description;
        }

        result += add + s + add + ',';
      }

      result = result.length > 0 ? result.substring(0, result.length - 1) : result;
      val = ' (' + result + ')';
    }

    if (this.datatype.split('_').includes('string') || this.datatype.split('_').includes('datetime')) {
      val = "'" + val + "'";
    }

    return kolom + expressie + val;
  }

  translateItem(lang,item){
    item.label = this.languages.translate(lang,item.translation);
  }

  translate(lang){
    this.lblExpression = this.languages.translate(lang,'expression');
    this.lblField = this.languages.translate(lang,'field');
    this.lblValue = this.languages.translate(lang,'value');
    if (this.expressieValues){
      this.expressieValues.forEach((item) => {
        this.translateItem(lang,item)
      })
    }
  }

  ngAfterViewInit(): void {
    //this.valueContainer.createEmbeddedView(this.stringInput);
    this.translate(this.comp.language)

    this.formGroup.valueChanges.subscribe((v) => {
      this.formControl.setValue(this.generateString(v.kolom, v.expressie, v.value));
    });

    this.formGroup.get('kolom').valueChanges.subscribe((k) => {
      const datatype = this.columnOptions.find((c) => c.label == k)?.datatype;

      this.activeColumn = this.columnOptions.find((c) => c.label == k);

      if (datatype == this.datatype) {
        return;
      }
      this.datatype = datatype;

      // Verander expressie opties
      this.expressieValuesFiltered = this.expressieValues.filter((e) => {
        let result = false;

        datatype.split('_').map((d) => {
          if (e.allowed.includes(d)) {
            result = true;
          }
        });

        return result;
      });

      this.formGroup
        .get('expressie')
        .setValue(this.expressieValuesFiltered.length ? this.expressieValuesFiltered[0].value : null);
    });

    this.formGroup.get('expressie').valueChanges.subscribe((v) => {
      this.formGroup.get('value').setValue('');

      if (this.valueContainer.length > 0) {
        this.valueContainer.remove(0);
      }

      if (!v) {
        return;
      }

      const expressie = this.expressieValues.find((e) => {
        let result = false;

        this.datatype.split('_').map((d) => {
          if (e.allowed.includes(d)) {
            result = true;
          }
        });
        return e.value == v && result;
      });
      this.selectedexpression = expressie;
      this.activeFunction = expressie.func;

      this.valueContainer.createEmbeddedView(this[expressie.template]);

      this.cd.detectChanges();
    });
  }

  expandNode() {
    const event = new CustomEvent('expand', {
      bubbles: true,
      detail: {
        self: this,
      },
    });

    this.el.nativeElement.dispatchEvent(event);

    //this.expand.emit('test')
  }

  removeNode() {
    const event = new CustomEvent('remove', {
      bubbles: true,
      detail: {
        self: this,
      },
    });

    this.el.nativeElement.dispatchEvent(event);

    //this.expand.emit('test')
  }
}
