<div class="bar">
  <button>
    <mat-icon (click)="close()">close</mat-icon>
  </button>
</div>
<isgrid
  [id]="-1009"
  class="isgrid"
  [selectCheckbox]="true"
  [datamode]="3"
  [columns]="columns"
  [data]="data"
></isgrid>
