<!-- <iframe
  src="http://localhost:8094/getreport?reportcode=DC86C2050ADCE21E23E0669B"
  #iframe
  style="
    background: white;
    width: 22cm;
    height: 29.7cm;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  "
>
</iframe> -->
<div class="loader" *ngIf="loading"></div>
<iframe *ngIf=!embed #iframe style="height: 100%; width: 100%" (load)="loaded()"> </iframe> 
<!--<iframe #iframe style="height: 100%; width: 100%" (load)="loaded()" (onbeforeRequest)="beforeRequest()" [src]="sansource"> </iframe>-->
<embed *ngIf=embed [src]="embsrc" [type]="embtype" class="pdf-content"/>
