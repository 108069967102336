<mat-toolbar
  color="primary"
  class="toolbar"
  style="height: calc(100%); width: calc(100%); display: flex"
>
  <!--<button mat-icon-button>
        <mat-icon>menu</mat-icon>
    </button>-->
  <!--<div *ngFor="let size of sizes" [style.flex-grow]="size">-->
  <ng-template #children *ngFor="let size of sizes"></ng-template>
  <!-- </div>   -->
</mat-toolbar>
