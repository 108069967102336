import { Component, ElementRef, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';

@Component({
  selector: 'ispanel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
  sizes: any = [];

  constructor(private el: ElementRef,private comp:ComponentsService,store:Store) {}

  @ViewChildren('children', { read: ViewContainerRef })
  public children: QueryList<ViewContainerRef>;

  fakeNativeDrop() {
    this.el.nativeElement.focus();
  }
}
