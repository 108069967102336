import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';

import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'isinput',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent extends IscontrolComponent implements OnInit, AfterViewInit {
  @Input() label: string;
  @Input() initialValue = '';
  @Input() frmGrp: FormGroup;
  @Input() master: number = null;
  @Input() fieldname: string = null;
  @Input() type: string = null;
  @Input() autocapitalize: string = null;
  @Input() disabled: boolean = false;
  translation : any | null = null;

  prependIcon: string = '';

  get fc() {
    return this.formControl;
  }

  controlid: number;
  public formControl: FormControl;
  notActive = false;
  multiline = false;
  warning = false;


  @ViewChild('warningMes', { read: TemplateRef }) warningMes: TemplateRef<any>;

  @HostListener('focusin', ['$event'])
  focusElement(ev) {
    ev.currentTarget.classList.remove('error');
  }

  constructor(
    public outlet: Outlet2Service,
    public compserv: ComponentsService,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(compserv, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    if (this.formInput && this.id) {
      try {
        this.controlid = super.initControl(this.id.toString() + '.input', this.initialValue, 'input', null, this.disabled);
        this.formControl = super.getFormControl(this.controlid);
      } catch (err) {
        console.log(err, this);
      }
    } else {
      this.formControl = new FormControl({ value: this.initialValue, disabled: this.disabled });
    }
  }

  ngAfterViewInit() {
    this.el.nativeElement.setAttribute('tabindex', -1);
    if (!this.multiline) {
      this.el.nativeElement.querySelector('input').focus();
    } else {
      this.el.nativeElement.querySelector('textarea').focus();
    }
    this.cd.detectChanges();

    if (this.prependIcon) {
      this.el.nativeElement.querySelector('input').classList.add('prepend-icon-input');
    }
  }

  afterformReady() {
    if (this.formControl) {
      if (this['valueChanges']) {
        this.formControl.valueChanges.subscribe((item) => {
          this['valueChanges'](item);
        });
      }
    }
  }

  controlnameValueChanges(newVal) {
    this.compserv.formcontrols.get(this.controlid.toString()).name = newVal;
  }

  translate(lang){
    if (this.translation){
      this.label = this.translation[lang];
    }
  }
}
