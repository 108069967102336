import { Injectable } from '@angular/core';
import { IsCloudService } from '@ic-builder/data-access-iscloud';

@Injectable({
  providedIn: 'root'
})
export class IslanguageService {

  translations:any;
  constructor() {     
  }

  translate(language:string,item:string){
    if(this.translations){
      return this.translations[item]?this.translations[item][language]:item;
    } else
    {
      return null
    }
  }
}
