import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import { ComponentsService, Outlet2Service,IscontrolComponent } from '@ic-builder/is-base';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'istimefull',
  templateUrl: './timefull.component.html',
  styleUrls: ['./timefull.component.scss'],
})
export class TimeFullComponent extends IscontrolComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() lblfrom = 'vanaf';
  @Input() lbluntil = 'tot';
  @Input() lblhours = 'uren';
  @Input() lblpause = 'pause';
  

  validators: Array<ValidatorFn> = [];
  notActive = false;

  controlidFrom: number;
  controlidUntil: number;
  controlidHours: number;
  controlidPause: number;
  controlidItems: number;

  formControlFrom: FormControl;
  formControlUntil: FormControl;
  formControlHours: FormControl;
  formControlPause: FormControl;
  formControlItems: FormControl;

  focus: number;
  controls: any[];
  hourkind = 'digital';

  constructor(
    comp: ComponentsService,
    public compserv: ComponentsService,
    public outlet: Outlet2Service,
    public store: Store,
    public NgZone: NgZone,
    public dialog: MatDialog,
    public el: ElementRef,
    public cd: ChangeDetectorRef
  ) {
    super(comp, store, outlet, NgZone, dialog, el, cd);
  }

  ngOnInit(): void {
    this.controlidFrom = super.initControl(this.id.toString() + '.from', null, 'from');
    this.controlidUntil = super.initControl(this.id.toString() + '.until', null, 'until');
    this.controlidHours = super.initControl(this.id.toString() + '.hours', null, 'hours');
    this.controlidPause = super.initControl(this.id.toString() + '.pause', null, 'pause');
    this.controlidItems = super.initControl(this.id.toString() + '.items', null, 'items');

    this.formControlFrom = super.getFormControl(this.controlidFrom);
    this.formControlUntil = super.getFormControl(this.controlidUntil);
    this.formControlHours = super.getFormControl(this.controlidHours);
    this.formControlPause = super.getFormControl(this.controlidPause);
    this.formControlItems = super.getFormControl(this.controlidItems);

    this.formControlFrom.valueChanges.subscribe((val) => {
      this.timeDiff();
    });
    this.formControlUntil.valueChanges.subscribe((val) => {
      this.timeDiff();
    });
    this.formControlHours.valueChanges.subscribe((val) => {
      this.timeSum();
    });
    this.formControlPause.valueChanges.subscribe((val) => {
      this.timeSum();
    });
    this.formControlItems.valueChanges.subscribe((val) => {
      this.timeSum();
    });
  }

  controlnameValueChanges(newVal) {
    this.compserv.formcontrols.get(this.controlidFrom.toString()).name = newVal + 'from';
    this.compserv.formcontrols.get(this.controlidUntil.toString()).name = newVal + 'until';
    this.compserv.formcontrols.get(this.controlidHours.toString()).name = newVal + 'hours';
    this.compserv.formcontrols.get(this.controlidHours.toString()).name = newVal + 'pause';
    this.compserv.formcontrols.get(this.controlidHours.toString()).name = newVal + 'items';
  }

  onAttach() {
    // this.addControls();
  }

  ngOnDestroy() {
    // super.destroyControlsNew(this.controls);
  }

  // addValidator(str,number){
  //   this.validators.push(Validators[str](number))
  //   this.frmGrp.controls[this.controlname].setValidators(this.validators)
  // }

  timeDiff() {
    if (this.formControlItems.value == null){
      let start = this.formControlFrom.value;
      let end = this.formControlUntil.value;
      const hours = this.formControlHours.value;

      if (this.focus == 3) {
        if (start) {
          start = start.split(':');
        }
      }

      if (this.focus == 2) {
      }

      if (start && end) {
        start = start.split(':');
        end = end.split(':');
        let hours = end[0] - start[0];
        let mins;
        if (start[1] > end[1]) {
          hours -= 1;
          mins = 60 - (start[1] - end[1]);
        } else {
          mins = end[1] - start[1];
        }

        if (this.focus != 3) {
          this.formControlHours.setValue(hours + mins / 60);
        }
        const timeString = this.timeToString(hours, mins);
        if (this.focus == 2) {
          this.focus = 0;
          this.formControlFrom.setValue(timeString);
          this.focus = 2;
        }
        if (this.focus == 1) {
          this.formControlUntil.setValue(timeString);
        }
      }
    }
  }

  timeSum() {
    if (this.formControlItems.value == null){
    let start = this.formControlFrom.value;
    let time = this.formControlUntil.value;

    if (start && time) {
      start = start.split(':');
      time = time.split(':');
      let hours = Number(start[0]) + Number(time[0]);
      let mins = Number(start[1]) + Number(time[1]);
      if (mins >= 60) {
        hours += 1;
        mins -= 60;
      }

      const timeString = this.timeToString(hours, mins);
      if (this.focus == 3) {
        this.formControlUntil.setValue(timeString);
      }
    }
    }
  }

  timeToString(hours: number, mins: number): string {
    let hoursString;
    let minsString;

    if (hours < 10) {
      hoursString = String('0' + hours);
    } else {
      hoursString = String(hours);
    }
    if (mins < 10) {
      minsString = String('0' + mins);
    } else {
      minsString = String(mins);
    }

    return hoursString + ':' + minsString;
  }

  focused(num) {
    this.focus = num;
  }
}
