<select [formControl]="colorControl">
  <option *ngFor="let theme of colorList" [value]="theme.name">
    {{ theme.name }}
  </option>
</select>
<select [formControl]="shapeControl">
  <option *ngFor="let theme of shapeList" [value]="theme.name">
    {{ theme.name }}
  </option>
</select>
