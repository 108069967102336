<!--<div >{{test}}</div>-->
<isenumselect
  style="flex: 1; overflow: hidden"
  [account]="10"
  [enum]="enum"
  [formInput]="false"
  [control]="formControl"
></isenumselect>
<!-- <button (click)="openconfig()" *ngIf="formControl.value=='isform'">...</button> -->

<ng-template #configPopUp>
  <isgrid
    [id]="id"
    [columns]="columns"
    [editmode]="false"
    [datamode]="4"
    [selectedRowId]="1"
    class="isgrid"
    (afterview)="afterview()"
  ></isgrid>
  <div style="height: 40px; display: flex">
    <isbutton text="OK" (click)="gridButtonOK()"></isbutton>
    <isbutton text="Cancel" (click)="gridButtonCancel()"></isbutton>
  </div>
</ng-template>
