<div style="padding: 10px 10px 10px 10px">
  <div style="height: 30vh">
    <tree-root [nodes]="nodes | async" [options]="options">
      <ng-template #treeNodeWrapperTemplate let-node let-index="index">
        <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
          <tree-node-expander [node]="node"></tree-node-expander>
          <div
            class="node-content-wrapper"
            [class.node-content-wrapper-active]="node.isActive"
            [class.node-content-wrapper-focused]="node.isFocused"
            (click)="onclick(node, $event)"
            (dblclick)="node.data.mode = 'edit'"
            [draggable]="node.data.children ? false : true"
            (dragstart)="dragstart(node.data.config, $event)"
            (dragend)="dragend(node.data.config, $event)"
            (dragging)="drag(node.data.config, $event)"
          >
            <div *ngIf="!node.data.mode">{{ node.data.name }}</div>

            <input
              *ngIf="node.data.mode === 'edit'"
              id="name"
              [value]="node.data.name"
              (focusout)="
                node.data.mode = ''; node.data.name = $event.target.value
              "
            />
          </div>
        </div>
      </ng-template>
    </tree-root>
  </div>
</div>
