import { Ismenu2Component } from './../ismenu2.component';
import { ComponentsService } from '@ic-builder/is-base';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'lib-ismenuitem2',
  templateUrl: './ismenuitem2.component.html',
  styleUrls: ['./ismenuitem2.component.scss'],
})
export class Ismenuitem2Component implements OnInit {
  constructor(
    private compservice: ComponentsService,
    private compfac: ComponentFactoryResolver,
    private el: ElementRef,
    private cd: ChangeDetectorRef
  ) {}

  parentid: number;
  parent: ComponentRef<Ismenu2Component>;
  description: string;
  sizes: any[] = [];
  submenuRef: ComponentRef<Ismenu2Component>;
  level: number;
  mainmenuid: number;
  id: number;

  //clickFunction = ''
  //clickAction = () => {
  //  new Function(this.clickFunction)()
  //}

  @ViewChild('submenu', { read: ViewContainerRef }) submenu: ViewContainerRef;

  parentloaded = () => {
    //this.parent = this.compservice.widgets.get(this.parentid);
  };

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    /*this.el.nativeElement.querySelector('.menu-item').addEventListener(
      'click' , () => {
        this.clickAction()
      }
    )*/

    if (this.sizes.length > 0) {
      const widget = this.compfac.resolveComponentFactory(Ismenu2Component);
      this.submenuRef = this.submenu.createComponent(widget);

      this.submenuRef.instance.sizes = this.sizes;
      this.submenuRef.instance.expanded = false;
      this.submenuRef.instance.mainmenuid = this.mainmenuid;
      this.submenuRef.changeDetectorRef.detectChanges();
    }

    this.el.nativeElement
      .querySelector('.menu-item')
      .addEventListener('mouseenter', (ev) => {
        const mainmenu = this.compservice.widgets.get(this.mainmenuid);

        for (const cur of mainmenu.instance.currentlyexpanded) {
          const expanded = this.compservice.widgets.get(cur);

          if (this.level <= expanded.instance.level) {
            if (expanded.instance.sizes.length > 0) {
              expanded.instance.submenuRef.instance.expanded = false;
              expanded.instance.submenuRef.changeDetectorRef.detectChanges();
              mainmenu.instance.currentlyexpanded =
                mainmenu.instance.currentlyexpanded.filter((a) => {
                  return a !== cur;
                });
            }
          }
          mainmenu.changeDetectorRef.detectChanges();
        }

        for (const size of this.sizes) {
          this.compservice.widgets.get(size.id).instance.mainmenuid =
            this.mainmenuid;
        }
        if (this.sizes.length > 0) {
          const width = ev.currentTarget.getBoundingClientRect().width;
          this.submenuRef.instance.left = width;
          this.submenuRef.instance.expanded = true;
          this.submenuRef.changeDetectorRef.detectChanges();
          mainmenu.instance.currentlyexpanded.push(this.id);
        }

        this.cd.detectChanges();
      });
  }
}
