<button (click)="opendialog()">...</button>

<ng-template #stylefunceditor>
  <!--<isgrid [id]="-1009" [columns]="columns" [data]="data" [editmode]="true" [datamode]="3" class="isgrid"></isgrid>-->

  <div class="column">
    <ngx-monaco-editor
      (onInit)="monaco($event)"
      [options]="options"
      [style.height]="'800px'"
      [style.width]="'1800px'"
    ></ngx-monaco-editor>
    <div class="commands">
      <isbutton
        class="cbutton"
        (click)="testjson()"
        cfg='{"text":"Test Json"}'
      ></isbutton>
    </div>
  </div>

  <div class="isbuttonrow">
    <isbutton
      class="isbutton"
      cfg='{"text":"Cancel"}'
      (click)="close()"
    ></isbutton>
    <isbutton
      class="isbutton"
      cfg='{"text":"Save"}'
      (click)="save()"
    ></isbutton>
  </div>
</ng-template>
