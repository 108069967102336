<div class="row">
  <div istag="label" class="content islabel" id="labeltxt">
    {{ label }}
  </div>

  <!-- <div *ngIf="controlsready"> -->
  <label istag="from_label" class="islabel from_label">{{ fromlabel }} </label>
  <input
    istag="from_input"
    type="date"
    class="content from_input"
    [formControl]="formControlfrom"
    useValueAsDate
    format="mm/dd/yyyy"
  />
  <label istag="until_label" class="islabel until_label">{{
    untillabel
  }}</label>
  <input
    istag="until_input"
    type="date"
    [min]="startTot"
    [max]="endTot"
    class="content until_input"
    [formControl]="formControluntil"
    useValueAsDate
    format="mm/dd/yyyy"
  />
  <mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
  <mat-icon
    *ngIf="warning"
    style="color: orange"
    (mouseenter)="openwarning($event)"
    (mouseleave)="closewarning()"
    >{{ warning }}</mat-icon
  >
  <!-- </div> -->
</div>

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
