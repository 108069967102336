import {
  Inject,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
  Type,
} from '@angular/core';
import {
  FEATURE_WIDGET_COMPONENT_FACTORY_REGISTRY_TOKEN,
  KnownModule,
  WIDGET_REGISTRY_FORROOT_GUARD,
} from './symbols';
import { WidgetsRegistryService } from './widgets-registry.service';

@NgModule()
export class WidgetsRegistryModule {
  static forRoot(): ModuleWithProviders<WidgetsRegistryModule> {
    return {
      ngModule: WidgetsRegistryModule,
      providers: [
        WidgetsRegistryService,
        {
          provide: WIDGET_REGISTRY_FORROOT_GUARD,
          useFactory: provideForRootGuard,
          deps: [[WidgetsRegistryService, new Optional(), new SkipSelf()]],
        },
      ],
    };
  }

  static forChild(moduleRegistry: {
    module: { name: KnownModule } | { ngModule: Type<unknown> };
    components: Record<string, Type<unknown>>;
  }): ModuleWithProviders<WidgetsRegistryModule> {
    return {
      ngModule: WidgetsRegistryModule,
      providers: [
        {
          provide: FEATURE_WIDGET_COMPONENT_FACTORY_REGISTRY_TOKEN,
          multi: true,
          useValue: moduleRegistry,
        },
      ],
    };
  }

  // Note: We are injecting the WidgetsRegistryService so it gets created eagerly...
  constructor(
    @Optional() @Inject(WIDGET_REGISTRY_FORROOT_GUARD) guard: string,
    @Optional() widgetsRegistryService: WidgetsRegistryService,
    @Optional()
    @Inject(FEATURE_WIDGET_COMPONENT_FACTORY_REGISTRY_TOKEN)
    moduleRegistries: Array<{
      module:  { ngModule: Type<unknown> };
      components: Record<string, Type<unknown>>;
    }> = []
  ) {
    // console.log(
    //   `%cClass: WidgetsRegistryModule, Function: constructor(moduleRegistries): `,
    //   'color: black;',
    //   moduleRegistries
    // );
    moduleRegistries
      .filter((r) => Boolean(r))
      .forEach(({ module, components }) => {
        if (hasOwnPropertyHelper(module, 'ngModule')) {
          widgetsRegistryService.registerEagerlyModuleWithComponents(
            module,
            components
          );
        } else {
          widgetsRegistryService.registerLazyModuleWithComponents(
            module,
            components
          );
        }
      });
  }
}
export function provideForRootGuard(
  widgetsRegistryService: WidgetsRegistryService
): string {
  if (widgetsRegistryService) {
    throw new Error(
      `WidgetsRegistryModule.forRoot() called twice. Lazy loaded modules should use WidgetsRegistryModule.forChild() instead.`
    );
  }
  return 'guarded';
}

function hasOwnPropertyHelper<X extends unknown, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}
