import { ChangeDetectorRef, Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface LeafExpressionFormData {
  field: string;
  operator: string;
  value: string;
}

@Component({
  selector: 'isleafexpression',
  templateUrl: './isleafexpression.component.html',
  styleUrls: ['./isleafexpression.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IsleafexpressionComponent),
      multi: true,
    },
  ],
})
export class IsleafexpressionComponent implements ControlValueAccessor, OnInit {
  form: FormGroup;
  @Input() fieldOptions = [];
  open = false;

  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() splitevent: EventEmitter<void> = new EventEmitter<void>();
  operatorOptions = ['!=', '<=', '>=', '==', 'starts with'];

  private _onChange: (value: LeafExpressionFormData | null | undefined) => void;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.createFormGroup();

    this.setupObservables();
    this.open = this.fieldOptions.length == 1;
  }

  // start interface ControlValueAccessor
  writeValue(value: LeafExpressionFormData): void {
    if (!value) {
      return;
    }

    this.form.patchValue(value);
  }

  registerOnChange(fn: (v: LeafExpressionFormData | null | undefined) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // TODO: implement this method
    // throw new Error("registerOnTouched not implemented");
  }

  setDisabledState(isDisabled: boolean): void {
    // TODO: implement this method
    // throw new Error("setDisabledState not implemented");
  }
  // end interface

  private createFormGroup() {
    const field = this.fieldOptions.length == 1 ? this.fieldOptions[0] : null;
    this.form = new FormGroup({
      field: new FormControl(field),
      operator: new FormControl(null),
      value: new FormControl(null),
    });
  }

  private setupObservables() {
    this.form.valueChanges.subscribe((value) => {
      if (this._onChange) {
        this._onChange(value);
      }
    });
  }

  toggleOpen() {
    this.open = !this.open;
    this.cd.detectChanges();
  }

  split() {
    this.splitevent.emit();
  }
}
