import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'editcel',
  templateUrl: './editcel.component.html',
  styleUrls: ['./editcel.component.scss'],
})
export class EditcelComponent implements OnInit, AfterViewInit {
  constructor(private el: ElementRef) {}

  initialValue = '';
  formControl: FormControl;

  @HostListener('focus')
  focus() {
    let input = this.el.nativeElement.querySelector('input');
    input.focus();
  }

  ngOnInit() {
    this.formControl = this.formControl
      ? this.formControl
      : new FormControl(this.initialValue);
  }

  ngAfterViewInit(): void {}
}
