<div istag="label" class="content label" id="labeltxt">
  {{ label }}
</div>

<iscustomselect
  class="select"
  istag="select"
  [formControl]="formControl"
  [options]="options"
  [valuefield]="valuefield"
  [displayfield]="displayfield"
>
</iscustomselect>
<mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
<mat-icon
  istag="warning"
  *ngIf="warning"
  style="color: orange"
  (mouseenter)="openwarning($event)"
  (mouseleave)="closewarning()"
  >warning</mat-icon
>

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
