<div istag="label" class="content label" id="labeltxt">
  {{ label }}
</div>

<label istag="label_from" class="label_from">{{ lblfrom }}: </label>
<input istag="input_from" (focus)="focused(1)" type="time" class="content input_from" [formControl]="formControlFrom" />
<label istag="label_until" class="label_until">{{ lbluntil }}: </label>
<input
  istag="input_until"
  (focus)="focused(2)"
  type="time"
  class="content input_until"
  [formControl]="formControlUntil"
/>
<label istag="label_time" class="label_time">{{ lblhours }}: </label>
<input
  istag="input_time"
  (focus)="focused(3)"
  type="number"
  class="content input_time"
  [formControl]="formControlHours"
/>
<label istag="label_pause" class="label_pause">{{ lblpause }}: </label>
<input
  istag="input_pause"
  (focus)="focused(4)"
  type="number"
  class="content input_pause"
  [formControl]="formControlPause"
/>
<mat-icon istag="error" *ngIf="!valid" style="color: red">error</mat-icon>
<mat-icon
  istag="warning"
  *ngIf="warning"
  style="color: orange"
  (mouseenter)="openwarning($event)"
  (mouseleave)="closewarning()"
  >warning</mat-icon
>

<ng-template #warningMes>
  <div class="warning">
    <span *ngFor="let warn of warningMessages">
      {{ warn }}
    </span>
  </div>
</ng-template>
