<mat-icon istag="phoneicon" [class]="phonemenu ? 'phonebutton' : 'none'">{{
  icon
}}</mat-icon>
<div istag="phonetext" [class]="phonemenu ? 'phonetext' : 'none'">
  {{ label }}
</div>

<mat-icon istag="levelicon" *ngIf="children ? children.length > 0 : false">
  {{
    direction === 'vertical' ? 'keyboard_arrow_right' : 'keyboard_arrow_down'
  }}
</mat-icon>
