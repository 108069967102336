import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';

@Component({
  selector: 'menuhandlercontainer',
  templateUrl: './menuhandlercontainer.component.html',
  styleUrls: ['./menuhandlercontainer.component.scss'],
})
export class MenuhandlercontainerComponent implements OnInit, AfterViewInit {
  @ViewChild('menucontainer', { read: ViewContainerRef })
  menucontainer: ViewContainerRef;
  @ViewChild('menucontaineritems', { read: ViewContainerRef })
  menucontaineritems: ViewContainerRef;
  phonemenu = false;

  constructor(
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private comp: ComponentsService
  ) {}

  ngOnInit(): void {
    console.log('phonemenu : ', this.phonemenu);
  }

  ngAfterViewInit() {}
}
