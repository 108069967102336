<!-- <div class="inh row"> -->
<div *ngIf="label" istag="label" class="content label" id="labeltxt">
  {{ label }}
</div>

<!-- <div class="inh form"> -->
<!-- <form class="inh" [formGroup]="sizeGroup"> -->
<input
  (keydown)="valueChange()"
  istag="input"
  class="inh value input"
  [formControl]="sizeGroup.get('value')"
/>
<select
  (change)="unitChange()"
  istag="select"
  class="unit select"
  name="unit"
  [formControl]="sizeGroup.get('unit')"
>
  <option
    *ngFor="let option of options"
    istag="option"
    class="option"
    [value]="option"
  >
    {{ option }}
  </option>
</select>
<!-- </form> -->
<!-- </div> -->
<!-- </div> -->
