import { IsAction } from '../isapplication/isapplication.model';

export class StartDragNode {
  static readonly type = '[StartDragNode]';
  constructor(public payload: any) {}
}

export class EndDragNode {
  static readonly type = '[EndDragNode]';
  constructor(public payload: any) {}
}

export class DragNode {
  static readonly type = '[DragNode]';
  constructor(public payload: any) {}
}
