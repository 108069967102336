import { FormControl, FormGroup } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentsService } from '@ic-builder/is-base';
import { EnumerationItem, EnumState } from '@ic-builder/iscomp';

@Component({
  selector: 'is-style-select',
  templateUrl: './is-style-select.component.html',
  styleUrls: ['./is-style-select.component.scss'],
})
export class IsStyleSelectComponent implements OnInit, OnDestroy {
  @Input() options: SelectItem[];
  @Input() frmGrp: FormGroup;

  @Input() selected: string;
  @Input() label: string;
  @Input() enum: string;
  @Input() valueOnly = false;
  @Input() objectList = true;
  @Input() disabled = false;
  @Input() name: string;
  @Input() wizGrp: FormGroup;
  @Input() destroy = true;

  id: number;
  // name:string;

  controlsready = false;
  validators: Array<any> = [];
  parentid: number;
  enumerationlist$: Observable<EnumerationItem[]>;

  formCtrl = new FormControl('')

  constructor(private store: Store, private comp: ComponentsService) {}

  ngOnInit(): void {
    
    this.frmGrp.addControl(this.name,this.formCtrl);

    this.frmGrp.get(this.name).valueChanges.subscribe(a => console.log(this.name,a))
    this.formCtrl.valueChanges.subscribe(a => console.log(this.name,a))
    /*
    if (this.options) {
      if (this.options.length > 0) {
        if (this.selected) {
          this.addControls([this.name], this.selected, this.disabled);
        } else {
          this.addControls([this.name], this.options[0].value, this.disabled);
        }
      } else {
        this.addControls([this.name], null, this.disabled);
      }
    } else {
      this.addControls([this.name], null, this.disabled);
    }

    this.enumerationlist$ = this.store.select(EnumState.getenumlist).pipe(
      map((filterFn) => {
        return filterFn(this.enum);
      })
    );*/
  }

  ngOnDestroy() {
    this.destroyControls([this.name]);
  }

  addControls(controlnames: string[], controlvalue: any, disabled?: boolean) {
    if (!this.frmGrp) {
      if (!this.comp.formgroup.get(this.parentid)) {
        this.comp.addformgroup(this.parentid);
      }
      this.frmGrp = this.comp.formgroup.get(this.parentid);
    }
    if (!this.frmGrp.get(controlnames[0])) {
      this.frmGrp.addControl(controlnames[0], new FormControl({ value: controlvalue, disabled: disabled }));
    }
    // controlnames.forEach(name => {
    //   if (!this.frmGrp.get(name)) {
    //     this.frmGrp.addControl(name, new FormControl({value: controlvalue, disabled: disabled}));
    //   }
    // })
    this.controlsready = true;
  }

  destroyControls(controlnames: string[]) {
    if (this.destroy) {
      controlnames.forEach((name) => {
        this.frmGrp.removeControl(name);
      });
    }
  }
}

export class SelectItem {
  value: string;
  description: string;
}
