export class SetPath {
  static readonly type = '[HIGHLIGHT]';

  constructor(public payload: string) {}
}

export class ToggleTheme {
  static readonly type = '[THEME]';

  constructor(public payload: string) {}
}

export class ResetPath {
  static readonly type = '[HIGHLIGHT]';

  constructor(public payload: string) {}
}
