import { ComponentsService } from '@ic-builder/is-base';

import { Store } from '@ngxs/store';
import { FormGroup } from '@angular/forms';
import {
  Component,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'formgroup',
  templateUrl: './formgroup.component.html',
  styleUrls: ['./formgroup.component.scss'],
})
export class FormgroupComponent implements OnInit {
  @ViewChildren('children', { read: ViewContainerRef })
  public children: QueryList<ViewContainerRef>;

  constructor(private store: Store, private comp: ComponentsService) {}

  formGroup = new FormGroup({});
  fielddefs;
  id: number;
  sizes: any;
  controlids = [];

  ngOnInit(): void {
    /*this.formGroup = new FormGroup({})
    if(this.fielddefs){
      for(let [key,value] of Object.entries(this.fielddefs)){
        this.formGroup.addControl(key,new FormControl(value))
      }
    }*/
  }

  addControls(code) {
    /*let temp = JSON.parse(code);
    let currentactive = []

    if(this.sizes){
      for(let size of this.sizes){
        let child = this.comp.widgets.get(size.id)

        if(child.instance.controlname){
          currentactive.push({name:child.instance.controlname,id:child.instance.id})
        }*/
    /*if(!Object.keys(temp).includes(child.instance.controlname)){
          this.formGroup.removeControl(child.instance.controlname)
          child.instance.controlname='';
          child.changeDetectorRef.detectChanges()
        }
      }
    }

    for(let key of Object.keys(this.fielddefs)){
      if(!Object.keys(temp).includes(key)){

        let active = currentactive.filter(c => {return c.name==key})

        if(active[0]){
          this.comp.widgets.get(active[0].id).instance.controlname=''
          this.comp.widgets.get(active[0].id).changeDetectorRef.detectChanges()
        }

        this.formGroup.removeControl(key)
      }
    }*/
    //for(let newcon of Object.keys(temp)){
    //}
    //debugger;
    //this.formGroup = new FormGroup({})
    /*this.fielddefs = JSON.parse(code);

    this.store.dispatch(new UpdateProperty({compid:this.id,path:'config.fielddefs',value:this.fielddefs}))

    for(let [key,value] of Object.entries(this.fielddefs)){
      if(!Object.keys(this.formGroup.controls).includes(key)){
        this.formGroup.addControl(key,new FormControl(value));
      } else {
        this.formGroup.get(key).setValue(value)
      }

    }*/
  }

  onDetach() {
    this.formGroup.reset();
  }

  newFormGroup(data) {
    this.formGroup = new FormGroup({});
    this.controlids = [];

    for (const d of data) {
      const regExp = /\(([^)]+)\)/;
      const number = Number(regExp.exec(d.controlname)[1]);
      this.controlids.push(number);
      this.formGroup.addControl(
        d.key,
        this.comp.formcontrols.get(number.toString()).formcontrol
      );
    }
  }

  controlidsValueChanges(newVal) {
    const ad = this.comp.formcontrols.get(newVal);

    if (ad) {
      this.formGroup.addControl(ad.name, ad.formcontrol);
    }
    this.formGroup.valueChanges.subscribe((a) => {
      console.log(a);
    });
  }
}
