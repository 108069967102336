import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IsthemingService } from '@ic-builder/is-base';

@Component({
  selector: 'isthemepicker',
  templateUrl: './isthemepicker.component.html',
  styleUrls: ['./isthemepicker.component.scss'],
})
export class IsthemepickerComponent implements OnInit {
  constructor(private themeService: IsthemingService) {}

  colorList;
  shapeList;
  colorControl: FormControl;
  shapeControl: FormControl;

  ngOnInit(): void {
    this.colorList = this.themeService.getColorThemeList();
    this.shapeList = this.themeService.shapeThemes;

    this.colorControl = new FormControl(this.colorList[0].name);
    this.shapeControl = new FormControl(this.shapeList[0].name);

    this.colorControl.valueChanges.subscribe((value) => {
      this.themeService.addThemeToComposite(
        this.themeService.getColorTheme(value),
        'colorTheme'
      );
    });

    this.shapeControl.valueChanges.subscribe((value) => {
      this.themeService.addThemeToComposite(
        this.themeService.getShapeTheme(value),
        'shapeTheme'
      );
    });
  }
}
