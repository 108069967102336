import { Overlay } from '@angular/cdk/overlay';
import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ComponentsService } from '@ic-builder/is-base';
import { IsGridRegistry } from '../isgrid.service';

@Component({
  selector: 'isfooter',
  templateUrl: './isfooter.component.html',
  styleUrls: ['./isfooter.component.scss'],
})
export class IsfooterComponent implements AfterViewInit {
  @ViewChild('footerContainer', { read: ViewContainerRef })
  public footerContainer: ViewContainerRef;
  private _visible: boolean;
  @Input() id: number;
  @Input() set visible(val: boolean) {
    this.setVisible(val);
  }
  get visible() {
    return this._visible;
  }

  @Input() height = 20;

  constructor(
    private isgrid: IsGridRegistry,
    private el: ElementRef,
    private comp: ComponentsService,
    private renderer: Renderer2
  ) {}

  setVisible(val: boolean) {
    if (this._visible != val) {
      this._visible = val;
      if (!val) {
        this.el.nativeElement.setAttribute('hidden', '');
        this.renderer.setStyle(this.el.nativeElement, 'height', '0px');
      } else {
        this.el.nativeElement.removeAttribute('hidden');
        this.el.nativeElement.removeAttribute('style', 'height');
      }
    }
  }

  setClass(footerclass) {
    this.el.nativeElement.classList.add(footerclass);
  }

  ngAfterViewInit() {
    this.setFooterinView();
    this.setVisible(this._visible);
  }

  setFooterinView() {
    this.isgrid.get(this.id).footerContainer = this.footerContainer;
  }
}
