import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { IIsAppError } from '../isapplication/isapplication.action';

@Component({
  selector: 'iserror',
  templateUrl: './iserror.component.html',
  styleUrls: ['./iserror.component.scss'],
})
export class IserrorComponent implements OnInit, OnDestroy {
  @Input() error: IIsAppError;
  errorsub: Subscription;
  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const errorsel = this.store.select(
      (state) => state.isapplicationstate.error
    );

    this.errorsub = errorsel.subscribe((item) => {
      if (item) {
        this.error = item;
        this.renderer.addClass(this.el.nativeElement, this.error.level);
      }
    });
  }

  reset() {
    if (this.error){
      this.renderer.removeClass(this.el.nativeElement, this.error.level);
      this.error = null;
      this.cd.detectChanges();
    }
  }

  ngOnDestroy() {
    if (this.errorsub) {
      this.errorsub.unsubscribe();
    }
  }
}
