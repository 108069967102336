<cdk-tree [dataSource]="ds" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node"
    class="example-tree-node-final"
    (click)="click(node)"
  >
    <!-- use a disabled button to provide padding for tree leaf -->
    <button style="text-align: left; width: 150px" mat-icon-button disabled>
      <mat-icon></mat-icon>{{ node.description }}
    </button>
  </cdk-nested-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node; when: hasChild"
    class="example-tree-node"
  >
    <button
      mat-icon-button
      style="height: 50px; width: 100%; left: 0px; text-align: left"
      [attr.aria-label]="'toggle ' + node.name"
      cdkTreeNodeToggle
    >
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
      <mat-icon>home</mat-icon>{{ node.description }}
    </button>

    <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>
