<div class="canvas-container" istag="issignaturecontainer" (click)="saveSignPad($event)">
    <canvas istag="issignaturecanvas"
        #signPadCanvas
        (touchstart)="startSignPadDrawing($event)"
        (touchmove)="movedFinger($event)"
        (touchend)="saveSignPad($event)"
        >
    </canvas>
</div>
<div class="clear btn-container">
    <button class="btn" (click)="clearSignPad()">Clear</button>
</div>
<div class="undo btn-container">
    <button class="btn" (click)="undoSign()">Undo</button>
</div>
