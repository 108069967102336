<label istag="label">
    <input
      type="file"
      [accept]="accept"
      (change)="getfile($event)"
      capture="user"
      istag="input"
    />{{ label }}
</label>
  
