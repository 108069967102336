import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'cvcolor',
})
export class CvcolorPipe implements PipeTransform {
  private cutHex(h: string): string {
    return h.charAt(0) == '#' ? h.substring(1, 7) : h;
  }

  private hexToB(h: string): string {
    return parseInt(this.cutHex(h).substring(0, 2), 16).toString();
  }

  private hexToG(h: string): string {
    return parseInt(this.cutHex(h).substring(2, 4), 16).toString();
  }
  private hexToR(h: string): string {
    return parseInt(this.cutHex(h).substring(4, 6), 16).toString();
  }

  private toHex(n: string): string {
    let h = parseInt(n, 10);
    if (isNaN(h)) return '00';
    h = Math.max(0, Math.min(h, 255));
    return (
      '0123456789ABCDEF'.charAt((h - (h % 16)) / 16) +
      '0123456789ABCDEF'.charAt(h % 16)
    );
  }

  private rgbToHex(R: string, G: string, B: string): string {
    return this.toHex(R) + this.toHex(G) + this.toHex(B);
  }

  public transform(acolor: number): string {
    if (acolor != undefined) {
      let ac = acolor.toString(16);
      while (ac.length < 6) {
        ac = '0' + ac;
      }
      const tmp =
        'rgb(' +
        this.hexToR(ac) +
        ',' +
        this.hexToG(ac) +
        ',' +
        this.hexToB(ac) +
        ')';
      return tmp;
    } else {
      return 'rgb(255,255,255)';
    }
  }
}
