<svg class="plot-area">
  <g id="parent" *ngFor="let serie of data">
    <line
      *ngFor="let d of serie.lines; let i = index"
      [attr.x1]="serie.getLeft(i)"
      [attr.x2]="serie.getLeft(i + 1)"
      stroke-width="2"
      stroke="red"
      [attr.y1]="getTop(d.y1)"
      [attr.y2]="getTop(d.y2)"
    ></line>

    <circle
      r="3px"
      fill="white"
      *ngFor="let d of serie.values; let i = index"
      [attr.cx]="serie.getLeft(i)"
      [attr.cy]="getTop(d)"
      stroke-width="2"
      stroke="red"
    ></circle>
  </g>
  <g>
    <line x1="0%" y1="0%" y2="100%" x2="0%" class="line"></line>
    <line
      x1="0%"
      [attr.y1]="BaseXAxis"
      [attr.y2]="BaseXAxis"
      x2="100%"
      class="line"
    ></line>
  </g>
</svg>
