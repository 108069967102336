import { FormControl } from '@angular/forms';
import { Component, HostListener, OnInit, ChangeDetectorRef, Input } from '@angular/core';

@Component({
  selector: 'ic-builder-and-or',
  templateUrl: './and-or.component.html',
  styleUrls: ['./and-or.component.scss']
})
export class AndOrComponent implements OnInit {

  constructor(private cd:ChangeDetectorRef) { }

  @Input('index') index:number;

  @HostListener('click')
  toggleSelection(){
    if(this.formControl.value=='AND'){
      this.formControl.setValue('OR')
    } else {
      this.formControl.setValue('AND')
    }
    this.cd.detectChanges();
  }

  formControl = new FormControl('AND')

  ngOnInit(): void {
  }

}
