<form [formGroup]="formGroup" *ngIf="enumerationlist$ | async">
  <!--id-->

  <mat-form-field>
    <input
      matInput
      (keydown.Tab)="TabPressed()"
      [formControlName]="idnr"
      placeholder="Nr."
      autocomplete="off"
    />
  </mat-form-field>

  <!--description-->
  <mat-form-field>
    <input
      matInput
      placeholder="{{ this.placeHolder }}"
      [formControlName]="field"
      [matAutocomplete]="auto"
    />
  </mat-form-field>
  <!--buttons-->

  <button mat-icon-button matSuffix (click)="search()" tabindex="-1">
    <mat-icon style="font-size: 16px">more_horiz</mat-icon>
  </button>
  <button mat-icon-button matSuffix (click)="clear()" tabindex="-1">
    <mat-icon style="font-size: 16px">close</mat-icon>
  </button>
  <!--autocomplete-->

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      (click)="select(option)"
      *ngFor="let option of filteredOptions | async"
      [value]="option"
    >
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</form>
<div *ngIf="!(enumerationlist$ | async)">
  <mat-form-field>
    <input
      matInput
      disabled
      placeholder="Could not load {{ this.placeHolder }}"
    />
  </mat-form-field>
</div>
