<div style="display: grid; grid-template-columns: 24px 1fr 24px 24px; background-color: var(--400)">
  <mat-icon class="icon" (click)="toggleOpen()" [style.transform]="open ? 'rotate(90deg)' : ''"
    >keyboard_arrow_right</mat-icon
  >
  <p>{{ printResult(form.value) }}</p>
  <mat-icon (click)="splitevent.emit()" style="transform: rotate(90deg)" class="icon">call_split</mat-icon>
  <mat-icon class="icon" (click)="remove.emit()">remove</mat-icon>
</div>

<div style="padding-left: 16px" [style.display]="open ? 'block' : 'none'">
  <form [formGroup]="form">
    <div *ngIf="form.get('leftExpression')" style="background-color: var(--200)">
      <div *ngIf="form.get('leftExpression').value">
        <div *ngIf="isLeaf(form.get('leftExpression').value)">
          <isleafexpression
            (splitevent)="splitExp('leftExpression')"
            (remove)="removeExp('leftExpression')"
            [fieldOptions]="fieldOptions"
            formControlName="leftExpression"
          ></isleafexpression>
        </div>

        <div *ngIf="!isLeaf(form.get('leftExpression').value)">
          <isexpression
            (splitevent)="splitExp('leftExpression')"
            (remove)="removeExp('leftExpression')"
            [fieldOptions]="fieldOptions"
            formControlName="leftExpression"
          ></isexpression>
        </div>
      </div>
      <div *ngIf="!form.get('leftExpression').value">
        <mat-icon class="icon" (click)="add('leftExpression')">account_tree</mat-icon>
        <mat-icon class="icon" (click)="addLeaf('leftExpression')">add</mat-icon>
      </div>
    </div>

    <is-style-select
      [frmGrp]="form"
      name="operator"
      label="operator"
      [options]="['AND', 'OR']"
      [valueOnly]="true"
      [objectList]="false"
    ></is-style-select>

    <div *ngIf="form.get('rightExpression')" style="background-color: var(--200)">
      <div *ngIf="form.get('rightExpression').value">
        <div *ngIf="isLeaf(form.get('rightExpression').value)">
          <isleafexpression
            (splitevent)="splitExp('leftExpression')"
            (remove)="removeExp('rightExpression')"
            [fieldOptions]="fieldOptions"
            formControlName="rightExpression"
          ></isleafexpression>
        </div>

        <div *ngIf="!isLeaf(form.get('rightExpression').value)">
          <isexpression
            (splitevent)="splitExp('rightExpression')"
            (remove)="removeExp('rightExpression')"
            [fieldOptions]="fieldOptions"
            formControlName="rightExpression"
          ></isexpression>
        </div>
      </div>
      <div *ngIf="!form.get('rightExpression').value">
        <mat-icon class="icon" (click)="add('rightExpression')">account_tree</mat-icon>
        <mat-icon class="icon" (click)="addLeaf('rightExpression')">add</mat-icon>
      </div>
    </div>
  </form>
</div>
