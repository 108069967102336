import { ExtractPropertyNames, Treeified } from './symbols';

export function treeifyArray<
  T,
  C extends PropertyKey,
  I extends keyof T,
  P extends ExtractPropertyNames<T, I>
>(
  nodes: Array<T>,
  config: { idProperty: I; parentIdProperty: P; childrenProperty: C }
): Array<Treeified<T, C>> {
  const nodeMap: Map<T[I], Treeified<T, C>> = new Map(
    nodes.map((node) => [
      node[config.idProperty],
      <Treeified<T, C>>{
        ...node,
        [config.childrenProperty]: [],
      },
    ])
  );
  const roots: Array<Treeified<T, C>> = [];

  nodes.forEach((node) => {
    const nodeId = node[config.idProperty];
    const nodeParentId = node[config.parentIdProperty] as unknown as T[I];
    let container = roots;
    if (nodeMap.has(nodeParentId)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      container = nodeMap.get(nodeParentId)![config.childrenProperty]!;
    }
    const nodeFromMap = nodeMap.get(nodeId);
    if (nodeFromMap) {
      container.push(nodeFromMap);
    }
  });
  return roots;
}
